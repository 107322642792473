import { zodResolver } from "@hookform/resolvers/zod";
import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { FileUploader } from "components/ui/file-uploader";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { Textarea } from "components/ui/textarea";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "components/ui/tooltip";
import { HelpCircle, LoaderCircle, PlusCircle, Trash2 } from "lucide-react";
import { useCallback, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
	email: z.string().email("Dirección de correo electrónico inválida"),
	phoneNumber: z
		.string()
		.regex(
			/^\+[1-9]\d{10,14}$/,
			"Formato de número de teléfono internacional inválido",
		),
	linkedinPdf: z
		.instanceof(File)
		.refine(
			(file) => file.type === "application/pdf",
			"El archivo debe ser un PDF",
		),
	targetAudience: z.string().min(1, "La audiencia objetivo es requerida"),
	onboardingMessage: z.string().optional(),
	linkedinPosts: z
		.array(
			z.object({
				content: z.string().transform((str) => str.replace(/\n/g, "\n")),
			}),
		)
		.optional(),
	metadata: z.string().optional(),
});

type FormValues = z.infer<typeof formSchema>;

const DEFAULT_ONBOARDING_MESSAGE =
	"¡Hola! Bienvenido al chatbot de Inbound Tools 📓 \n\nMi objetivo es ayudarte a crear contenido para LinkedIn\n\nPara esto vas a tener que tener una cuenta activa en Inbound Tools.\n\nEstamos en fase de pruebas, por lo que en caso de que tengas algún problema, puedes contactarnos directamente al +16508439111 mediante WhatsApp.\n\nInicia enviando un mensaje 💬";

const ProfilePersonaCreation = () => {
	const { toast } = useToast();
	const [isLoading, setIsLoading] = useState(false);

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: "",
			phoneNumber: "",
			targetAudience: "",
			onboardingMessage: DEFAULT_ONBOARDING_MESSAGE,
			linkedinPosts: [],
			metadata: "",
		},
	});

	const { fields, append, remove } = useFieldArray({
		control: form.control,
		name: "linkedinPosts",
	});

	const onSubmit = useCallback(
		async (data: FormValues) => {
			setIsLoading(true);
			try {
				const formData = new FormData();
				formData.append("email", data.email);
				formData.append("phoneNumber", data.phoneNumber);
				formData.append("targetAudience", data.targetAudience);
				formData.append("linkedinPdf", data.linkedinPdf);
				if (data.onboardingMessage && data.onboardingMessage.trim() !== "") {
					formData.append("onboardingMessage", data.onboardingMessage);
				}
				if (data.linkedinPosts && data.linkedinPosts.length > 0) {
					formData.append("linkedinPosts", JSON.stringify(data.linkedinPosts));
				}
				if (data.metadata && data.metadata.trim() !== "") {
					formData.append("metadata", data.metadata);
				}

				const response = (await axios.post(
					"/api/linkedin/create-profile-persona-and-chatbot-user",
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
						},
					},
				)) as { data: { message: string } };

				toast({
					title: "Éxito ✅",
					description: response.data.message,
				});
			} catch (error) {
				if (axios.isAxiosError(error) && error.response?.status === 404) {
					toast({
						title: "Usuario no encontrado",
						description:
							"El correo electrónico proporcionado no coincide con ningún usuario existente.",
						variant: "destructive",
					});
				} else {
					toast({
						title: "Error",
						description:
							"No se pudo crear el perfil persona y el usuario del chatbot. Por favor, inténtalo de nuevo.",
						variant: "destructive",
					});
				}
			} finally {
				setIsLoading(false);
			}
		},
		[toast],
	);

	return (
		<Card className="max-w-2xl mx-auto">
			<CardHeader>
				<CardTitle>Crear Perfil Persona</CardTitle>
			</CardHeader>
			<CardContent>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
						<FormField
							control={form.control}
							name="email"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Correo electrónico</FormLabel>
									<FormControl>
										<Input placeholder="usuario@ejemplo.com" {...field} />
									</FormControl>
									<FormDescription>
										Ingresa el correo electrónico del usuario para asignar este
										perfil persona. Este correo debe ser el mismo que se usó
										para registrarse en Inbound Tools.
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="phoneNumber"
							render={({ field }) => (
								<FormItem>
									<FormLabel className="flex items-center">
										Número de WhatsApp
										<TooltipProvider>
											<Tooltip>
												<TooltipTrigger asChild>
													<HelpCircle className="w-4 h-4 ml-2 cursor-pointer" />
												</TooltipTrigger>
												<TooltipContent className="bg-white text-black border">
													<p>
														Ingresa el número de teléfono en formato
														internacional completo.
													</p>
													<p>Ejemplo: +14081234567</p>
													<a
														href="https://faq.whatsapp.com/1294841057948784"
														target="_blank"
														rel="noopener noreferrer"
														className="text-blue-500 hover:underline"
													>
														Más información sobre el formato internacional
													</a>
													<br />
													<a
														href="https://b_fQwtbAfrP1o.v0.build/"
														target="_blank"
														rel="noopener noreferrer"
														className="text-blue-500 hover:underline"
													>
														Convertir número de teléfono a formato internacional
													</a>
												</TooltipContent>
											</Tooltip>
										</TooltipProvider>
									</FormLabel>
									<FormControl>
										<Input placeholder="+14081234567" {...field} />
									</FormControl>
									<FormDescription>
										Ingresa el número de WhatsApp en formato internacional
										completo (ej., +14081234567). Sin espacios ni caracteres
										especiales.
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="linkedinPdf"
							render={({ field }) => (
								<FormItem>
									<FormLabel>PDF de LinkedIn</FormLabel>
									<FormControl>
										<FileUploader
											value={field.value ? [field.value] : []}
											onValueChange={(newFiles) => {
												field.onChange(
													Array.isArray(newFiles) ? newFiles[0] || null : null,
												);
											}}
											maxFiles={1}
											maxSize={1 * 1024 * 1024} // 1MB
											accept={{ "application/pdf": [".pdf"] }}
										/>
									</FormControl>
									<FormDescription>
										Sube el perfil de LinkedIn en formato PDF.
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="targetAudience"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Audiencia Objetivo</FormLabel>
									<FormControl>
										<Textarea
											placeholder="Describe la audiencia objetivo..."
											{...field}
										/>
									</FormControl>
									<FormDescription>
										Describe la audiencia objetivo para este perfil persona.
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="onboardingMessage"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Mensaje de Bienvenida</FormLabel>
									<FormControl>
										<Textarea
											placeholder="Ingresa el mensaje de bienvenida..."
											{...field}
											rows={6}
										/>
									</FormControl>
									<FormDescription>
										Proporciona un mensaje de bienvenida para el usuario del
										chatbot. Deja en blanco para no enviar un mensaje.
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="metadata"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Metadatos</FormLabel>
									<FormControl>
										<Textarea
											placeholder="Ingresa metadatos adicionales... ej. No usar emojis. No usar hashtags."
											{...field}
										/>
									</FormControl>
									<FormDescription>
										Proporciona cualquier metadato adicional para el perfil
										persona. Esto se utilizará en el prompt para generar las
										publicaciones de LinkedIn.
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div>
							<FormLabel>Publicaciones de LinkedIn (Opcional)</FormLabel>
							<Button
								type="button"
								variant="outline"
								onClick={() => append({ content: "" })}
								className="w-full mt-2 mb-4"
							>
								<PlusCircle className="mr-2 h-4 w-4" />
								Agregar Publicación de LinkedIn
							</Button>
							{fields.map((field, index) => (
								<FormField
									key={field.id}
									control={form.control}
									name={`linkedinPosts.${index}.content`}
									render={({ field }) => (
										<FormItem className="mb-4">
											<FormControl>
												<div className="relative">
													<Textarea
														{...field}
														placeholder="Ingresa la publicación de LinkedIn"
														className="pr-10 min-h-[100px]"
													/>
													<Button
														type="button"
														variant="ghost"
														size="icon"
														className="absolute top-2 right-2"
														onClick={() => remove(index)}
													>
														<Trash2 className="h-4 w-4" />
													</Button>
												</div>
											</FormControl>
										</FormItem>
									)}
								/>
							))}
							<FormDescription>
								Agrega publicaciones de LinkedIn para generar una configuración
								de voz personalizada (opcional).
							</FormDescription>
						</div>

						<Button type="submit" disabled={isLoading}>
							{isLoading ? (
								<>
									<LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
									Creando Perfil Persona...
								</>
							) : (
								"Crear Perfil Persona"
							)}
						</Button>
					</form>
				</Form>
			</CardContent>
		</Card>
	);
};

export default ProfilePersonaCreation;
