/***
 *
 *   BILLING
 *   Change subscription, update card details or view past invoices
 *
 **********/

import {
	Animate,
	AuthContext,
	Message,
	OrganizationNav,
	TabView,
	useAPI,
} from "components/lib";
import React, { Fragment, useContext, useEffect, useState } from "react";

import { BillingCard } from "./card";
import { BillingInvoices } from "./invoices";
import { BillingPlan } from "./plan";

export function Billing(props) {
	// state/context
	const context = useContext(AuthContext);
	const [showMessage, setShowMessage] = useState(false);

	// fetch subscription
	const subscription = useAPI("/api/organization/subscription");
	const isPaid = context.user.plan !== "free";
	const labels = [props.t("organization.billing.tabs.plan")];
	if (isPaid)
		labels.push(
			props.t("organization.billing.tabs.card"),
			props.t("organization.billing.tabs.invoices"),
		);

	useEffect(() => {
		// subscription did load - show message?
		if (
			subscription.data &&
			subscription.data.status !== "active" &&
			subscription.data.status !== "trialing"
		)
			setShowMessage(true);
	}, [subscription.data]);

	return (
		<Fragment>
			<OrganizationNav />
			<Animate>
				{showMessage && (
					<Message
						type={
							subscription.data.status === "requires_action"
								? "warning"
								: "error"
						}
						title={props.t(
							`organization.billing.message.${subscription.data.status}.title`,
						)}
						text={props.t(
							`organization.billing.message.${subscription.data.status}.text`,
						)}
					/>
				)}

				<TabView name="Billing" labels={labels}>
					<BillingPlan
						{...props}
						subscription={subscription}
						onUpdate={() => setShowMessage(false)}
					/>

					{isPaid && <BillingCard {...props} />}

					{isPaid && <BillingInvoices {...props} />}
				</TabView>
			</Animate>
		</Fragment>
	);
}
