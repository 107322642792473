import { Badge } from "components/ui/badge";
import { HelpCircle } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "components/ui/tooltip";

interface TooltipProps {
  title?: string;
  value?: React.ReactNode;
  description: string;
}

export const TooltipHeader = ({
  title,
  description,
}: TooltipProps) => (
  <Tooltip>
    <TooltipTrigger className="flex items-center justify-center gap-1 w-full">
      {title}
      <HelpCircle className="h-4 w-4 text-muted-foreground" />
    </TooltipTrigger>
    <TooltipContent>
      <p className="max-w-xs">{description}</p>
    </TooltipContent>
  </Tooltip>
);

export const TooltipCell = ({
  value,
  description,
}: TooltipProps) => (
  <Tooltip>
    <TooltipTrigger className="w-full">
      <div className="flex justify-center">
        <Badge variant="secondary">{value}</Badge>
      </div>
    </TooltipTrigger>
    <TooltipContent>
      <p className="max-w-xs">{description}</p>
    </TooltipContent>
  </Tooltip>
); 