import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/ui/table";
import { Checkbox } from "components/ui/checkbox";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import { Edit, ChevronDown, ChevronRight } from "lucide-react";
import type { LinkedinPostType, LinkedPostMapping } from "../../../../types";
import { getPostStatusBadgeStyle, getPostStatusLabel } from "../utils/helpers";

interface PostTableProps {
  posts: LinkedinPostType[];
  selectedPosts: string[];
  onPostSelection: (postId: string) => void;
  expandedPosts: string[];
  togglePostExpansion: (postId: string) => void;
  linkedPosts: LinkedPostMapping[];
  onEditPublished: (blogPostId: string, domain?: string, status?: string) => void;
  publishSinglePost: (post: LinkedinPostType, isDraft: boolean) => void;
  selectedDomain?: { domain: string };
  selectedDraftPost: string | null;
  selectedPublishPost: string | null;
  isLoadingLinkedPosts: boolean;
  t: (key: string) => string;
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const truncateContent = (content: string, expanded: boolean): string => {
  if (expanded) return content;
  const words = content.split(' ');
  if (words.length <= 15) return content;
  return words.slice(0, 15).join(' ') + '...';
};

export const PostTable = ({
  posts,
  selectedPosts,
  onPostSelection,
  expandedPosts,
  togglePostExpansion,
  linkedPosts,
  onEditPublished,
  publishSinglePost,
  selectedDomain,
  selectedDraftPost,
  selectedPublishPost,
  isLoadingLinkedPosts,
  t
}: PostTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[50px]">
            <Checkbox
              checked={
                posts.length > 0 &&
                posts.every(post => selectedPosts.includes(post.linkedin_id))
              }
              onCheckedChange={(checked) => {
                if (checked) {
                  posts.forEach(post => onPostSelection(post.linkedin_id));
                } else {
                  posts.forEach(post => {
                    if (selectedPosts.includes(post.linkedin_id)) {
                      onPostSelection(post.linkedin_id);
                    }
                  });
                }
              }}
            />
          </TableHead>
          <TableHead>{t("content-creation.blog_from_posts_bulk.content")}</TableHead>
          <TableHead className="w-[150px]">{t("content-creation.blog_from_posts_bulk.date")}</TableHead>
          <TableHead className="w-[150px]">{t("content-creation.blog_from_posts_bulk.status")}</TableHead>
          <TableHead className="w-[200px]">{t("content-creation.blog_from_posts_bulk.actions")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {posts.map((post) => (
          <TableRow key={post.linkedin_id}>
            <TableCell>
              <Checkbox
                checked={selectedPosts.includes(post.linkedin_id)}
                onCheckedChange={() => onPostSelection(post.linkedin_id)}
              />
            </TableCell>
            <TableCell>
              <div className="relative">
                <p>
                  {truncateContent(post.content, expandedPosts.includes(post.linkedin_id))}
                </p>
                {post.content.split(' ').length > 15 && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => togglePostExpansion(post.linkedin_id)}
                  >
                    {expandedPosts.includes(post.linkedin_id) 
                      ? <ChevronDown className="h-4 w-4" />
                      : <ChevronRight className="h-4 w-4" />
                    }
                  </Button>
                )}
              </div>
            </TableCell>
            <TableCell>
              {formatDate(post.creation_time)}
            </TableCell>
            <TableCell>
              {isLoadingLinkedPosts ? (
                <div className="h-6 w-20 animate-pulse bg-gray-200 rounded" />
              ) : (
                linkedPosts?.find(
                  (item) => item.linkedin_post_id === post.linkedin_id
                ) ? (
                  <Badge
                    variant="outline"
                    className={getPostStatusBadgeStyle(
                      linkedPosts.find(
                        (item) => item.linkedin_post_id === post.linkedin_id
                      )?.status
                    )}
                  >
                    {getPostStatusLabel(t, linkedPosts.find(
                      (item) => item.linkedin_post_id === post.linkedin_id
                    )?.status)}
                  </Badge>
                ) : (
                  <Badge variant="outline" className="text-gray-500 border-gray-300">
                    {t("content-creation.blog_from_posts_bulk.unpublished")}
                  </Badge>
                )
              )}
            </TableCell>
            <TableCell>
              {isLoadingLinkedPosts ? (
                <div className="h-9 w-full animate-pulse bg-gray-200 rounded" />
              ) : linkedPosts?.find(
                (item) => item.linkedin_post_id === post.linkedin_id
              ) ? (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    const mapping = linkedPosts.find(
                      (item) => item.linkedin_post_id === post.linkedin_id
                    );
                    if (mapping) {
                      onEditPublished(mapping.blog_post_id, selectedDomain?.domain, mapping.status);
                    }
                  }}
                >
                  <Edit className="h-4 w-4 mr-2" />
                  {t("content-creation.blog_from_posts_bulk.edit")}
                </Button>
              ) : (
                <div className="flex gap-2">
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => publishSinglePost(post, true)}
                    disabled={selectedDraftPost === post.linkedin_id}
                  >
                    {selectedDraftPost === post.linkedin_id
                      ? t("content-creation.blog_from_posts_bulk.saving_draft")
                      : t("content-creation.blog_from_posts_bulk.publish_draft")}
                  </Button>
                  <Button
                    variant="default"
                    size="sm"
                    onClick={() => publishSinglePost(post, false)}
                    disabled={selectedPublishPost === post.linkedin_id}
                  >
                    {selectedPublishPost === post.linkedin_id
                      ? t("content-creation.blog_from_posts_bulk.publishing_post")
                      : t("content-creation.blog_from_posts_bulk.publish_post")}
                  </Button>
                </div>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}; 