import { useToast } from "components/hooks/use-toast";
import { Skeleton } from "components/ui/skeleton";
import { Trophy } from "lucide-react";
import type { Post } from "../types";
import { isLinkedInPost } from "../utils";
import { TopPostCard } from "./top-post-card";

interface TopPostsCardsProps {
	posts: Post[] | undefined;
	isLoading: boolean;
	error: unknown;
	platform: "linkedin" | "wordpress";
	t: (key: string, options?: Record<string, string>) => string;
}

export const TopPostsCards = ({
	posts,
	isLoading,
	error,
	platform,
	t,
}: TopPostsCardsProps) => {
	const { toast } = useToast();

	if (error || (!posts && !isLoading)) {
		toast({
			title: "Error",
			description: "Failed to fetch top posts",
			variant: "destructive",
		});
		return null;
	}

	return (
		<div className="space-y-4">
			<div className="flex items-center gap-2">
				<Trophy className="h-5 w-5 text-yellow-500" />
				<h3 className="font-semibold text-lg">
					{t("dashboard.posts.top_performing", { platform })}
				</h3>
			</div>
			{isLoading
				? Array.from({ length: 5 }).map((_, i) => (
						<Skeleton
							key={`top-posts-skeleton-${i + 1}`}
							className="h-32 w-full"
						/>
					))
				: posts
						?.slice(0, 5)
						.map((post) => (
							<TopPostCard
								key={
									isLinkedInPost(post) ? post.linkedin_id : post.wordpress_id
								}
								post={post}
							/>
						))}
		</div>
	);
};
