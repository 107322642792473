import { Card, CardContent, CardHeader } from "components/ui/card";
import { Skeleton } from "components/ui/skeleton";

export const SkeletonMetricCard = () => {
	return (
		<Card className="bg-white">
			<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
				<Skeleton className="h-4 w-[100px]" />
			</CardHeader>
			<CardContent>
				<Skeleton className="h-8 w-[120px] mb-4" />
				<Skeleton className="h-4 w-[200px] mb-4" />
				<Skeleton className="h-[40px] w-full" />
			</CardContent>
		</Card>
	);
};
