import * as z from "zod";

export const icpFormSchema = z.object({
  icp_target_industries: z.string(),
  icp_target_roles: z.string(),
  icp_company_size: z.string(),
  icp_geography: z.string(),
  icp_goals: z.string(),
  icp_description: z.string()
});

export type IcpFormData = z.infer<typeof icpFormSchema>; 