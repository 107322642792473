import { Badge } from "components/ui/badge";
import { Label } from "components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Switch } from "components/ui/switch";

interface Goal {
	id: string;
	title: string;
}

interface WeekProgress {
	week: number;
}

interface GoalControlsProps {
	vsGoal: boolean;
	setVsGoal: (value: boolean) => void;
	selectedGoal: Goal | null;
	setSelectedGoal: (goal: Goal) => void;
	selectedWeek: number | null;
	setSelectedWeek: (week: number) => void;
	goals: Goal[];
	weekProgressData: WeekProgress[];
	t: (key: string) => string;
}

export function GoalControls({
	vsGoal,
	setVsGoal,
	selectedGoal,
	setSelectedGoal,
	selectedWeek,
	setSelectedWeek,
	goals,
	weekProgressData,
	t,
}: GoalControlsProps) {
	return (
		<>
			<Select
				value={selectedWeek?.toString()}
				onValueChange={(value) => setSelectedWeek(Number.parseInt(value, 10))}
				disabled={!vsGoal}
			>
				<SelectTrigger className="max-w-36 bg-white">
					<SelectValue placeholder="Select a week">
						{selectedWeek !== null ? `Week ${selectedWeek}` : undefined}
					</SelectValue>
				</SelectTrigger>
				<SelectContent>
					<SelectGroup>
						<SelectLabel>Weeks in Goal</SelectLabel>
						{weekProgressData.map((week) => (
							<SelectItem key={week.week} value={week.week.toString()}>
								Week {week.week}
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>

			<Select
				value={selectedGoal?.id}
				onValueChange={(value) => {
					const goal = goals.find((g) => g.id === value);
					if (goal) setSelectedGoal(goal);
				}}
				disabled={!vsGoal}
			>
				<SelectTrigger className="max-w-36 bg-white">
					<SelectValue placeholder="Select a goal">
						{selectedGoal?.title}
					</SelectValue>
				</SelectTrigger>
				<SelectContent>
					<SelectGroup>
						<SelectLabel>All Goals</SelectLabel>
						{goals.map((goal) => (
							<SelectItem key={goal.id} value={goal.id}>
								{goal.title}
							</SelectItem>
						))}
					</SelectGroup>
				</SelectContent>
			</Select>

			<Badge className="bg-white shadow-sm" variant="outline">
				<div className="flex items-center space-x-2 w-full p-1">
					<Switch id="switch" checked={vsGoal} onCheckedChange={setVsGoal} />
					<Label htmlFor="switch">
						{t("dashboard.collaborator_metrics.vs_goal")}
					</Label>
				</div>
			</Badge>
		</>
	);
}
