const Style = {
	wrapper: "!py-10",
	flexContainer: "flex justify-between items-center w-full px-4",
	nav: "relative",
	desktop: "hidden sm:block",
	mobile: "fixed top-[1.2em] left-[0.75em] right-[0.75em] sm:hidden z-[100]",
	logo: "m-0",
	links: " text-left sm:text-right sm:p-0 sm:h-5 sm:leading-[1.3em]",
	link: "block !text-slate-700 mb-4 no-underline sm:inline-block sm:!text-white sm:p-0 sm:opacity-90 sm:hover:opacity-100 sm:mb-0 sm:mr-6",
	toggleButtonOpen:
		"absolute top-1/2 right-[0.4em] z-100 sm:!hidden -translate-y-1/2",
	toggleButtonClosed: "absolute right-[1em] top-[1em] z-100 sm:hidden",
	buttonWrapper: "sm:absolute sm:right-0 sm:top-1/2 sm:-translate-y-1/2",
	button:
		"cursor-pointer font-sans text-sm !px-6 !py-2 relative block text-center font-semibold rounded px-12 py-3 ease-in-out duration-500 text-white uppercase bg-blue-500 hover:bg-blue-600 hover:border-blue-600",
};

export default Style;
