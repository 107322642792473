/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
"use client";

import { AuthContext } from "components/lib";
import { Button } from "components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import {
	type ChartConfig,
	ChartContainer,
	ChartTooltipContent,
} from "components/ui/chart";
import { Separator } from "components/ui/separator";
import { format } from "date-fns";
import { addWeeks, differenceInWeeks, getISOWeek } from "date-fns";
import {
	ExternalLink,
	MessageSquareText,
	ScrollText,
	ThumbsUp,
	TrendingUp,
} from "lucide-react";
import { useContext } from "react";
import { useEffect, useState } from "react";
import {
	Area,
	AreaChart,
	CartesianGrid,
	Legend,
	Tooltip,
	XAxis,
	YAxis,
} from "recharts";

interface ProgressChartProps {
	id: string;
	data: any;
	metrics: any[];
	goal: any;
	metricTypes: string[];
	selectedUser: any;
	initialGoalMetrics: any[];
	setSelectedUser: (user: any) => void;
}

const chartConfig = {
	expected: {
		label: "Expected",
		color: "hsl(var(--chart-1))",
	},
	actual: {
		label: "Actual",
		color: "hsl(var(--chart-2))",
	},
} satisfies ChartConfig;

export const ProgressChart = ({
	id,
	data,
	metrics,
	goal,
	metricTypes,
	selectedUser,
	setSelectedUser,
	initialGoalMetrics,
}: ProgressChartProps) => {
	const auth: any = useContext(AuthContext);
	const organization = auth.user.organizations.find(
		(org: any) => org.id === auth.user.organization_id,
	);

	const [chartData, setChartData] = useState<any>(null);
	const [selectedMetricType, setSelectedMetricType] = useState<string>(
		metricTypes[0],
	);

	useEffect(() => {
		if (data && metrics.length && selectedMetricType && goal) {
			const transformedData = transformChartData(
				data,
				metrics,
				goal.start_date,
				goal.end_date,
				selectedUser,
			);
			setChartData(transformedData);
		}
	}, [selectedMetricType, selectedUser, data, metrics, goal]);

	useEffect(() => {
		if (metricTypes) {
			setSelectedMetricType(metricTypes[0]);
		}
	}, [metricTypes]);

	const getIconForMetricType = (metricType: string) => {
		switch (metricType) {
			case "impressions":
				return <TrendingUp className="w-4 h-4" />;
			case "likes":
				return <ThumbsUp className="w-4 h-4" />;
			case "comments":
				return <MessageSquareText className="w-4 h-4" />;
			// case "shares":
			//   return <ExternalLink className="w-4 h-4" />;
			case "posts":
				return <ScrollText className="w-4 h-4" />;
			default:
				return null;
		}
	};

	const transformChartData = (
		data: any[],
		metrics: any[],
		startDate: string | number | Date,
		endDate: string | number | Date,
		selectedUser: any,
	) => {
		const totalWeeks = differenceInWeeks(
			new Date(endDate),
			new Date(startDate),
		);
		const weeks = Array.from({ length: totalWeeks + 1 }, (_, i) => {
			const weekDate = addWeeks(new Date(startDate), i);
			return `Week ${getISOWeek(weekDate)}`;
		});

		const actualMetric = metrics.find(
			(metric) => metric.metric_type === selectedMetricType,
		);

		if (!actualMetric) {
			return weeks.map((week) => ({ week, actual: 0, expected: 0 }));
		}

		let actualValues;
		if (selectedUser) {
			actualValues = data.map((week) => {
				const metricData = week[actualMetric.metric_type];
				const userMetricData = metricData.find(
					(item: any) => item.user_id === selectedUser.user_id,
				);
				return userMetricData ? userMetricData.value : 0;
			});
		} else {
			actualValues = data.map((week) => {
				const metricData = week[actualMetric.metric_type];
				const totalValue = metricData.reduce(
					(sum: any, current: { value: any }) => sum + current.value,
					0,
				);
				return totalValue;
			});
		}

		let expectedValues;
		if (selectedUser) {
			const userInitialData = initialGoalMetrics
				.find((goal) => goal.metricType === selectedMetricType)
				?.data.find((user: any) => user.user_id === selectedUser.user_id);
			const initialValue = userInitialData?.initial_value || 0;
			const weeklyGrowthRate = actualMetric.weekly_growth_rate;

			expectedValues = Array.from({ length: totalWeeks + 1 }, (_, index) =>
				Math.round(initialValue * Math.pow(weeklyGrowthRate, index)),
			);
		} else {
			expectedValues = Array.from({ length: totalWeeks + 1 }, (_, index) => {
				if (actualMetric.metric_type === "posts") {
					return actualMetric.initial_value;
				}
				return Math.round(
					actualMetric.initial_value *
						Math.pow(actualMetric.weekly_growth_rate, index),
				);
			});
		}

		return weeks.map((week, index) => ({
			week,
			actual: actualValues[index],
			expected: expectedValues[index],
		}));
	};

	return (
		<Card>
			<CardHeader className="flex flex-row justify-between items-center w-full">
				<div>
					<CardTitle className="text-2xl">
						Progress Chart for{" "}
						{selectedMetricType.charAt(0).toUpperCase() +
							selectedMetricType.slice(1)}{" "}
					</CardTitle>
					<CardDescription className="m-1 text-lg">
						Showing actual vs expected progress for{" "}
						<b>
							{selectedUser?.user_name ||
								organization.name ||
								"the organization"}
						</b>
					</CardDescription>
				</div>
				{selectedUser && (
					<Button variant="outline" onClick={() => setSelectedUser(null)}>
						Show Organization Goals
					</Button>
				)}
			</CardHeader>
			<CardContent>
				<div className="flex gap-2 items-center mb-4">
					{metricTypes.map((type, index) => (
						<div key={type} className="flex gap-2 items-center">
							<Button
								key={type}
								variant="outline"
								className={`flex items-center gap-2
                ${selectedMetricType === type ? "bg-primary text-white" : ""}`}
								disabled={selectedMetricType === type}
								onClick={() => setSelectedMetricType(type)}
							>
								{getIconForMetricType(type)}
								{type.toUpperCase()}
							</Button>

							{index < metricTypes.length - 1 && (
								<Separator className="min-h-full" orientation="vertical" />
							)}
						</div>
					))}
				</div>
				<Separator className="my-4" />
				{chartData && (
					<ChartContainer config={chartConfig}>
						<AreaChart
							width={600}
							height={300}
							data={chartData}
							margin={{
								top: 5,
								right: 30,
								left: 20,
								bottom: 5,
							}}
						>
							<CartesianGrid strokeDasharray="3 3" />
							<XAxis dataKey="week" />
							<YAxis />
							<Legend />
							<Tooltip cursor={false} content={<ChartTooltipContent />} />
							<defs>
								<linearGradient id="fillActual" x1="0" y1="0" x2="0" y2="1">
									<stop
										offset="5%"
										stopColor="var(--color-actual)"
										stopOpacity={0.5}
									/>
									<stop
										offset="95%"
										stopColor="var(--color-actual)"
										stopOpacity={0.1}
									/>
								</linearGradient>
								<linearGradient id="fillExpected" x1="0" y1="0" x2="0" y2="1">
									<stop
										offset="5%"
										stopColor="var(--color-expected)"
										stopOpacity={0.2}
									/>
									<stop
										offset="95%"
										stopColor="var(--color-expected)"
										stopOpacity={0.1}
									/>
								</linearGradient>
							</defs>
							<Area
								type="monotone"
								dataKey="expected"
								stroke="var(--color-expected)"
								fillOpacity={0.8}
								fill="url(#fillExpected)"
								strokeWidth={1}
							/>
							<Area
								type="monotone"
								dataKey="actual"
								stroke="var(--color-actual)"
								fillOpacity={0.8}
								fill="url(#fillActual)"
								strokeWidth={1}
							/>
						</AreaChart>
					</ChartContainer>
				)}
			</CardContent>
			<CardFooter>
				<div className="flex gap-2 items-start w-full text-sm">
					<div className="grid gap-2">
						<div className="flex gap-2 items-center leading-none text-muted-foreground">
							{format(new Date(goal.start_date), "MMMM d, yyyy")} -{" "}
							{format(new Date(goal.end_date), "MMMM d, yyyy")}
						</div>
					</div>
				</div>
			</CardFooter>
		</Card>
	);
};
