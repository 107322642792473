import { AuthContext } from "components/lib";
import { Badge } from "components/ui/badge";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "components/ui/dialog";
import { MessageSquare, Share2, ThumbsUp } from "lucide-react";
import { Loader2 } from "lucide-react";
import { useContext, useState } from "react";
import type { AuthContextType } from "types/authContext";
import { usePostReactions } from "../_hooks/use-post-reactions";
import { leadLensApi } from "../services/lead-lens-api";
import type { Post, PostReactionInteraction } from "../types";
import { LeadProfileDialog } from "./lead-profile-dialog";
import { LeadsTable } from "./leads-table/component";
import { Button } from "components/ui/button";

interface PostLeadsDialogProps {
  post: Post | null;
  onClose: () => void;
  refetchPosts: () => Promise<void>;
  syncingProfiles: Set<string>;
  setSyncingProfiles: (value: Set<string> | ((prev: Set<string>) => Set<string>)) => void;
  onGenerateIcebreaker: (linkedinId: string) => Promise<void>;
}

export const PostLeadsDialog = ({
	post,
	onClose,
	refetchPosts,
	syncingProfiles,
	setSyncingProfiles,
  onGenerateIcebreaker
}: PostLeadsDialogProps) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize] = useState(10);
	const auth = useContext<AuthContextType>(AuthContext);


	const { data: reactionsData, isLoading, refetch: refetchReactions } = usePostReactions(
		post?.linkedin_id || null,
		currentPage,
		pageSize
	);

	
	const [selectedInteraction, setSelectedInteraction] = useState<PostReactionInteraction | null>(null);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const handleGenerateIcebreaker = async (linkedinId: string) => {
		setSyncingProfiles((prev: Set<string>) => {
			const newSet = new Set(prev);
			newSet.add(linkedinId);
			return newSet;
		});

		await leadLensApi.generateIcebreaker({
			linkedinId: linkedinId,
			userId: auth.user?.id || "",
			token: auth.user?.token || ""
		});

		setSyncingProfiles(prev => {
			const newSet = new Set(prev);
			newSet.delete(linkedinId);
			return newSet;
		});

		await refetchReactions();
	};

	if (!post) return null;

	return (
		<>
			<Dialog open={!!post} onOpenChange={onClose}>
				<DialogContent className="max-w-[90vw] max-h-[90vh] flex flex-col">
					<DialogHeader>
						<DialogTitle>Post Interactions</DialogTitle>
					</DialogHeader>

					<div className="mt-4 flex-1 overflow-y-auto pr-2 relative">
						<div className="bg-muted/50 p-4 rounded-lg mb-6">
							<h3 className="text-lg font-semibold mb-2">Post Content</h3>
							<p className="mb-4 whitespace-pre-line">{post.content}</p>
							<div className="flex items-center gap-6">
								<div className="flex items-center gap-2">
									<ThumbsUp className="h-4 w-4 text-blue-500" />
									<span>{post.metrics.likes} likes</span>
								</div>
								<div className="flex items-center gap-2">
									<MessageSquare className="h-4 w-4 text-green-500" />
									<span>{post.metrics.comments} comments</span>
								</div>
								<div className="flex items-center gap-2">
									<Share2 className="h-4 w-4 text-purple-500" />
									<span>{post.metrics.shares} shares</span>
								</div>
								<Badge variant="secondary">
									{post.qualifiedLeads} Qualified Leads
								</Badge>
							</div>
						</div>

						<h4 className="font-semibold mb-4">
							Interactions ({reactionsData?.data?.totalInteractions || 0})
						</h4>

						{isLoading ? (
							<div className="flex items-center justify-center p-4">
								<Loader2 className="h-6 w-6 animate-spin" />
							</div>
						) : (
							<>
								<LeadsTable
									leads={reactionsData?.data.interactions || []}
									onLeadClick={setSelectedInteraction}
									currentPage={currentPage}
									totalPages={reactionsData?.data.totalPages || 1}
									onPageChange={handlePageChange}
									onGenerateIcebreaker={handleGenerateIcebreaker}
									syncingProfiles={syncingProfiles}
								/>
								{selectedInteraction && (
									<LeadProfileDialog 
										interaction={selectedInteraction} 
										onClose={() => setSelectedInteraction(null)} 
										onGenerateIcebreaker={handleGenerateIcebreaker}
										isSyncing={syncingProfiles.has(selectedInteraction.profile.linkedinId)}
									/>
								)}
							</>
						)}
					</div>
					<DialogFooter>
						<Button variant="outline" onClick={onClose}>
							Close
						</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</>
	);
}; 