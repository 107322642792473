import type {
	GoalMetricType,
	GoalType,
	UserInitialGoalValueType,
	WeekProgressType,
} from "../types";
import { MetricPieChartVsGoals } from "./metric-pie-chart-vs-goal";
import { SkeletonPieChart } from "./skeleton-pie-chart";

interface AccumulatedMetricsPieChartsProps {
	weekProgressData: WeekProgressType[];
	userInitialGoalMetrics: UserInitialGoalValueType[];
	loading: boolean;
	selectedWeek: number;
	goalMetrics: GoalMetricType[];
	goal: GoalType;
	selectedTeamId: string;
}

export const MetricsPieChartsVsGoals = ({
	weekProgressData,
	userInitialGoalMetrics,
	goalMetrics,
	loading,
	selectedWeek,
	goal,
	selectedTeamId,
}: AccumulatedMetricsPieChartsProps) => {
	if (loading || (!loading && (!weekProgressData || !userInitialGoalMetrics))) {
		return (
			<div className="flex items-center justify-between w-full">
				<SkeletonPieChart />
				<SkeletonPieChart />
				<SkeletonPieChart />
				<SkeletonPieChart />
			</div>
		);
	}

	return (
		<div className="flex items-center justify-between w-full">
			<MetricPieChartVsGoals
				weekProgressData={weekProgressData}
				userInitialGoalMetrics={userInitialGoalMetrics}
				metricKey="posts"
				title="Posts"
				description="Distribution of posts among contributors"
				key="pie-goal-posts"
				selectedWeek={selectedWeek}
				goalMetrics={goalMetrics}
				goal={goal}
				selectedTeamId={selectedTeamId}
			/>
			<MetricPieChartVsGoals
				weekProgressData={weekProgressData}
				userInitialGoalMetrics={userInitialGoalMetrics}
				metricKey="impressions"
				title="Impressions"
				description="Distribution of impressions among contributors"
				key="pie-goal-impressions"
				selectedWeek={selectedWeek}
				goalMetrics={goalMetrics}
				goal={goal}
				selectedTeamId={selectedTeamId}
			/>
			<MetricPieChartVsGoals
				weekProgressData={weekProgressData}
				userInitialGoalMetrics={userInitialGoalMetrics}
				metricKey="likes"
				title="Interactions"
				description="Distribution of interactions among contributors"
				key="pie-goal-likes"
				selectedWeek={selectedWeek}
				goalMetrics={goalMetrics}
				goal={goal}
				selectedTeamId={selectedTeamId}
			/>
			<MetricPieChartVsGoals
				weekProgressData={weekProgressData}
				userInitialGoalMetrics={userInitialGoalMetrics}
				metricKey="comments"
				title="Comments"
				description="Distribution of interactions among contributors"
				key="pie-goal-comments"
				selectedWeek={selectedWeek}
				goalMetrics={goalMetrics}
				goal={goal}
				selectedTeamId={selectedTeamId}
			/>
		</div>
	);
};
