const Style = {
	tabs: "overflow-hidden border-b border-solid border-slate-100 md:border-0 dark:border-0",

	button: `relative float-left w-full px-10 py-3 outline-none text-sm
    ease-in-out duration-300 border-b border-solid border-slate-100 last:border-0
    md:w-auto md:border-0 md:bg-blue-400 dark:text-white dark:border-0`,

	button_active:
		"text-white bg-blue-400 md:text-slate-500 md:bg-white dark:bg-slate-800 dark:text-slate-200",
	button_inactive:
		"text-slate-500 bg-white md:text-white md:bg-blue-400 dark:bg-sky-700",
};

export default Style;
