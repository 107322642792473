/***
 *
 *   NOTIFICATIONS
 *   User can choose which notifications they receive
 *
 **********/

import { Animate, Card, Form, OrganizationNav, useAPI } from "components/lib";
import { Fragment, useEffect, useState } from "react";

export function Notifications(props) {
	const [inputs, setInputs] = useState(null);
	const res = useAPI("/api/notification");

	useEffect(() => {
		// dynamically render the available
		// inputs for this user's permission
		if (res.data?.length) {
			const s = {};
			for (const input of res.data) {
				s[input.name] = {
					type: "switch",
					default: input.active,
					label: props.t(
						`organization.notifications.form.options.${input.name}`,
					),
				};
			}

			setInputs(s);
		}
	}, [res.data]);

	return (
		<Fragment>
			<OrganizationNav />
			<Animate>
				<Card
					title={props.t("organization.notifications.subtitle")}
					loading={res.loading}
					restrictWidth
					className={props.className}
				>
					{inputs && (
						<Form
							method="patch"
							url="/api/notification"
							inputs={inputs}
							buttonText={props.t("organization.notifications.form.button")}
						/>
					)}
				</Card>
			</Animate>
		</Fragment>
	);
}
