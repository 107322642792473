import { useQuery } from "@tanstack/react-query";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import type * as React from "react";

import { appConfig } from "../../config";

interface CustomVoicesSelectProps {
	userId: string;
	organizationId: string;
	handleState: React.Dispatch<React.SetStateAction<number | null>>;
}

export const CustomVoicesSelect: React.FC<CustomVoicesSelectProps> = ({
	userId,
	organizationId,
	handleState,
}) => {
	const { data: voices } = useQuery({
		queryKey: ["custom-voices"],
		queryFn: async () => {
			const response = await fetch(
				`${appConfig.API_MAIN_URL}/api/custom-voice/get-all?userId=${userId}&organizationId=${organizationId}`,
			);
			return response.json();
		},
	});

	return (
		<Select onValueChange={(value) => handleState(Number(value))}>
			<SelectTrigger className="w-[180px]">
				<SelectValue placeholder="Select a voice (optional)" />
			</SelectTrigger>
			<SelectContent>
				<SelectGroup>
					<SelectLabel>Voices</SelectLabel>
					{voices?.map((voice: any) => (
						<SelectItem key={voice.id} value={voice.id}>
							{voice.name} - {voice.status}{" "}
							{voice.share_with_organization
								? "(shared with organization)"
								: ""}
						</SelectItem>
					))}
					<SelectItem value={"null"}>None</SelectItem>
				</SelectGroup>
			</SelectContent>
		</Select>
	);
};

export default CustomVoicesSelect;
