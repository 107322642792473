/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/

import axios from "axios";
import { Event } from "components/lib";
import { createContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { appConfig } from "../views/content-creation/config";

// auth context
export const AuthContext = createContext();
import { usePostHog } from "posthog-js/react";

import permissions from "./permissions";

export function AuthProvider(props) {
	const cache = JSON.parse(localStorage.getItem("user"));
	const [user, setUser] = useState(cache);
	const [auth, setAuth] = useState({
		loading: true,
	});
	const posthog = usePostHog();

	const [identifiedUser, setIdentifiedUser] = useState(false);

	useEffect(() => {
		const fetchAuth = async () => {
			const response = await fetch(`${appConfig.API_MAIN_URL}/api/auth`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${user?.token}`,
				},
			});

			if (response.ok) {
				const auth = await response.json();
				setAuth(auth);
				if (auth?.data?.email) {
					posthog.identify(auth?.data?.email, {
						email: auth?.data?.email,
						id: auth?.data?.id,
						name: auth?.data?.name,
						organization_id: auth?.data?.organizations[0]?.id,
						organization_name: auth?.data?.organizations[0]?.name,
					});
				}
			} else {
				if (!window.location.pathname.includes("singin")) {
					void signout();
				}
			}
		};

		void fetchAuth();
	}, [posthog.identify, user?.token]);

	if (user && !user?.organization_id) {
		console.log(
			"User exists in localStorage but has no organization_id. Clearing localStorage.",
		);
		localStorage.clear();
		setUser(null);
	}

	useEffect(() => {
		if (!auth.loading && auth.data) {
			if (auth.data.authenticated) {
				update(auth.data);
			} else {
				void signout();
			}
		}
	}, [auth]);

	function signin(res) {
		if (res.data) {
			posthog.identify(res.data?.email, {
				email: res.data?.email,
				id: res.data?.id,
				name: res.data?.name,
				organization_id: res.data?.organizations[0]?.id,
				organization_name: res.data?.organizations[0]?.name,
			});
			update(res.data);
			axios.defaults.headers.common.Authorization = `Bearer ${res.data.token}`;
			Event("signin");

			if (!res.data.verified) {
				console.log("User not verified, redirecting to /signup/verify");
				return window.location.replace("/signup/verify");
			}

			if (!res.data.plan) {
				console.log("User has no plan, redirecting too /signup/plan");
				return window.location.replace("/signup/plan");
			}

			console.log("Signin successful, redirecting to /dashboard");
			return window.location.replace("/dashboard");
		}
	}

	async function signout() {
		console.log("signout function called");
		await axios({ method: "delete", url: "/api/auth" });
		posthog.reset();
		localStorage.clear();
		setUser(null);
		console.log("Signout successful, redirecting to /signin");
		return window.location.replace("/signin");
	}

	async function switchOrganization(id) {
		console.log("switchOrganization function called with id", id);
		const res = await axios({
			method: "post",
			url: "/api/auth/switch",
			data: { organization: id },
		});

		if (res.data) {
			console.log("Organization switched, calling signin");
			signin(res);
			window.location.reload();
		}
	}

	function update(data) {
		const user = JSON.parse(localStorage.getItem("user")) || {};
		for (const key in data) {
			if (Array.isArray(data[key])) {
				user[key] = data[key];
			} else if (typeof data[key] === "object") {
				for (const innerKey in data[key]) {
					user[key][innerKey] = data[key][innerKey];
				}
			} else {
				user[key] = data[key];
			}
		}

		localStorage.setItem("user", JSON.stringify(user));
		setUser(user);
	}

	useEffect(() => {
		if (window.dataLayer && auth?.data && !identifiedUser) {
			window.dataLayer.push({ event: "gleap_init", user: auth?.data });
			setIdentifiedUser(true);
			console.log("User identified for dataLayer", auth?.data);
		}
	}, [auth, identifiedUser]);

	return (
		<AuthContext.Provider
			value={{
				user: user,
				signin: signin,
				signout: signout,
				update: update,
				switchOrganization: switchOrganization,
				permission: permissions[user?.permission],
			}}
			{...props}
		/>
	);
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
	const user = JSON.parse(localStorage.getItem("user"));
	const path = window.location.pathname;
	const permittedRoutes = [
		"/organization/billing",
		"/signup/plan",
		"/organization/upgrade",
		"/organization",
		"/organization/profile",
		"/onboarding",
	];

	if (user?.token) {
		if (!permissions[user.permission][props.permission]) {
			console.log("User lacks required permissions, redirecting to /dashboard");
			return <Navigate to="/dashboard" />;
		}

		if (user.verified) {
			if (user?.onboarded !== true && path !== "/onboarding") {
				console.log("User is not onboarded, redirecting to /onboarding");
				return <Navigate to="/onboarding" />;
			}

			if (path !== "/onboarding") {
				if (
					!user.plan &&
					path !== "/organization/profile" &&
					path !== "/signup/plan"
				) {
					console.log("User has no plan, redirecting to /signup/plan");
					return <Navigate to="/signup/plan" />;
				}

				if (
					user.subscription !== "active" &&
					user.subscription !== "trialing" &&
					user.permission !== "master" &&
					!permittedRoutes.includes(path)
				) {
					console.log(
						"User subscription is not active, redirecting to /organization/billing",
					);
					return <Navigate to="/organization/billing" />;
				}

				if (path === "/signup/verify") {
					console.log("User is verified, redirecting to /dashboard");
					return <Navigate to="/dashboard" />;
				}
			}
		} else {
			if (path !== "/organization/profile" && path !== "/signup/verify") {
				return <Navigate to="/signup/verify" />;
			}
		}

		return props.children;
	}

	console.log("User is not authenticated, redirecting to /signin");
	return <Navigate to="/signin" />;
}
