import { usePostHog } from "posthog-js/react";

export const useAnalytics = () => {
	const posthog = usePostHog();

	const captureEvent = (
		eventName: string,
		properties?: Record<string, unknown>,
	) => {
		if (posthog) {
			posthog.capture(eventName, properties);
		} else {
			console.warn("PostHog not initialized");
		}
	};

	return { captureEvent };
};
