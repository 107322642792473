import { useQuery } from "@tanstack/react-query";
import { appConfig } from "views/content-creation/config";
import { useContext } from "react";
import { AuthContext } from "components/lib";
import type { AuthContextType } from "types/authContext";
import axios from "axios";
import type { IcpSettings } from "../types";

export const useUserSettings = () => {
  const auth = useContext<AuthContextType>(AuthContext);

  const {
    data: settings,
    isLoading,
    error,
    refetch
  } = useQuery<IcpSettings>({
    queryKey: ["userSettings", auth.user?.id],
    queryFn: async () => {
      const { data } = await axios.get(
        `${appConfig.API_MAIN_URL}/api/user-settings`,
        {
          params: { user_id: auth.user?.id },
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
        }
      );
      return data;
    },
    enabled: !!auth.user?.token && !!auth.user?.id,
  });

  return {
    settings,
    isLoading,
    error,
    refetch,
  };
}; 