import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import { Edit, ChevronDown, ChevronRight } from "lucide-react";
import type { LinkedinPostType, LinkedPostMapping } from "../../../../types";
import { getPostStatusBadgeStyle, getPostStatusLabel } from "../utils/helpers";
import { Checkbox } from "components/ui/checkbox";

interface PostCardsProps {
  posts: LinkedinPostType[];
  selectedPosts: string[];
  onPostSelection: (postId: string) => void;
  expandedPosts: string[];
  togglePostExpansion: (postId: string) => void;
  linkedPosts: LinkedPostMapping[];
  onEditPublished: (blogPostId: string, domain?: string, status?: string) => void;
  publishSinglePost: (post: LinkedinPostType, isDraft: boolean) => void;
  selectedDomain?: { domain: string };
  selectedDraftPost: string | null;
  selectedPublishPost: string | null;
  isLoadingLinkedPosts: boolean;
  t: (key: string) => string;
}

const truncateContent = (content: string, expanded: boolean): string => {
  if (expanded) return content;
  const words = content.split(' ');
  if (words.length <= 15) return content;
  return words.slice(0, 15).join(' ') + '...';
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const PostCards = ({
  posts,
  selectedPosts,
  onPostSelection,
  expandedPosts,
  togglePostExpansion,
  linkedPosts,
  onEditPublished,
  publishSinglePost,
  selectedDomain,
  selectedDraftPost,
  selectedPublishPost,
  isLoadingLinkedPosts,
  t
}: PostCardsProps) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {posts.map((post) => (
        <Card key={post.linkedin_id} className="flex flex-col">
          <CardHeader className="flex-row items-center justify-between space-y-0 pb-2">
            <div className="flex items-center gap-2">
              <Checkbox
                checked={selectedPosts.includes(post.linkedin_id)}
                onCheckedChange={() => onPostSelection(post.linkedin_id)}
              />
              <span className="text-sm text-muted-foreground">
                {formatDate(post.creation_time)}
              </span>
            </div>
            {!isLoadingLinkedPosts && linkedPosts?.find(
              (item) => item.linkedin_post_id === post.linkedin_id
            ) && (
              <Badge
                variant="outline"
                className={getPostStatusBadgeStyle(
                  linkedPosts.find(
                    (item) => item.linkedin_post_id === post.linkedin_id
                  )?.status
                )}
              >
                {getPostStatusLabel(t, linkedPosts.find(
                  (item) => item.linkedin_post_id === post.linkedin_id
                )?.status)}
              </Badge>
            )}
          </CardHeader>
          <CardContent>
            <div className="relative">
              <p className="text-sm">
                {truncateContent(post.content, expandedPosts.includes(post.linkedin_id))}
              </p>
              {post.content.split(' ').length > 15 && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => togglePostExpansion(post.linkedin_id)}
                  className="mt-2"
                >
                  {expandedPosts.includes(post.linkedin_id) 
                    ? <ChevronDown className="h-4 w-4" />
                    : <ChevronRight className="h-4 w-4" />
                  }
                </Button>
              )}
            </div>
            {post.image_id && (
              <div className="relative w-full h-48 overflow-hidden rounded-md">
                <img
                  src={`https://media.licdn.com/dms/image/${post.image_id}`}
											alt="Post"
											className="object-cover w-full h-full"
										/>
              </div>
            )}
          </CardContent>
          <CardFooter className="mt-auto">
            {isLoadingLinkedPosts ? (
              <div className="h-9 w-full animate-pulse bg-gray-200 rounded" />
            ) : linkedPosts?.find(
              (item) => item.linkedin_post_id === post.linkedin_id
            ) ? (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  const mapping = linkedPosts.find(
                    (item) => item.linkedin_post_id === post.linkedin_id
                  );
                  if (mapping) {
                    onEditPublished(mapping.blog_post_id, selectedDomain?.domain, mapping.status);
                  }
                }}
                className="w-full"
              >
                <Edit className="h-4 w-4 mr-2" />
                {t("content-creation.blog_from_posts_bulk.edit")}
              </Button>
            ) : (
              <div className="flex gap-2 w-full">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => publishSinglePost(post, true)}
                  disabled={selectedDraftPost === post.linkedin_id}
                  className="flex-1"
                >
                  {selectedDraftPost === post.linkedin_id
                    ? t("content-creation.blog_from_posts_bulk.saving_draft")
                    : t("content-creation.blog_from_posts_bulk.publish_draft")}
                </Button>
                <Button
                  variant="default"
                  size="sm"
                  onClick={() => publishSinglePost(post, false)}
                  disabled={selectedPublishPost === post.linkedin_id}
                  className="flex-1"
                >
                  {selectedPublishPost === post.linkedin_id
                    ? t("content-creation.blog_from_posts_bulk.publishing_post")
                    : t("content-creation.blog_from_posts_bulk.publish_post")}
                </Button>
              </div>
            )}
          </CardFooter>
        </Card>
      ))}
    </div>
  );
}; 