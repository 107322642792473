/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { formatDistanceToNow } from "date-fns";
import { differenceInCalendarWeeks } from "date-fns";
import type { DateRange } from "react-day-picker";

import type { OrganizationUser } from "./_components/goal-creation";
import type {
	GrowthMetrics,
	MetricData,
	Objectives,
	PredictedMetricValues,
	UserMetricData,
	UserPredictedMetricData,
} from "./types";

export const accumulateMetrics = (
	data: UserPredictedMetricData,
): PredictedMetricValues => {
	return Object.values(data).reduce(
		(acc, current) => {
			acc.likes += current.likes;
			acc.comments += current.comments;
			acc.shares += current.shares;
			acc.impressions += current.impressions;
			return acc;
		},
		{ likes: 0, comments: 0, impressions: 0, shares: 0 },
	);
};

export const filterMetricsBySelectedUsers = (
	metrics: UserPredictedMetricData,
	selectedUsers: OrganizationUser[],
): UserPredictedMetricData => {
	return Object.entries(metrics)
		.filter(([userId]) => selectedUsers.some((user) => user.id === userId))
		.reduce((acc, [userId, metricValues]) => {
			acc[userId] = metricValues;
			return acc;
		}, {} as UserPredictedMetricData);
};

export const calculateWeeklyGrowth = (
	accumulateMetrics: PredictedMetricValues,
	growthMultipliers: GrowthMetrics,
	dateRange: DateRange,
): GrowthMetrics => {
	if (!dateRange.from || !dateRange.to) {
		throw new Error("Invalid date range");
	}
	const to = dateRange.to;
	const from = dateRange.from;
	const weeks = differenceInCalendarWeeks(to, from);

	const weeklyGrowthRate: GrowthMetrics = {
		likes: null,
		comments: null,
		impressions: null,
		shares: null,
	};

	for (const metric of Object.keys(accumulateMetrics) as Array<
		keyof PredictedMetricValues
	>) {
		const currentMetricValue = accumulateMetrics[metric];
		const growthMultiplier = growthMultipliers[metric];
		if (growthMultiplier) {
			const targetMetricValue = currentMetricValue * growthMultiplier;
			weeklyGrowthRate[metric] =
				(targetMetricValue / currentMetricValue) ** (1 / weeks);
		}
	}

	return weeklyGrowthRate;
};

export const calculateWeekNumber = (startDate: string | Date): number => {
	const now = new Date();
	const start = new Date(startDate);
	const timeDiff = now.getTime() - start.getTime();
	const weekNumber = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7)) + 1;
	return weekNumber;
};

export const calculateCurrentWeekObjective = (
	metric: MetricData,
	weekNumber: number,
): number => {
	return metric.total_initial_value * metric.weekly_growth_rate ** weekNumber;
};

export const calculateDueTime = (endDate: string | Date) => {
	const end = new Date(endDate);

	if (end <= new Date()) {
		return "Due now";
	}

	return `Due in ${formatDistanceToNow(end, { addSuffix: false })}`;
};

export const calculateWeightedCompletion = (
	data: UserMetricData[],
	objectives: Objectives,
) => {
	let totalImpressions = 0;
	let totalLikes = 0;
	let totalComments = 0;
	// let totalShares = 0;
	// Aggregate current values for each metric
	for (const weekData of data) {
		if (weekData.impressions !== undefined)
			totalImpressions += weekData.impressions.reduce(
				(sum, item) => sum + item.value,
				0,
			);
		if (weekData.likes !== undefined)
			totalLikes += weekData.likes.reduce((sum, item) => sum + item.value, 0);
		if (weekData.comments !== undefined)
			totalComments += weekData.comments.reduce(
				(sum, item) => sum + item.value,
				0,
			);
	}

	// Calculate the completion for each metric
	let impressionsCompletion = objectives.impressionsObjective
		? (totalImpressions / objectives.impressionsObjective) * 100
		: 0;
	let likesCompletion = objectives.likesObjective
		? (totalLikes / objectives.likesObjective) * 100
		: 0;
	let commentsCompletion = objectives.commentsObjective
		? (totalComments / objectives.commentsObjective) * 100
		: 0;
	// let sharesCompletion = objectives.sharesObjective
	//   ? (totalShares / objectives.sharesObjective) * 100
	//   : 0;

	// any value > 100% is capped at 100%
	if (impressionsCompletion > 100) impressionsCompletion = 100;
	if (likesCompletion > 100) likesCompletion = 100;
	if (commentsCompletion > 100) commentsCompletion = 100;
	// if (sharesCompletion > 100) sharesCompletion = 100;

	// Define weights
	const weights = {
		impressions: 0.75,
		likes: 0.15,
		comments: 0.1,
		// shares: 0.05,
	};

	// Adjust weights based on which metrics are present
	let totalWeight = 0;
	if (objectives.impressionsObjective !== undefined)
		totalWeight += weights.impressions;
	if (objectives.likesObjective !== undefined) totalWeight += weights.likes;
	if (objectives.commentsObjective !== undefined)
		totalWeight += weights.comments;
	// if (objectives.sharesObjective !== undefined) totalWeight += weights.shares;

	// Normalize weights
	const normalizedWeights = {
		impressions:
			objectives.impressionsObjective !== undefined
				? weights.impressions / totalWeight
				: 0,
		likes:
			objectives.likesObjective !== undefined ? weights.likes / totalWeight : 0,
		comments:
			objectives.commentsObjective !== undefined
				? weights.comments / totalWeight
				: 0,
		// shares:
		//   objectives.sharesObjective !== undefined
		//     ? weights.shares / totalWeight
		//     : 0,
	};

	// Calculate the overall weighted completion
	const weightedCompletion =
		impressionsCompletion * normalizedWeights.impressions +
		likesCompletion * normalizedWeights.likes +
		commentsCompletion * normalizedWeights.comments;
	// sharesCompletion * normalizedWeights.shares;

	return Math.round(weightedCompletion);
};

export const getBackgroundColorClass = (score: number) => {
	if (score < 25) return "bg-red-400/75";
	if (score < 50) return "bg-orange-300";
	if (score < 75) return "bg-yellow-300";
	if (score < 100) return "bg-lime-200";
	return "bg-green-300";
};

export const calculateScore = (row: any, metricTypes: string[]) => {
	const weights: { [key: string]: number } = {
		impressions: 50,
		posts: 30,
		likes: 15,
		comments: 5,
		// shares: 5,
	};

	let totalWeight = 0;
	let score = 0;

	for (const metric of metricTypes) {
		const metricPercentage = row[`${metric}Percentage`];
		if (metricPercentage !== undefined) {
			const weight = weights[metric] || 0;
			score += metricPercentage * weight;
			totalWeight += weight;
		}
	}

	return totalWeight > 0 ? score / totalWeight : 0;
};

export const calculateWeeklyGrowthFromFixed = (
	accumulatedMetrics: PredictedMetricValues,
	fixedTargets: GrowthMetrics,
	dateRange: DateRange,
): GrowthMetrics => {
	if (!dateRange.from || !dateRange.to) {
		throw new Error("Invalid date range");
	}
	const to = dateRange.to;
	const from = dateRange.from;
	const weeks = differenceInCalendarWeeks(to, from);

	const weeklyGrowthRate: GrowthMetrics = {
		likes: null,
		comments: null,
		impressions: null,
		shares: null,
	};

	for (const metric of Object.keys(accumulatedMetrics) as Array<
		keyof PredictedMetricValues
	>) {
		const currentMetricValue = accumulatedMetrics[metric];
		const fixedTarget = fixedTargets[metric];
		if (fixedTarget !== null) {
			weeklyGrowthRate[metric] =
				(fixedTarget / currentMetricValue) ** (1 / weeks);
		}
	}

	return weeklyGrowthRate;
};
