import { useQuery } from "@tanstack/react-query";
import axios from "axios";

interface LinkedInMetrics {
	posts: number;
	likes: number;
	comments: number;
	shares: number;
	impressions: number;
	followers: number;
	interactions: number;
	engagement: number;
}

interface WordPressMetrics {
	posts: number;
	views: number;
}

interface Metrics {
	date: string;
	linkedin: LinkedInMetrics;
	wordpress: WordPressMetrics;
}

interface PostMetrics {
	likes: number;
	shares: number;
	impressions: number;
	engagement: number;
	comments: number;
}

interface Post {
	id: string;
	date: string;
	content: string;
	metrics: PostMetrics;
	url: string;
}

interface DailyStat {
	date: string;
	post_count: number;
}

interface PostDetails {
	posts: Post[];
	daily_stats: DailyStat[];
}

interface PeriodInfo {
	current_period: {
		start_date: string;
		end_date: string;
	};
	previous_period: {
		start_date: string;
		end_date: string;
	};
}

interface DetailedMetricsResponse {
	metrics: Metrics[];
	post_details: PostDetails;
	period_info: PeriodInfo;
}

interface UseUserDetailedMetricsReturn {
	metrics: DetailedMetricsResponse | null;
	loading: boolean;
	error: Error | null;
}

export const useUserDetailedMetrics = (
	userId: string | undefined,
	startDate: string,
	endDate: string,
): UseUserDetailedMetricsReturn => {
	const fetchMetrics = async () => {
		const { data } = await axios.get<DetailedMetricsResponse>(
			`/api/analytics/metrics/user/${userId}?startDate=${startDate}&endDate=${endDate}`,
		);
		return data;
	};

	const { data, isLoading, error } = useQuery({
		queryKey: ["userMetrics", userId, startDate, endDate],
		queryFn: fetchMetrics,
		enabled: !!userId,
	});

	return {
		metrics: data ?? null,
		loading: isLoading,
		error,
	};
};
