const Style = {
	base: "ml-0 list-none",
	item: "relative text-left bg-[length:12px] bg-left bg-center pl-5 mb-2 leading-6",
	checkGreen: `bg-[url('/assets/icons/ico-check-green.svg')]`,
	checkWhite: `bg-[url('/assets/icons/ico-check-white.svg')]`,
	cross: `bg-[url('/assets/icons/ico-cross-red.svg')]`,
	interactive: "cursor-pointer",
};

export default Style;
