import { Badge } from "components/ui/badge";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "components/ui/tooltip";
import { differenceInDays, formatDistanceToNow } from "date-fns";
import { AlertCircle, CircleCheck, XCircle } from "lucide-react";

const SyncStatusBadge = ({ syncStatus, lastSyncDate }) => {
	const formatTooltip = (date) => {
		if (!date) return "Never synced";
		return `Last synced ${formatDistanceToNow(new Date(date), { addSuffix: true })}`;
	};

	const renderBadge = (icon, variant) => (
		<TooltipProvider>
			<Tooltip>
				<TooltipTrigger>
					<Badge variant={variant}>{icon}</Badge>
				</TooltipTrigger>
				<TooltipContent>
					<p>{formatTooltip(lastSyncDate)}</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);

	if (syncStatus === "Never synced" || !lastSyncDate) {
		return renderBadge(<XCircle className="w-4 h-4" />, "destructive");
	}

	const daysSinceLastSync = differenceInDays(
		new Date(),
		new Date(lastSyncDate),
	);

	if (syncStatus === "success") {
		if (daysSinceLastSync < 1) {
			return renderBadge(<CircleCheck className="w-4 h-4" />, "success");
		}
		if (daysSinceLastSync < 3) {
			return renderBadge(<AlertCircle className="w-4 h-4" />, "warning");
		}
		return renderBadge(<AlertCircle className="w-4 h-4" />, "destructive");
	}
	return renderBadge(<XCircle className="w-4 h-4" />, "destructive");
};

export default SyncStatusBadge;
