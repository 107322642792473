/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { interactWithExtension } from "lib/utils";
import { useEffect, useState } from "react";

export const useLinkedinProfileId = (pollingInterval: number | null = 1000) => {
	const [profileId, setProfileId] = useState<string | null>(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const checkProfileId = () => {
			getProfileIdFromExtension()
				.then((fetchedProfileId) => {
					setProfileId(fetchedProfileId);
					setLoading(false);

					// Stop polling if the profile ID is successfully fetched
					if (fetchedProfileId && intervalId !== null) {
						clearInterval(intervalId);
						intervalId = null; // Set intervalId to null to prevent further clearing attempts
					}
				})
				.catch((error) => {
					setLoading(false);
					setError(error);
				});
		};

		// Perform an initial check
		checkProfileId();

		let intervalId: number | null = null;

		// Set up polling only if the profile ID is not initially found and pollingInterval is provided
		if (!profileId && pollingInterval) {
			intervalId = window.setInterval(checkProfileId, pollingInterval);
		}

		// Cleanup interval on component unmount
		return () => {
			if (intervalId !== null) {
				window.clearInterval(intervalId);
			}
		};
	}, [pollingInterval, profileId]);

	return { profileId, loading, error };
};

const getProfileIdFromExtension = (): Promise<string> => {
	return new Promise((resolve, reject) => {
		try {
			interactWithExtension<{ profileId?: string; error?: string }>(
				"extractProfileId",
				(response) => {
					if (response?.profileId) {
						resolve(response.profileId);
					} else {
						reject(`Error extracting profile ID: ${response?.error}`);
					}
				},
			);
		} catch (error) {
			reject(error);
		}
	});
};
