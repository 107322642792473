import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { icpFormSchema } from "../schemas";
import type { IcpFormData } from "../schemas";
import { leadLensApi } from "../services/lead-lens-api";
import { useUserSettings } from "./use-user-settings";

export const useIcpForm = (userId: string, token: string) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const form = useForm<IcpFormData>({
		resolver: zodResolver(icpFormSchema),
		defaultValues: {
			icp_target_industries: "",
			icp_target_roles: "",
			icp_company_size: "",
			icp_geography: "",
			icp_goals: "",
			icp_description: "",
		},
	});

	const {
		settings,
		isLoading: settingsLoading,
		refetch: refetchSettings,
	} = useUserSettings();

	useEffect(() => {
		if (settings) {
			form.reset({
				icp_description: settings.icp_description || "",
				icp_target_industries: settings.icp_target_industries || "",
				icp_target_roles: settings.icp_target_roles || "",
				icp_company_size: settings.icp_company_size || "",
				icp_geography: settings.icp_geography || "",
				icp_goals: settings.icp_goals || "",
			});
		}
	}, [settings, form]);

	const handleSubmit = async (data: IcpFormData) => {
		try {
			setIsSubmitting(true);
			await leadLensApi.updateIcpSettings({
				userId,
				token,
				data,
			});
			await refetchSettings();
			form.reset(data);
			return true;
		} catch (error) {
			console.error("Error updating ICP settings:", error);
			return false;
		} finally {
			setIsSubmitting(false);
		}
	};

	return {
		form,
		settingsLoading,
		isSubmitting,
		handleSubmit,
	};
}; 