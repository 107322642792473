import { interactWithExtension } from "lib/utils";
import { useEffect, useState } from "react";

export const useExtensionInstalled = (
	pollingInterval: number | null = 1000,
) => {
	const [extensionInstalled, setExtensionInstalled] = useState<boolean | null>(
		null,
	);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const checkExtensionInstalled = () => {
			getExtensionIdFromExtension()
				.then((installed) => {
					setExtensionInstalled(installed);
					setLoading(false);

					// If installed, clear any existing interval
					if (installed && intervalId !== null) {
						clearInterval(intervalId); // Stop polling if the extension is installed
						intervalId = null; // Set intervalId to null to prevent further clearing attempts
					}
				})
				.catch((error) => {
					setLoading(false);
					setError(error);
				});
		};

		// Perform an initial check
		checkExtensionInstalled();

		let intervalId: number | null = null;

		// Set up polling only if the extension is not initially installed and pollingInterval is provided
		if (!extensionInstalled && pollingInterval) {
			intervalId = window.setInterval(checkExtensionInstalled, pollingInterval);
		}

		// Cleanup interval on component unmount
		return () => {
			if (intervalId !== null) {
				window.clearInterval(intervalId);
			}
		};
	}, [pollingInterval, extensionInstalled]);

	return { extensionInstalled, loading, error };
};

const getExtensionIdFromExtension = async (): Promise<boolean> => {
	return new Promise((resolve, reject) => {
		try {
			interactWithExtension<{ installed: boolean; error?: string }>(
				"checkExtension",
				(response) => {
					if (response?.installed) {
						resolve(true);
					} else {
						reject(`Error extracting profile ID: ${response?.error}`);
					}
				},
			);
		} catch (error) {
			reject(error);
		}
	});
};
