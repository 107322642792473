import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Animate, Card, Form, OrganizationNav } from "components/lib";
import { Fragment, useState } from "react";
import { WordPressUserSelector } from "views/content-creation/_components/tools/WordpressBlogFromLinkedinPost/wordpress-user-selector";
import { useToast } from "components/hooks/use-toast";

interface UserSettingsProps {
	t: (key: string) => string;
	className?: string;
}

export function UserSettings({ t, className }: UserSettingsProps) {
	const { toast } = useToast();
	const queryClient = useQueryClient();
	const [autoPublish, setAutoPublish] = useState(true);
	const [autoPostToBlog, setAutoPostToBlog] = useState(false);
	const [blogStatusNotifications, setBlogStatusNotifications] = useState(true);

	const { data: user, isLoading: isLoadingUser } = useQuery({
		queryKey: ["user"],
		queryFn: async () => await axios.get('/api/user'),
	});

	const {
		isLoading: isLoadingSettings,
		data: userSettings,
		error,
	} = useQuery({
		queryKey: ["userSettings"],
		queryFn: async () => {
			const userSettings = await axios.get(
				`/api/user-settings?user_id=${user?.data?.data.id}`,
			);
			setAutoPublish(userSettings.data.auto_publish);
			setAutoPostToBlog(userSettings.data.auto_post_to_blog);
			setBlogStatusNotifications(userSettings.data.notifications_enabled ?? true);
			return userSettings.data;
		},
		enabled: !!user?.data?.data.id,
	});

	const modifyMutation = useMutation({
			mutationFn: async (formData: {
				user_id: string;
				auto_publish: boolean;
				auto_post_to_blog: boolean;
				blog_status_notifications: boolean;
			}) => {
				await axios.post('/api/user-settings', formData);
			},
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: ["userSettings"] });
				toast({
					title: t("organization.user_settings.save_success_title"),
					description: t("organization.user_settings.save_success_description"),
					variant: "default",
				});
			},
			onError: () => {
				console.error("Error al modificar los ajustes del usuario");
				toast({
					title: t("organization.user_settings.save_error_title"),
					description: t("organization.user_settings.save_error_description"),
					variant: "destructive",
				});
			},
		});

	const handleSubmit = async () => {
		if (user?.data?.data.id) {
			modifyMutation.mutate({
				user_id: user.data.data.id,
				auto_publish: autoPublish,
				auto_post_to_blog: autoPostToBlog,
				blog_status_notifications: blogStatusNotifications,
			});
		} else {
			console.error("ID de usuario no disponible");
		}
	};

	const handleChange = (value: {
		input: string;
		value: boolean;
		valid: boolean;
	}) => {
		switch (value.input) {
			case "auto_publish":
				setAutoPublish(value.value);
				break;
			case "auto_post_to_blog":
				setAutoPostToBlog(value.value);
				break;
			case "blog_status_notifications":
				setBlogStatusNotifications(value.value);
				break;
		}
	};

	const isLoading = isLoadingUser || isLoadingSettings || modifyMutation.isPending;

	return (
		<Fragment>
			<OrganizationNav />
			<Animate>
				<div className="flex flex-col md:flex-row gap-4">
					<Card
						title={t("organization.user_settings.title")}
						restrictWidth
						className={className}
						loading={isLoading}
					>
						{!isLoading && (
							<>
								<p>
									{t("organization.user_settings.auto_post_to_blog_description")}
								</p>
								<Form
									inputs={{
										auto_post_to_blog: {
											type: "switch",
											default: autoPostToBlog,
											name: "auto_post_to_blog",
											label: t(
												"organization.user_settings.auto_post_to_blog_label",
											),
											value: autoPostToBlog,
											description: 
												"organization.user_settings.auto_post_to_blog_description",
											
										},
										...(autoPostToBlog && {
											auto_publish: {
												type: "switch",
												default: autoPublish,
												name: "auto_publish",
												label: t("organization.user_settings.auto_publish_label"),
												value: autoPublish,
												description: t(
													"organization.user_settings.auto_publish_description",
												),
											},
										}),
									}}
									buttonText={t("organization.user_settings.save_button")}
									updateOnChange
									onChange={handleChange}
									callback={handleSubmit}
									loading={modifyMutation.isPending}
								/>
							</>
						)}
					</Card>

					{(
						<Card
							title={t("organization.user_settings.wordpress_settings")}
							className={className}
							loading={isLoading}
						>
							{!isLoading && (
								<>
									<p>
										{t("organization.user_settings.wordpress_settings_description")}
									</p>
									{user?.data?.data.id && (
										<WordPressUserSelector
											userId={user.data.data.id}
											organizationId={user.data.data.organization_id}
											onUserSelected={(wpUser) => {
												console.log("WordPress user selected:", wpUser);
											}}
										/>
									)}
								</>
							)}
						</Card>
					)}

					<Card
						title={t("organization.user_settings.notifications")}
						className={className}
						loading={isLoading}
					>
						{!isLoading && (
							<>
								<p>
									{t("organization.user_settings.notifications_description")}
								</p>
								<Form
									inputs={{
										blog_status_notifications: {
											type: "switch", 
											default: blogStatusNotifications,
											name: "blog_status_notifications",
											label: t("organization.user_settings.notifications_label"),
											value: blogStatusNotifications,
											description: t("organization.user_settings.notifications_description"),
										},
									}}
									buttonText={t("organization.user_settings.save_button")}
									updateOnChange
									onChange={handleChange}
									callback={handleSubmit}
									loading={modifyMutation.isPending}
								/>
							</>
						)}
					</Card>
				</div>
			</Animate>
		</Fragment>
	);
}
