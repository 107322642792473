/***
 *
 *   ORGANIZATION NAV
 *   Acount sub nav that renders different options based
 *   on user permissions. Shows billing & user admin to only organization owners
 *
 **********/

import { SubNav, useTranslation } from "components/lib";

export function OrganizationNav() {
	const { t } = useTranslation();

	return (
		<SubNav
			items={[
				{ link: "/organization/profile", label: t("organization.nav.profile") },
				{
					link: "/organization/password",
					label: t("organization.nav.password"),
				},
				{ link: "/organization/2fa", label: t("organization.nav.2fa") },
				{
					link: "/organization/billing",
					label: t("organization.nav.billing"),
					permission: "owner",
				},
				{
					link: "/organization/notifications",
					label: t("organization.nav.notifications"),
					permission: "user",
				},
				{
					link: "/organization/apikeys/",
					label: t("organization.nav.api_keys"),
					permission: "developer",
				},
				{
					link: "/organization/users",
					label: t("organization.nav.users"),
					permission: "admin",
				},
				{
					link: "/organization/user-settings",
					label: t("organization.nav.user_settings"),
					permission: "user",
				},
			]}
		/>
	);
}
