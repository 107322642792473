/***
 *
 *   SETUP DATABASE
 *   Configures your Stripe settings for processing payments.
 *   You can delete this when you've completed the setup process.
 *
 **********/

import { Form, Helper, Loader, useAPI } from "components/lib";
import { Fragment } from "react";

export function SetupStripe() {
	const { data: settings, loading } = useAPI("/api/setup/stripe");

	if (loading) return <Loader />;

	return (
		<Fragment>
			<Helper
				text="Need help? Refer to the Stripe setup guide"
				url="https://docs.usegravity.app/gravity-server/installation/stripe-setup"
			/>

			<Form
				inputs={{
					test_pk: {
						label: "Test Publishable API Key",
						type: "text",
						required: true,
						placeholder: "pk_test",
						value: settings?.publishableAPIKey,
						errorMessage: "Please enter your test publishable API key",
					},
					test_sk: {
						label: "Test Secret API Key",
						type: "text",
						required: true,
						placeholder: "sk_test",
						value: settings?.secretAPIKey,
						errorMessage: "Please enter your test secret API key",
					},
					header: {
						type: "header",
						title: "Live Publishable Key",
						label:
							"Please also add your live secret key to your development environment",
					},
					live_pk: {
						label: "Live Publishable API Key",
						type: "text",
						placeholder: "pk_live",
						errorMessage: "Please enter your publishable API key",
					},
					live_sk: {
						label: "Live Secret API Key",
						type: "text",
						placeholder: "sk_live",
						errorMessage: "Please enter your secret API key",
					},
					freePlan: {
						label: "Include a free plan",
						type: "switch",
						default: true,
					},
				}}
				url="/api/setup/stripe"
				method="POST"
				buttonText="Save"
			/>
		</Fragment>
	);
}
