import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import {
	ChartContainer,
	ChartTooltip,
	ChartTooltipContent,
} from "components/ui/chart";
import { differenceInWeeks, parseISO, setWeek, startOfWeek } from "date-fns";
import * as React from "react";
import { Label, Pie, PieChart } from "recharts";

import type {
	GoalMetricType,
	GoalType,
	MetricKeys,
	UserInitialGoalValueType,
	WeekProgressType,
} from "../types";
import {
	calculateCompletion,
	formatNumber,
	generateBlueHues,
	getCompletionFillColor,
} from "../utils";

import { useMemo } from "react";

interface MetricPieChartVsGoalsProps {
	weekProgressData: WeekProgressType[];
	userInitialGoalMetrics: UserInitialGoalValueType[];
	metricKey: MetricKeys;
	title: string;
	description: string;
	selectedWeek: number;
	goalMetrics: GoalMetricType[];
	goal: GoalType;
	selectedTeamId: string | null;
}

const getAccumulatedData = (
	weekProgressData: WeekProgressType[],
	userInitialGoalMetrics: UserInitialGoalValueType[],
	metricKey: MetricKeys,
	selectedWeek: number,
	selectedTeamId: string | null,
	goalMetrics: GoalMetricType[],
	goal: GoalType,
) => {
	const mostRecentWeekData = weekProgressData[weekProgressData.length - 1];
	const metricArray = mostRecentWeekData[metricKey];
	let totalValue = 0;

	const userStartDates = userInitialGoalMetrics.reduce(
		(acc, { metricType, data }) => {
			if (metricType === metricKey) {
				for (const { user_id, start_date } of data) {
					acc[user_id] = parseISO(start_date.split("T")[0]);
				}
			}
			return acc;
		},
		{} as { [key: string]: Date },
	);

	const currentYear = new Date().getFullYear();
	const selectedWeekDate = setWeek(new Date(currentYear, 0, 1), selectedWeek, {
		weekStartsOn: 1,
	});

	if (Array.isArray(metricArray)) {
		totalValue = metricArray.reduce((sum, { user_id, value }) => {
			const startDate = userStartDates[user_id];
			if (startDate) {
				const weeksPassed = differenceInWeeks(
					startOfWeek(selectedWeekDate, { weekStartsOn: 1 }),
					startOfWeek(startDate, { weekStartsOn: 1 }),
				);

				if (weeksPassed >= 0) {
					return sum + value;
				}
			}
			return sum;
		}, 0);
	}

	const objectives = userInitialGoalMetrics.reduce(
		(acc, { metricType, weeklyGrowthRate, data }) => {
			if (metricType === metricKey) {
				if (!selectedTeamId) {
					const goalMetric = goalMetrics.find(
						(gm) => gm.metric_type === metricKey,
					);
					if (goalMetric) {
						const weeksPassed = differenceInWeeks(
							startOfWeek(selectedWeekDate, { weekStartsOn: 1 }),
							startOfWeek(parseISO(goal.start_date.split("T")[0]), {
								weekStartsOn: 1,
							}),
						);
						const expectedValue = Math.round(
							goalMetric.initial_value * weeklyGrowthRate ** weeksPassed,
						);
						acc.total = {
							initial_value: goalMetric.initial_value,
							expectedValue,
						};
					}
				} else {
					for (const { user_id, initial_value, start_date } of data) {
						const startDate = parseISO(start_date.split("T")[0]);
						const weeksPassed = differenceInWeeks(
							startOfWeek(selectedWeekDate, { weekStartsOn: 1 }),
							startOfWeek(startDate, { weekStartsOn: 1 }),
						);

						if (weeksPassed >= 0) {
							const expectedValue = Math.round(
								initial_value * weeklyGrowthRate ** weeksPassed,
							);
							if (!acc[user_id]) {
								acc[user_id] = { initial_value, expectedValue };
							} else {
								acc[user_id].expectedValue += expectedValue;
							}
						}
					}
				}
			}
			return acc;
		},
		{} as { [key: string]: { initial_value: number; expectedValue: number } },
	);

	const totalObjective = Object.values(objectives).reduce(
		(sum, { expectedValue }) => sum + expectedValue,
		0,
	);

	const accumulatedData = [
		{
			name: "Achieved",
			value: totalValue,
			fill: "hsl(220, 70%, 50%)",
		},
		{
			name: "Remaining",
			value: Math.max(0, totalObjective - totalValue),
			fill: "hsl(0, 0%, 90%)",
		},
	];

	return { accumulatedData, objectives, totalObjective };
};

const generateChartConfig = (title: string) => ({
	[title.toLowerCase()]: {
		label: title,
	},
});

export const MetricPieChartVsGoals = ({
	weekProgressData,
	userInitialGoalMetrics,
	metricKey,
	title,
	description,
	selectedWeek,
	goalMetrics,
	selectedTeamId,
	goal,
}: MetricPieChartVsGoalsProps) => {
	const filteredWeekProgressData = useMemo(() => {
		return weekProgressData.filter((weekData) => weekData.week <= selectedWeek);
	}, [weekProgressData, selectedWeek]);

	const { accumulatedData, objectives, totalObjective } = useMemo(
		() =>
			getAccumulatedData(
				filteredWeekProgressData,
				userInitialGoalMetrics,
				metricKey,
				selectedWeek,
				selectedTeamId,
				goalMetrics,
				goal,
			),
		[
			filteredWeekProgressData,
			userInitialGoalMetrics,
			metricKey,
			selectedWeek,
			selectedTeamId,
			goalMetrics,
			goal,
		],
	);

	const total = React.useMemo(() => {
		return accumulatedData[0].value;
	}, [accumulatedData]);

	const completion = React.useMemo(
		() => Math.round(calculateCompletion(total, totalObjective)),
		[total, totalObjective],
	);

	const chartConfig = React.useMemo(() => generateChartConfig(title), [title]);

	return (
		<Card className="flex flex-col bg-white min-w-[290px]">
			<CardHeader className="items-center pb-0">
				<CardTitle className="text-2xl">{title}</CardTitle>
				<CardDescription>
					<div className="flex items-center gap-2 font-medium leading-none whitespace-nowrap">
						This week's objective:{" "}
						<span className="font-bold">{formatNumber(totalObjective)}</span>
					</div>
				</CardDescription>
			</CardHeader>
			<CardContent className="flex-1 py-2">
				<ChartContainer config={chartConfig} className="mx-auto aspect-[5/3]">
					<PieChart>
						<ChartTooltip
							cursor={false}
							content={<ChartTooltipContent hideLabel />}
						/>
						<Pie
							data={accumulatedData}
							dataKey="value"
							nameKey="name"
							innerRadius={70}
							outerRadius={90}
							strokeWidth={5}
							startAngle={180}
							endAngle={0}
							paddingAngle={0}
							animationBegin={250}
							cy={110}
						>
							<Label
								content={({ viewBox }) => {
									if (viewBox && "cx" in viewBox && "cy" in viewBox) {
										return (
											<text
												x={viewBox.cx}
												y={viewBox.cy}
												textAnchor="middle"
												dominantBaseline="middle"
											>
												<tspan
													x={viewBox.cx}
													y={(viewBox.cy || 0) - 20}
													className="fill-foreground text-3xl font-bold"
												>
													{formatNumber(total)}
												</tspan>
												<tspan
													x={viewBox.cx}
													y={viewBox.cy || 0}
													className={`${getCompletionFillColor(completion)} text-sm font-bold`}
												>
													{completion}%
												</tspan>
											</text>
										);
									}
								}}
							/>
						</Pie>
					</PieChart>
				</ChartContainer>
			</CardContent>
		</Card>
	);
};
