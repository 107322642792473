const getAppConfig = () => {
	switch (import.meta.env.VITE_APP_ENV) {
		case "development":
			return {
				API_URL: "http://localhost:3005",
				API_MAIN_URL: "http://localhost:8080",
			};
		case "staging":
			return {
				API_URL: "https://inbound-tools-staging.up.railway.app",
				API_MAIN_URL: "https://backoffice-server-staging.up.railway.app",
			};
		case "production":
			return {
				API_URL: "https://inbound-tools-production.up.railway.app",
				API_MAIN_URL: "https://backoffice-server-production.up.railway.app",
			};
		default:
			return {
				API_URL: "http://localhost:3005",
				API_MAIN_URL: "http://localhost:8080",
			};
	}
};

export const appConfig = getAppConfig();
