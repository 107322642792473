import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import type { LinkedinMetrics, WordPressMetrics } from "types/metricTypes";

export interface User {
	user_name: string;
	user_id: string;
	linkedin: LinkedinMetrics;
	wordpress: WordPressMetrics;
}

interface UseOrganizationUsersReturn {
	users: User[] | null;
	loading: boolean;
	error: Error | null;
}

export const useOrganizationUsers = (
	organizationId: string | undefined,
	startDate: string,
	endDate: string,
	timeFrameType = "days",
): UseOrganizationUsersReturn => {
	const fetchUsers = async () => {
		const { data } = await axios.get<User[]>(
			`/api/analytics/${organizationId}/collaborators?startDate=${startDate}&endDate=${endDate}&type=${timeFrameType}`,
		);
		return data;
	};

	const { data, isLoading, error } = useQuery({
		queryKey: [
			"organizationUsers",
			organizationId,
			startDate,
			endDate,
			timeFrameType,
		],
		queryFn: fetchUsers,
		enabled: !!organizationId,
	});

	return {
		users: data ?? null,
		loading: isLoading,
		error,
	};
};
