import { appConfig } from "./config";

const API_URL = appConfig.API_URL;

type ErrorWithMessage = { message: string };
function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
	return (
		typeof error === "object" &&
		error !== null &&
		"message" in error &&
		typeof (error as Record<string, unknown>).message === "string"
	);
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
	if (isErrorWithMessage(maybeError)) return maybeError;
	try {
		return new Error(JSON.stringify(maybeError));
	} catch {
		// fallback in case there's an error stringifying the maybeError
		// like with circular references for example.
		return new Error(String(maybeError));
	}
}

function getErrorMessage(error: unknown) {
	return toErrorWithMessage(error).message;
}

export class API {
	private defaultHeaders: Record<string, string> = {
		"Content-Type": "application/json",
	};

	private handleResponse = async <T>(
		response: Response,
		responseType?: string,
		onBadResponse?: (response: Response) => void,
	): Promise<T> => {
		if (response.ok) {
			switch (responseType ?? response.headers.get("Content-Type")) {
				case "application/json": {
					return (await response.json()) as T;
				}
				case "text/plain": {
					return (await response.text()) as unknown as T;
				}
				case "application/octet-stream": {
					return (await response.blob()) as unknown as T;
				}
				case "application/pdf": {
					return (await response.arrayBuffer()) as unknown as T;
				}
				case "text/html": {
					return (await response.text()) as unknown as T;
				}
				default: {
					return (await response.json()) as T;
				}
			}
		} else {
			if (onBadResponse) {
				onBadResponse(response);
			}
			throw new Error(
				`Server responded with: ${response.status} ${response.statusText}`,
			);
		}
	};

	private handleError = (error: unknown): Promise<never> => {
		const errorMessage = getErrorMessage(error);
		throw new Error(errorMessage);
	};

	public get = async <T>(
		endpoint: string,
		options?: RequestInit,
		responseType?: string,
		onBadResponse?: (response: Response) => void,
	) => {
		/* if endpoint is a whole url, dont add the API_URL */

		const url = endpoint.startsWith("http")
			? endpoint
			: `${API_URL}/${endpoint}`;

		const requestOptions: RequestInit = {
			method: "GET",
			headers: { ...this.defaultHeaders, ...options?.headers },
			...options,
		};

		try {
			const response = await fetch(url, requestOptions);
			return await this.handleResponse<T>(
				response,
				responseType,
				onBadResponse,
			);
		} catch (error) {
			return await this.handleError(error);
		}
	};

	public post = async <T>(
		endpoint: string,
		body?: unknown,
		options?: RequestInit,
		responseType?: string,
		onBadResponse?: (response: Response) => void,
	) => {
		const url = `${API_URL}/${endpoint}`;
		const requestBody = options?.body || JSON.stringify(body);
		const requestOptions: RequestInit = {
			method: "POST",
			headers: { ...this.defaultHeaders, ...options?.headers },
			body: requestBody,
			...options,
		};

		try {
			const response = await fetch(url, requestOptions);
			return await this.handleResponse<T>(
				response,
				responseType,
				onBadResponse,
			);
		} catch (error) {
			return await this.handleError(error);
		}
	};
}

export const api = new API();
