import { Skeleton } from "components/ui/skeleton";

export function SkeletonChart() {
	return (
		<div className="flex flex-col space-y-4">
			<div className="flex justify-between items-center">
				<div className="space-y-2">
					<Skeleton className="h-6 w-48" />
					<Skeleton className="h-4 w-64" />
				</div>
				<Skeleton className="h-8 w-36" />
			</div>
			<div className="flex gap-2 mb-4 items-center">
				<Skeleton className="h-10 w-24" />
				<Skeleton className="h-10 w-24" />
			</div>
			<Skeleton className="h-64 w-full" />
			<Skeleton className="h-6 w-64 mt-4" />
		</div>
	);
}
