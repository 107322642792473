import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import { type ChartConfig, ChartContainer } from "components/ui/chart";
import { RefreshCcw } from "lucide-react";

import {
	Label,
	PolarGrid,
	PolarRadiusAxis,
	RadialBar,
	RadialBarChart,
} from "recharts";

export type BenchmarkResult = {
	name: string;
	description: string;
	value: number;
	maxValue: number;
};

interface Props extends BenchmarkResult {
	refreshAction?: () => void;
}

const getColor = (value: number, maxValue: number) => {
	if (value / maxValue < 0.25) {
		return "#FF0000";
	}
	if (value / maxValue < 0.65) {
		return "#FF8000";
	}
	return "#288128";
};

export function ReadabilityCard({
	name,
	description,
	value,
	maxValue,
	refreshAction,
}: Props) {
	const chartConfig = {
		visitors: {
			label: "Points",
		},
		safari: {
			label: "points",
			color: getColor(value, maxValue),
		},
	} satisfies ChartConfig;

	const chartData = [
		{ browser: "safari", visitors: value, fill: getColor(value, maxValue) },
	];

	return (
		<Card className="flex flex-col">
			<CardHeader className="relative items-center pb-0">
				<button
					type="button"
					className="absolute top-3 right-3 text-muted-foreground"
					onClick={refreshAction}
				>
					<RefreshCcw className="w-4 h-4" />
				</button>
				<CardTitle>{name}</CardTitle>
				<CardDescription>Benchmark</CardDescription>
			</CardHeader>
			<CardContent className="flex-1 pb-0">
				<ChartContainer
					config={chartConfig}
					className="mx-auto aspect-square max-h-[250px]"
				>
					<RadialBarChart
						data={chartData}
						startAngle={0}
						endAngle={Number(((value / maxValue) * 360).toFixed(0))}
						innerRadius={80}
						outerRadius={110}
					>
						<PolarGrid
							gridType="circle"
							radialLines={false}
							stroke="none"
							className="first:fill-muted last:fill-background"
							polarRadius={[86, 74]}
						/>
						<RadialBar dataKey="visitors" background cornerRadius={10} />
						<PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
							<Label
								content={({ viewBox }) => {
									if (viewBox && "cx" in viewBox && "cy" in viewBox) {
										return (
											<text
												x={viewBox.cx}
												y={viewBox.cy}
												textAnchor="middle"
												dominantBaseline="middle"
											>
												<tspan
													x={viewBox.cx}
													y={viewBox.cy}
													className="text-4xl font-bold fill-foreground"
												>
													{chartData[0].visitors.toFixed(0).toLocaleString()}
												</tspan>
												<tspan
													x={viewBox.cx}
													y={(viewBox.cy || 0) + 24}
													className="fill-muted-foreground"
												>
													Points
												</tspan>
											</text>
										);
									}
								}}
							/>
						</PolarRadiusAxis>
					</RadialBarChart>
				</ChartContainer>
			</CardContent>
			<CardFooter className="flex-col gap-2 text-sm">
				<div className="leading-none text-muted-foreground">{description}</div>
			</CardFooter>
		</Card>
	);
}
