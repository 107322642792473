/***
 *
 *   API Key Editor
 *   Create or edit a new/existing API key
 *
 **********/

import Axios from "axios";
import {
	Animate,
	Breadcrumbs,
	Card,
	Form,
	Loader,
	Message,
	OrganizationNav,
	TextInput,
	useAPI,
	useLocation,
	useNavigate,
} from "components/lib";
import { useCallback, useEffect, useState } from "react";

export function APIKeyEditor(props) {
	const navigate = useNavigate();
	const location = useLocation();

	// get the scopes
	const scopes = useAPI("/api/key/scopes");

	// state
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(false);
	const [newAPIKey, setNewAPIKey] = useState(false);

	const fetch = useCallback(async () => {
		setLoading(true);
		const res = await Axios.get(`/api/key/${location.search?.slice(4)}`);
		res.data.data.length > 0 ? setData(res.data.data[0]) : navigate("/404");
		setLoading(false);
	}, [location.search, navigate]);

	useEffect(() => {
		// editing existing key?
		const id = location.search;
		if (id) fetch(id).catch((e) => console.error(e));
	}, [fetch, location.search]);

	if (scopes.loading) return <Loader />;

	return (
		<Animate>
			<OrganizationNav />

			<Breadcrumbs
				items={[
					{
						name: props.t("organization.api_keys.edit.breadcrumbs.keys"),
						url: "/organization/apikeys",
					},
					{
						name: `${data ? props.t("organization.api_keys.edit.breadcrumbs.edit") : props.t("organization.api_keys.edit.breadcrumbs.create")}`,
						url: "/organization/apikeys/create",
					},
				]}
			/>

			{newAPIKey ? (
				<Message
					type="warning"
					closable
					title={props.t("organization.api_keys.edit.message.title")}
					buttonText={props.t("organization.api_keys.edit.message.button")}
					buttonLink="/organization/apikeys"
				>
					<TextInput value={newAPIKey} />
				</Message>
			) : (
				<Card
					title={`${data ? props.t("organization.api_keys.edit.breadcrumbs.edit") : props.t("organization.api_keys.edit.breadcrumbs.create")} API Key`}
					loading={loading}
				>
					<Form
						inputs={{
							name: {
								label: props.t("organization.api_keys.edit.form.name.label"),
								type: "text",
								required: true,
								value: data.name,
								errorMessage: props.t(
									"organization.api_keys.edit.form.name.error",
								),
							},
							scope: {
								label: props.t("organization.api_keys.edit.form.scope.label"),
								type: "checkbox",
								required: true,
								min: 1,
								default: data.scope,
								options: scopes?.data,
								errorMessage: props.t(
									"organization.api_keys.edit.form.scope.error",
								),
							},
						}}
						url={data ? `/api/key/${data.id}` : "/api/key"}
						method={data ? "PATCH" : "POST"}
						buttonText={props.t("organization.api_keys.edit.form.button")}
						callback={(res) => {
							!data && setNewAPIKey(res?.data?.data?.full_key);
						}}
					/>
				</Card>
			)}
		</Animate>
	);
}
