import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Skeleton } from "components/ui/skeleton";

export default function CardSkeleton() {
	return (
		<Card className="w-full bg-white">
			<CardHeader>
				<CardTitle>
					<Skeleton className="h-8 w-48" />
				</CardTitle>
			</CardHeader>
			<CardContent className="space-y-4">
				{/* Table Header */}
				<div className="flex justify-between items-center">
					<Skeleton className="h-4 w-16" />
					<Skeleton className="h-4 w-1/3" />
					<Skeleton className="h-4 w-12" />
					<Skeleton className="h-4 w-16" />
					<Skeleton className="h-4 w-12" />
					<Skeleton className="h-4 w-12" />
					<Skeleton className="h-4 w-12" />
				</div>

				{/* Table Rows */}
				{[1, 2, 3, 4, 5].map((row) => (
					<div key={row} className="flex justify-between items-center">
						<Skeleton className="h-4 w-16" />
						<Skeleton className="h-4 w-1/3" />
						<Skeleton className="h-4 w-12" />
						<Skeleton className="h-4 w-16" />
						<Skeleton className="h-4 w-12" />
						<Skeleton className="h-4 w-12" />
						<Skeleton className="h-4 w-12" />
					</div>
				))}
			</CardContent>
		</Card>
	);
}
