import {
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SortableHeaderButton = ({ column, label }) => {
	return (
		<Button
			className="px-0"
			variant="ghost"
			onClick={() => column.toggleSorting()}
		>
			{label}
			{column.getIsSorted() === "desc" ? (
				<ChevronDown className="ml-2 w-4 h-4" />
			) : (
				column.getIsSorted() === "asc" && <ChevronUp className="ml-2 w-4 h-4" />
			)}
		</Button>
	);
};

const ChangeCell = ({ value }) => {
	const colorClass =
		value > 0 ? "text-green-500" : value < 0 ? "text-red-500" : "text-black";
	return (
		<span className={`font-bold text-base ${colorClass}`}>
			{value > 0 ? "+" : ""}
			{Math.round(value)}%
		</span>
	);
};

const TotalRow = ({ metrics }) => {
	if (!metrics) return null;

	const totals = {
		blogs: metrics.reduce(
			(sum, metric) => sum + metric.wordpress.blogs.current,
			0,
		),
		previousBlogs: metrics.reduce(
			(sum, metric) => sum + (metric.wordpress.blogs.previous || 0),
			0,
		),
		impressions: metrics.reduce(
			(sum, metric) => sum + metric.wordpress.impressions.current,
			0,
		),
		previousImpressions: metrics.reduce(
			(sum, metric) => sum + (metric.wordpress.impressions.previous || 0),
			0,
		),
		clicks: metrics.reduce(
			(sum, metric) => sum + metric.wordpress.clicks.current,
			0,
		),
		previousClicks: metrics.reduce(
			(sum, metric) => sum + (metric.wordpress.clicks.previous || 0),
			0,
		),
	};

	totals.blogsChange = totals.previousBlogs
		? ((totals.blogs - totals.previousBlogs) / totals.previousBlogs) * 100
		: 0;
	totals.impressionsChange = totals.previousImpressions
		? ((totals.impressions - totals.previousImpressions) /
				totals.previousImpressions) *
			100
		: 0;
	totals.clicksChange = totals.previousClicks
		? ((totals.clicks - totals.previousClicks) / totals.previousClicks) * 100
		: 0;

	const currentCtr = totals.impressions
		? (totals.clicks / totals.impressions) * 100
		: 0;
	const previousCtr = totals.previousImpressions
		? (totals.previousClicks / totals.previousImpressions) * 100
		: 0;
	const ctrChange = previousCtr
		? ((currentCtr - previousCtr) / previousCtr) * 100
		: 0;

	// Calculate average position
	const currentPositions = metrics
		.map((m) => m.wordpress.position.current)
		.filter(Boolean);
	const previousPositions = metrics
		.map((m) => m.wordpress.position.previous)
		.filter(Boolean);

	const avgPosition = currentPositions.length
		? currentPositions.reduce((a, b) => a + b, 0) / currentPositions.length
		: 0;
	const avgPreviousPosition = previousPositions.length
		? previousPositions.reduce((a, b) => a + b, 0) / previousPositions.length
		: 0;
	const positionChange = avgPreviousPosition
		? ((avgPreviousPosition - avgPosition) / avgPreviousPosition) * 100
		: 0;

	return (
		<TableRow className="bg-gray-50 font-medium">
			<TableCell className="font-bold text-base">Total</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.blogs}
			</TableCell>
			<TableCell className="text-center border-l">
				<ChangeCell value={totals.blogsChange} />
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.impressions}
			</TableCell>
			<TableCell className="text-center border-l">
				<ChangeCell value={totals.impressionsChange} />
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{totals.clicks}
			</TableCell>
			<TableCell className="text-center border-l">
				<ChangeCell value={totals.clicksChange} />
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{currentCtr.toFixed(2)}%
			</TableCell>
			<TableCell className="text-center border-l">
				<ChangeCell value={ctrChange} />
			</TableCell>
			<TableCell className="text-center border-l text-base">
				{avgPosition.toFixed(1)}
			</TableCell>
			<TableCell className="text-center border-l">
				<ChangeCell value={positionChange} />
			</TableCell>
		</TableRow>
	);
};

const getColumns = (t) => [
	{
		id: "user_name",
		accessorKey: "user_name",
		title: t("dashboard.collaborator_metrics.user_name"),
		header: ({ column }) => (
			<SortableHeaderButton
				column={column}
				label={t("dashboard.collaborator_metrics.user_name")}
			/>
		),
		cell: (info) => <span className="text-base">{info.getValue()}</span>,
	},
	{
		header: t("dashboard.collaborator_metrics.blogs"),
		columns: [
			{
				id: "wordpress.blogs.current",
				accessorKey: "wordpress.blogs.current",
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => <span className="text-base">{info.getValue()}</span>,
			},
			{
				id: "wordpress.blogs.change",
				accessorKey: "wordpress.blogs.change",
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => <ChangeCell value={info.getValue()} />,
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.impressions"),
		columns: [
			{
				id: "wordpress.impressions.current",
				accessorKey: "wordpress.impressions.current",
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => <span className="text-base">{info.getValue()}</span>,
			},
			{
				id: "wordpress.impressions.change",
				accessorKey: "wordpress.impressions.change",
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => <ChangeCell value={info.getValue()} />,
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.clicks"),
		columns: [
			{
				id: "wordpress.clicks.current",
				accessorKey: "wordpress.clicks.current",
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => <span className="text-base">{info.getValue()}</span>,
			},
			{
				id: "wordpress.clicks.change",
				accessorKey: "wordpress.clicks.change",
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => <ChangeCell value={info.getValue()} />,
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.ctr"),
		columns: [
			{
				id: "wordpress.ctr.current",
				accessorKey: "wordpress.ctr.current",
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => (
					<span className="text-base">
						{(info.getValue() * 100).toFixed(2)}%
					</span>
				),
			},
			{
				id: "wordpress.ctr.change",
				accessorKey: "wordpress.ctr.change",
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => <ChangeCell value={info.getValue()} />,
			},
		],
	},
	{
		header: t("dashboard.collaborator_metrics.position"),
		columns: [
			{
				id: "wordpress.position.current",
				accessorKey: "wordpress.position.current",
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.actual")}
					/>
				),
				cell: (info) => (
					<span className="text-base">{info.getValue().toFixed(1)}</span>
				),
			},
			{
				id: "wordpress.position.change",
				accessorKey: "wordpress.position.change",
				header: ({ column }) => (
					<SortableHeaderButton
						column={column}
						label={t("dashboard.collaborator_metrics.change")}
					/>
				),
				cell: (info) => <ChangeCell value={info.getValue()} />,
			},
		],
	},
];

const WordPressMetricsTable = ({ metrics, t }) => {
	const navigate = useNavigate();
	const [sorting, setSorting] = useState([
		{ id: "wordpress.blogs.current", desc: true },
	]);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});

	const table = useReactTable({
		data: metrics,
		columns: getColumns(t),
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onColumnVisibilityChange: setColumnVisibility,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
		},
	});

	const handleRowClick = (userId) => {
		navigate(`/stats/${userId}`);
	};

	return (
		<Table>
			<TableHeader>
				{table.getHeaderGroups().map((headerGroup) => (
					<TableRow key={headerGroup.id}>
						{headerGroup.headers.map((header, headerIndex) => {
							const isFirstHeader = headerIndex === 0;
							const isLastHeader =
								headerIndex === headerGroup.headers.length - 1;
							return (
								<TableHead
									key={header.id}
									colSpan={header.colSpan}
									className={`text-center ${!isFirstHeader ? "border-l" : ""} ${!isLastHeader ? "border-r" : ""}`}
								>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
								</TableHead>
							);
						})}
					</TableRow>
				))}
			</TableHeader>
			<TableBody>
				<TotalRow metrics={metrics} />
				{table.getRowModel().rows.map((row, rowIndex) => (
					<TableRow
						key={row.id}
						className={`hover:cursor-pointer hover:bg-muted-foreground/10 ${rowIndex % 2 === 0 ? "bg-gray-100" : "bg-white"}`}
						onClick={() => handleRowClick(row.original.user_id)}
					>
						{row.getVisibleCells().map((cell, cellIndex) => {
							const isFirstCell = cellIndex === 0;
							const isLastCell = cellIndex === row.getVisibleCells().length - 1;
							return (
								<TableCell
									key={cell.id}
									className={`${cell.column.id !== "user_name" ? "text-center" : ""} ${!isFirstCell ? "border-l" : ""} ${!isLastCell ? "border-r" : ""}`}
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</TableCell>
							);
						})}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

export default WordPressMetricsTable;
