import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import {
	Form,
	FormControl,
	FormDescription,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "components/ui/form";
import { Input } from "components/ui/input";
import { Textarea } from "components/ui/textarea";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "components/ui/tooltip";
import { HelpCircle, LoaderCircle, PlusCircle, Trash2 } from "lucide-react";
import { useCallback } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

const formSchema = z.object({
	phoneNumber: z
		.string()
		.regex(/^\+[1-9]\d{10,14}$/, "Número de teléfono inválido"),
	metadata: z.string().optional(),
	linkedinPosts: z.array(z.object({ content: z.string() })).optional(),
	outboundMessage: z.string().optional(),
});

type FormValues = z.infer<typeof formSchema>;

const DEFAULT_OUTBOUND_MESSAGE =
	"¡Hola! Bienvenido al chatbot demo de Inbound Tools 📓 \n\nMi objetivo es ayudarte a crear contenido para LinkedIn\n\nEste es un demo, por lo que algunas funcionalidades pueden estar limitadas.\n\nInicia enviando un mensaje 💬";

const CreateChatbotDemo = () => {
	const { toast } = useToast();

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			phoneNumber: "",
			metadata: "",
			linkedinPosts: [],
			outboundMessage: DEFAULT_OUTBOUND_MESSAGE,
		},
	});

	const { fields, append, remove } = useFieldArray({
		control: form.control,
		name: "linkedinPosts",
	});

	const createDemoMutation = useMutation({
		mutationFn: (data: FormValues) =>
			axios.post("/api/chatbot-user/create-demo", data),
		onSuccess: () => {
			toast({
				title: "Éxito",
				description: "Demo de chatbot creado correctamente",
			});
		},
		onError: () => {
			toast({
				title: "Error",
				description: "No se pudo crear el demo de chatbot",
				variant: "destructive",
			});
		},
	});

	const onSubmit = useCallback(
		(data: FormValues) => {
			createDemoMutation.mutate(data);
		},
		[createDemoMutation],
	);

	return (
		<Card className="max-w-2xl mx-auto">
			<CardHeader>
				<CardTitle>Crear Demo de Chatbot</CardTitle>
			</CardHeader>
			<CardContent>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
						<FormField
							control={form.control}
							name="phoneNumber"
							render={({ field }) => (
								<FormItem>
									<FormLabel className="flex items-center">
										Número de WhatsApp
										<TooltipProvider>
											<Tooltip>
												<TooltipTrigger asChild>
													<HelpCircle className="w-4 h-4 ml-2 cursor-pointer" />
												</TooltipTrigger>
												<TooltipContent className="bg-white text-black border">
													<p>
														Ingresa el número de teléfono en formato
														internacional completo.
													</p>
													<p>Ejemplo: +14081234567</p>
													<a
														href="https://faq.whatsapp.com/1294841057948784"
														target="_blank"
														rel="noopener noreferrer"
														className="text-blue-500 hover:underline"
													>
														Más información sobre el formato internacional
													</a>
													<br />
													<a
														href="https://b_fQwtbAfrP1o.v0.build/"
														target="_blank"
														rel="noopener noreferrer"
														className="text-blue-500 hover:underline"
													>
														Convertir número de teléfono a formato internacional
													</a>
												</TooltipContent>
											</Tooltip>
										</TooltipProvider>
									</FormLabel>
									<FormControl>
										<Input placeholder="+14081234567" {...field} />
									</FormControl>
									<FormDescription>
										Ingresa el número de WhatsApp en formato internacional
										completo (ej., +14081234567). Sin espacios ni caracteres
										especiales.
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="metadata"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Metadatos</FormLabel>
									<FormControl>
										<Textarea
											placeholder="Ingresa metadatos adicionales..."
											{...field}
										/>
									</FormControl>
									<FormDescription>
										Proporciona cualquier metadato adicional para el chatbot
										demo. ej) El contenido creado debe de ser ser profesional,
										serio, etc.
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<FormField
							control={form.control}
							name="outboundMessage"
							render={({ field }) => (
								<FormItem>
									<FormLabel>Mensaje de Bienvenida (Opcional)</FormLabel>
									<FormControl>
										<Textarea {...field} rows={6} />
									</FormControl>
									<FormDescription>
										Este es el mensaje de bienvenida predeterminado para el
										usuario del chatbot demo. Puedes modificarlo si lo deseas.
									</FormDescription>
									<FormMessage />
								</FormItem>
							)}
						/>

						<div>
							<FormLabel>Publicaciones de LinkedIn de inspiración</FormLabel>
							<Button
								type="button"
								variant="outline"
								onClick={() => append({ content: "" })}
								className="w-full mt-2 mb-4"
							>
								<PlusCircle className="mr-2 h-4 w-4" />
								Agregar Publicación de LinkedIn
							</Button>
							{fields.map((field, index) => (
								<FormField
									key={field.id}
									control={form.control}
									name={`linkedinPosts.${index}.content`}
									render={({ field }) => (
										<FormItem className="mb-4">
											<FormControl>
												<div className="relative">
													<Textarea
														{...field}
														placeholder="Ingresa la publicación de LinkedIn"
														className="pr-10 min-h-[100px]"
													/>
													<Button
														type="button"
														variant="ghost"
														size="icon"
														className="absolute top-2 right-2"
														onClick={() => remove(index)}
													>
														<Trash2 className="h-4 w-4" />
													</Button>
												</div>
											</FormControl>
										</FormItem>
									)}
								/>
							))}
							<FormDescription>
								Agrega publicaciones de LinkedIn de inspiración para el chatbot
								demo. Ya sean del usuario o de otros.
							</FormDescription>
						</div>

						<Button type="submit" disabled={createDemoMutation.isPending}>
							{createDemoMutation.isPending ? (
								<>
									<LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
									Creando Demo...
								</>
							) : (
								"Crear Demo de Chatbot"
							)}
						</Button>
					</form>
				</Form>
			</CardContent>
		</Card>
	);
};

export default CreateChatbotDemo;
