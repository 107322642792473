import { Dashboard } from "views/dashboard/dashboard";
import { Help } from "views/dashboard/help";
import { GrowthFunnel } from "views/growth-funnel/growth-funnel";
import { OnboardingView } from "views/onboarding/onboarding";
import { Onboarding } from "../views/auth/onboarding/Onboarding";

const Routes = [
	{
		path: "/dashboard",
		view: Dashboard,
		layout: "app",
		permission: "user",
		title: "dashboard.title",
	},
	{
		path: "/growth-funnel",
		view: GrowthFunnel,
		layout: "app",
		title: "growth-funnel.title",
	},
	{
		path: "/welcome",
		view: OnboardingView,
		layout: "onboarding",
		permission: "user",
		title: "onboarding.title",
	},
	{
		path: "/help",
		view: Help,
		layout: "app",
		permission: "user",
		title: "help.title",
	},
	{
		path: "/onboarding",
		view: Onboarding,
		layout: "onboarding",
		permission: "user",
		title: "onboarding.title",
	},
];

export default Routes;
