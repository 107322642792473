import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";

import { AlertDialogTrigger } from "@radix-ui/react-alert-dialog";
import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "components/ui/alert-dialog";
import { ScrollArea } from "components/ui/scroll-area";
import { Separator } from "components/ui/separator";
import { Loader2, Plus, X } from "lucide-react"; // Import icons
import React, { useState } from "react";
import type { Team } from "../teams";

interface ManageTeamDialogProps {
	team: Team;
	organizationUsers: { id: string; name: string; email: string }[];
	fetchTeamUsers: () => Promise<void>;
	teamUsers: { id: string; name: string; email: string }[];
}

export const ManageTeamDialog = ({
	team,
	organizationUsers,
	fetchTeamUsers,
	teamUsers,
}: ManageTeamDialogProps) => {
	const [openDialogs, setOpenDialogs] = useState<Record<string, boolean>>({});
	const [showAddUsers, setShowAddUsers] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { toast } = useToast();

	const handleRemoveUser = async (userId: string) => {
		setIsLoading(true);
		await new Promise((resolve) => setTimeout(resolve, 2000));
		try {
			await axios.post("/api/team/remove-user", { userId, teamId: team.id });
			await fetchTeamUsers();
			toast({
				title: "User removed",
				description: "The user has been removed from the team.",
			});
		} catch (error) {
			console.error("Error removing user from team:", error);
			toast({
				title: "Error",
				description: "Failed to remove user from the team.",
				variant: "destructive",
			});
		} finally {
			setIsLoading(false);
			setOpenDialogs((prev) => ({ ...prev, [userId]: false }));
		}
	};

	const handleAddUser = async (userId: string) => {
		setIsLoading(true);
		await new Promise((resolve) => setTimeout(resolve, 2000));
		try {
			await axios.post("/api/team/add-user", { userId, teamId: team.id });
			await fetchTeamUsers();
			toast({
				title: "User added",
				description: "The user has been added to the team.",
			});
		} catch (error) {
			console.error("Error adding user to team:", error);
			toast({
				title: "Error",
				description: "Failed to add user to the team.",
				variant: "destructive",
			});
		} finally {
			setIsLoading(false);
			setOpenDialogs((prev) => ({ ...prev, [userId]: false }));
		}
	};

	return (
		<DialogContent className="max-h-[50vh] flex flex-col justify-center">
			<DialogHeader>
				<DialogTitle>Manage {team.name} users</DialogTitle>
				<DialogDescription className="text-sm text-gray-500">
					{showAddUsers
						? "Add users to the team."
						: "Remove users from the team."}
				</DialogDescription>
			</DialogHeader>
			<ScrollArea className="h-[45vh]">
				<div className="">
					{!showAddUsers ? (
						<>
							{teamUsers.map((user) => (
								<React.Fragment key={user.id}>
									<div className="flex justify-between items-center my-2">
										<div className="flex flex-col">
											<h4 className="font-medium">{user.name}</h4>
											<span className="text-sm text-gray-500">
												{user.email}
											</span>
										</div>

										<AlertDialog
											open={openDialogs[user.id]}
											onOpenChange={(open) =>
												setOpenDialogs((prev) => ({ ...prev, [user.id]: open }))
											}
										>
											<AlertDialogTrigger asChild>
												<Button variant="outline" size="icon">
													<X className="h-4 w-4" />
												</Button>
											</AlertDialogTrigger>
											<AlertDialogContent>
												<AlertDialogHeader>
													<AlertDialogTitle>Are you sure?</AlertDialogTitle>
													<AlertDialogDescription>
														This will remove the user from the team. You can add
														them back later if needed.
													</AlertDialogDescription>
												</AlertDialogHeader>
												<AlertDialogFooter>
													<AlertDialogCancel disabled={isLoading}>
														Cancel
													</AlertDialogCancel>
													<AlertDialogAction
														onClick={(e) => {
															e.preventDefault();
															handleRemoveUser(user.id);
														}}
														disabled={isLoading}
													>
														{isLoading ? (
															<>
																<Loader2 className="mr-2 h-4 w-4 animate-spin" />
																Removing...
															</>
														) : (
															"Remove"
														)}
													</AlertDialogAction>
												</AlertDialogFooter>
											</AlertDialogContent>
										</AlertDialog>
									</div>
									<Separator className="my-2" />
								</React.Fragment>
							))}
						</>
					) : (
						<>
							{organizationUsers
								.filter(
									(user) =>
										!teamUsers.some((teamUser) => teamUser.id === user.id),
								)
								.map((user) => (
									<React.Fragment key={user.id}>
										<div className="flex justify-between items-center">
											<div className="flex flex-col">
												<h4 className="font-medium">{user.name}</h4>
												<span className="text-sm text-gray-500">
													{user.email}
												</span>
											</div>
											<AlertDialog
												open={openDialogs[user.id]}
												onOpenChange={(open) =>
													setOpenDialogs((prev) => ({
														...prev,
														[user.id]: open,
													}))
												}
											>
												<AlertDialogTrigger asChild>
													<Button variant="outline" size="icon">
														<Plus className="h-4 w-4" />
													</Button>
												</AlertDialogTrigger>
												<AlertDialogContent>
													<AlertDialogHeader>
														<AlertDialogTitle>
															Add user to team?
														</AlertDialogTitle>
														<AlertDialogDescription>
															This will add the user to the team. They will have
															access to team resources.
														</AlertDialogDescription>
													</AlertDialogHeader>
													<AlertDialogFooter>
														<AlertDialogCancel disabled={isLoading}>
															Cancel
														</AlertDialogCancel>
														<AlertDialogAction
															onClick={(e) => {
																e.preventDefault();
																handleAddUser(user.id);
															}}
															disabled={isLoading}
														>
															{isLoading ? (
																<>
																	<Loader2 className="mr-2 h-4 w-4 animate-spin" />
																	Adding...
																</>
															) : (
																"Add"
															)}
														</AlertDialogAction>
													</AlertDialogFooter>
												</AlertDialogContent>
											</AlertDialog>
										</div>
										<Separator className="my-2" />
									</React.Fragment>
								))}
						</>
					)}
				</div>
			</ScrollArea>
			<div className="mt-4">
				{showAddUsers ? (
					<Button onClick={() => setShowAddUsers(false)}>
						Back to Team Users
					</Button>
				) : (
					<Button onClick={() => setShowAddUsers(true)}>Add Users</Button>
				)}
			</div>
		</DialogContent>
	);
};
