import {
	type Column,
	type ColumnDef,
	type SortingState,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { useState } from "react";
import type { Post } from "../types";
import { getLinkedInPostLink, isLinkedInPost } from "../utils";
import { SortableHeaderButton } from "./sortable-header-button";

interface PostsTableProps {
	posts: Post[] | undefined;
	platform: "linkedin" | "wordpress";
	t: (key: string, options?: Record<string, string>) => string;
}

export function PostsTable({ posts = [], platform, t }: PostsTableProps) {
	const [sorting, setSorting] = useState<SortingState>([]);

	const columns: ColumnDef<Post>[] =
		platform === "linkedin"
			? [
					{
						accessorKey: "user_name",
						header: ({ column }) => (
							<SortableHeaderButton<Post>
								column={column}
								title={t("dashboard.collaborator_metrics.user_name")}
							/>
						),
					},
					{
						accessorKey: "content",
						header: ({ column }) => (
							<SortableHeaderButton<Post> column={column} title="Content" />
						),
						cell: ({ row }) => (
							<div className="max-w-[400px] truncate">
								{row.getValue("content")}
							</div>
						),
					},
					{
						accessorKey: "creation_time",
						header: ({ column }) => (
							<SortableHeaderButton<Post> column={column} title="Date" />
						),
						cell: ({ row }) => {
							const date = new Date(row.getValue("creation_time"));
							return date.toLocaleDateString();
						},
					},
					{
						accessorKey: "impressions",
						header: ({ column }) => (
							<SortableHeaderButton<Post>
								column={column}
								title={t("dashboard.collaborator_metrics.impressions")}
							/>
						),
					},
					{
						accessorKey: "likes",
						header: ({ column }) => (
							<SortableHeaderButton<Post>
								column={column}
								title={t("dashboard.collaborator_metrics.likes")}
							/>
						),
					},
					{
						accessorKey: "comments",
						header: ({ column }) => (
							<SortableHeaderButton<Post>
								column={column}
								title={t("dashboard.collaborator_metrics.comments")}
							/>
						),
					},
					{
						accessorKey: "shares",
						header: ({ column }) => (
							<SortableHeaderButton<Post>
								column={column}
								title={t("dashboard.collaborator_metrics.shares")}
							/>
						),
					},
					{
						accessorKey: "performance_score",
						header: ({ column }) => (
							<SortableHeaderButton<Post> column={column} title="Performance" />
						),
					},
				]
			: [
					{
						accessorKey: "user_name",
						header: ({ column }) => (
							<SortableHeaderButton<Post>
								column={column}
								title={t("dashboard.collaborator_metrics.user_name")}
							/>
						),
					},
					{
						accessorKey: "title",
						header: ({ column }) => (
							<SortableHeaderButton<Post> column={column} title="Title" />
						),
						cell: ({ row }) => (
							<div className="max-w-[400px] truncate">
								{row.getValue("title")}
							</div>
						),
					},
					{
						accessorKey: "created_at",
						header: ({ column }) => (
							<SortableHeaderButton<Post> column={column} title="Date" />
						),
						cell: ({ row }) => {
							const date = new Date(row.getValue("created_at"));
							return date.toLocaleDateString();
						},
					},
					{
						accessorKey: "total_impressions",
						header: ({ column }) => (
							<SortableHeaderButton<Post>
								column={column}
								title={t("dashboard.collaborator_metrics.impressions")}
							/>
						),
					},
					{
						accessorKey: "total_clicks",
						header: ({ column }) => (
							<SortableHeaderButton<Post>
								column={column}
								title={t("dashboard.collaborator_metrics.clicks")}
							/>
						),
					},
					{
						accessorKey: "ctr",
						header: ({ column }) => (
							<SortableHeaderButton<Post>
								column={column}
								title={t("dashboard.collaborator_metrics.ctr")}
							/>
						),
						cell: ({ row }) => {
							const ctr = row.getValue("ctr");
							return `${Number(ctr).toFixed(2)}%`;
						},
					},
					{
						accessorKey: "avg_position",
						header: ({ column }) => (
							<SortableHeaderButton<Post>
								column={column}
								title={t("dashboard.collaborator_metrics.position")}
							/>
						),
					},
					{
						accessorKey: "performance_score",
						header: ({ column }) => (
							<SortableHeaderButton<Post> column={column} title="Performance" />
						),
					},
				];

	const table = useReactTable({
		data: posts,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		state: {
			sorting,
		},
	});

	return (
		<div>
			<div className="rounded-md border">
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<TableHead key={header.id}>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</TableHead>
								))}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => {
								const postUrl = isLinkedInPost(row.original)
									? getLinkedInPostLink(row.original.linkedin_id)
									: row.original.link;

								return (
									<TableRow
										data-state={row.getIsSelected() && "selected"}
										className="hover:bg-gray-50 transition-colors cursor-pointer"
										key={row.id}
										onClick={() =>
											window.open(postUrl, "_blank", "noopener,noreferrer")
										}
									>
										{row.getVisibleCells().map((cell) => (
											<TableCell key={cell.id}>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext(),
												)}
											</TableCell>
										))}
									</TableRow>
								);
							})
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 text-center"
								>
									No results.
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			<div className="flex items-center justify-end space-x-2 py-4">
				<Button
					variant="outline"
					size="sm"
					onClick={() => table.previousPage()}
					disabled={!table.getCanPreviousPage()}
				>
					{t("dashboard.collaborator_metrics.previous")}
				</Button>
				<Button
					variant="outline"
					size="sm"
					onClick={() => table.nextPage()}
					disabled={!table.getCanNextPage()}
				>
					{t("dashboard.collaborator_metrics.next")}
				</Button>
			</div>
		</div>
	);
}
