import { AuthContext } from "components/lib";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import MultiStepForm, {
	type MultiFormStep,
} from "components/ui/multi-step-form";
import { Textarea } from "components/ui/textarea";
import { useCallback, useContext } from "react";
import { z } from "zod";

import { appConfig } from "../config";

const Step1Schema = z.object({
	content: z
		.string()
		.min(200, "Content must be at least 200 characters")
		.max(4800, "Content cannot exceed 4800 characters"),
	content2: z
		.string()
		.min(200, "Content must be at least 200 characters")
		.max(4800, "Content cannot exceed 4800 characters"),
	contentType: z.string().min(1, "Content type is required"),
});

const Step2Schema = z.object({
	name: z.string().min(1, "Name is required"),
	language: z.string().min(1, "Language is required"),
	shareWithOrganization: z.boolean().default(false),
});

const Step3Schema = z.object({});

const steps: MultiFormStep<
	typeof Step1Schema | typeof Step2Schema | typeof Step3Schema,
	any[]
>[] = [
	{
		id: "Step 1",
		name: "Content Information",
		schema: Step1Schema,
		component: ({ register, errors }) => (
			<>
				<h2 className="text-base font-semibold leading-7 text-gray-900">
					Content Information
				</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600">
					Provide content created by this voice (between 200 and 4800
					characters).
				</p>
				<div className="grid grid-cols-1 gap-x-6 gap-y-8 mt-10 sm:grid-cols-6">
					<div className="col-span-full">
						<Label
							htmlFor="content"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Content
						</Label>
						<div className="mt-2">
							<Textarea
								id="content"
								{...register("content")}
								rows={8}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
							/>
							{"content" in errors && (
								<p className="mt-2 text-sm text-red-400">
									{errors.content?.message}
								</p>
							)}
						</div>
					</div>
					<div className="col-span-full">
						<Label
							htmlFor="content2"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Second Piece of content
						</Label>
						<div className="mt-2">
							<Textarea
								id="content2"
								{...register("content2")}
								rows={8}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
							/>
							{"content2" in errors && (
								<p className="mt-2 text-sm text-red-400">
									{errors.content2?.message}
								</p>
							)}
						</div>
					</div>
					<div className="sm:col-span-4">
						<Label
							htmlFor="contentType"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Content Type
						</Label>
						<div className="mt-2">
							<Input
								type="text"
								id="contentType"
								{...register("contentType")}
								placeholder="e.g., LinkedIn post, blog, etc."
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
							/>
							{"contentType" in errors && (
								<p className="mt-2 text-sm text-red-400">
									{errors.contentType?.message}
								</p>
							)}
						</div>
					</div>
				</div>
			</>
		),
	},
	{
		id: "Step 2",
		name: "Voice Name",
		schema: Step2Schema,
		component: ({ register, errors }) => (
			<>
				<h2 className="text-base font-semibold leading-7 text-gray-900">
					Voice Name
				</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600">
					Provide a name for your custom voice.
				</p>
				<div className="grid grid-cols-1 gap-x-6 gap-y-8 mt-10 sm:grid-cols-6">
					<div className="sm:col-span-4">
						<Label
							htmlFor="name"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Name
						</Label>
						<div className="mt-2">
							<Input
								type="text"
								id="name"
								{...register("name")}
								autoComplete="name"
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
							/>
							{"name" in errors && (
								<p className="mt-2 text-sm text-red-400">
									{errors.name?.message}
								</p>
							)}
						</div>
					</div>
					<div className="sm:col-span-4">
						<Label
							htmlFor="language"
							className="block text-sm font-medium leading-6 text-gray-9000"
						>
							Language
						</Label>
						<div className="mt-2">
							<Input
								type="text"
								id="language"
								{...register("language")}
								className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
							/>
							{"language" in errors && (
								<p className="mt-2 text-sm text-red-400">
									{errors.language?.message}
								</p>
							)}
						</div>
					</div>
					<div className="sm:col-span-4">
						<Label
							htmlFor="shareWithOrganization"
							className="block text-sm font-medium leading-6 text-gray-900"
						>
							Share with Organization
						</Label>
						<div className="mt-2 w-8">
							<Input
								className="mx-auto"
								type="checkbox"
								id="shareWithOrganization"
								{...register("shareWithOrganization")}
							/>
							{"shareWithOrganization" in errors && (
								<p className="mt-2 text-sm text-red-4000">
									{errors.shareWithOrganization?.message}
								</p>
							)}
						</div>
					</div>
				</div>
			</>
		),
	},
	{
		id: "Step 3",
		name: "Complete",
		schema: Step3Schema,
		component: () => (
			<>
				<h2 className="text-base font-semibold leading-7 text-gray-900">
					Voice Creation in Progress
				</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600">
					Thank you for your submission. Your custom voice is being created.
					This process usually takes around a minute or two depending on the
					demand of our servers.
				</p>
			</>
		),
	},
];

type Props = {
	t: (key: string) => string;
};

export const NewVoicePage: React.FC<Props> = ({ t }) => {
	const auth: any = useContext(AuthContext);

	const handleSubmit = useCallback(
		async (
			data: z.infer<
				typeof Step1Schema | typeof Step2Schema | typeof Step3Schema
			>[],
		) => {
			const formData = data.reduce((acc, curr) => ({ ...acc, ...curr }), {});
			await fetch(`${appConfig.API_MAIN_URL}/api/custom-voice/create`, {
				method: "POST",
				body: JSON.stringify({
					...formData,
					userId: auth?.user?.id,
					organizationId: auth?.user?.organization_id,
				}),
				headers: {
					"Content-Type": "application/json",
				},
			});
		},
		[auth],
	);

	return (
		<div className="mx-auto max-w-5xl">
			<h1 className="mb-5 text-2xl font-bold">Create a new voice</h1>
			<MultiStepForm
				name="create-voice"
				t={t}
				steps={steps}
				onSubmit={handleSubmit}
			/>
		</div>
	);
};

export default NewVoicePage;
