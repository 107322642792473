/***
 *
 *   SIGN UP STEP 1
 *   Signup form for organization owners
 *   Step 1: create organization
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import { Animate, AuthContext, Card, Form, Link, Row } from "components/lib";
import { useContext } from "react";
import ExtensionMessage from "views/dashboard/_components/extension-message";
import { useLinkedinProfileId } from "views/dashboard/_hooks/use-linkedin-profile-id";

import {
	Alert,
	AlertDescription,
	AlertTitle,
} from "../../../components/ui/alert";

export function Signup(props) {
	const context = useContext(AuthContext);

	const { profileId, loading: loadingProfile } = useLinkedinProfileId();

	return (
		<Animate type="pop">
			<Row title={props.t("auth.signup.organization.title")}>
				<ExtensionMessage loading={loadingProfile} />
				<Card loading={false} restrictWidth center transparent>
					{!loadingProfile && (
						<Form
							inputs={{
								name: {
									label: props.t("auth.signup.organization.form.name.label"),
									type: "text",
									required: true,
									errorMessage: props.t(
										"auth.signup.organization.form.name.error",
									),
								},
								email: {
									label: props.t("auth.signup.organization.form.email.label"),
									type: "email",
									required: true,
								},
								password: {
									label: props.t(
										"auth.signup.organization.form.password.label",
									),
									type: "password",
									required: true,
									complexPassword: true,
								},
								confirm_password: {
									type: "hidden",
									value: null,
								},
								profile_id: {
									type: "hidden",
									value: profileId,
								},
							}}
							url="/api/organization"
							method="POST"
							buttonDisabled={!loadingProfile && !profileId}
							buttonText={props.t("auth.signup.organization.form.button")}
							callback={context.signin}
						/>
					)}

					<Alert className="mt-3">
						<AlertTitle>
							<b>Be careful!</b>
						</AlertTitle>
						<AlertDescription>
							When creating your user, we sync all your linkedin data, So it may
							take a minute to load. Don&apos;t close this window.
						</AlertDescription>
					</Alert>

					<footer className="mt-4">
						{props.t("auth.signup.organization.footer.text")}
						<Link
							url="/signin"
							text={props.t("auth.signup.organization.footer.link_text")}
						/>
					</footer>
				</Card>
			</Row>
		</Animate>
	);
}
