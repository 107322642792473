import { useQuery } from "@tanstack/react-query";
import type { PostReactionsResponse } from "../types";
import { appConfig } from "views/content-creation/config";
import { useContext } from "react";
import { AuthContext } from "components/lib";
import type { AuthContextType } from "types/authContext";
import axios from "axios";

const fetchPostReactions = async ({
  postId,
  userId,
  page = 1,
  limit = 10,
  token,
}: {
  postId: string;
  userId: string;
  page?: number;
  limit?: number;
  token: string;
}): Promise<PostReactionsResponse> => {
  const { data } = await axios.get(
    `${appConfig.API_MAIN_URL}/api/post/${postId}/reactions`,
    {
      params: {
        userId,
        page,
        limit,
      },
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const usePostReactions = (
  postId: string | null,
  page = 1,
  limit = 10
) => {
  const auth = useContext<AuthContextType>(AuthContext);
  
  return useQuery({
    queryKey: ['postReactions', postId, page, limit],
    queryFn: async () => {
      if (!postId || !auth.user?.token || !auth.user?.id) return null;
      return fetchPostReactions({
        postId,
        userId: auth.user.id,
        page,
        limit,
        token: auth.user.token
      });
    },
    enabled: !!postId && !!auth.user?.token && !!auth.user?.id,
    staleTime: 0,
  });
}; 