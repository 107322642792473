import { useQuery } from "@tanstack/react-query";
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios from "axios";
import { AuthContext } from "components/lib";
import { Button } from "components/ui/button";
import { Plus } from "lucide-react";
import { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Separator } from "components/ui/separator";
import { appConfig } from "views/content-creation/config";
import { GoalCard } from "./_components/goal-card";
import { SkeletonGoalCard } from "./_components/skeleton-goal-card";
import type { AuthOrganization, GoalWithMetrics } from "./types";

export const OrganizationGoals = () => {
	// biome-ignore lint/suspicious/noExplicitAny: <auth hook>
	const auth: any = useContext(AuthContext);
	const organization = auth.user.organizations.find(
		(org: AuthOrganization) => org.id === auth.user.organization_id,
	);
	const userId = auth.user.id;
	const { data: goals, isLoading: goalsLoading } = useQuery<GoalWithMetrics[]>({
		queryKey: ["goals", organization.id, userId],
		queryFn: async () => {
			const response = await axios.get(
				"/api/goal/get-all-user-goals-and-metrics",
				{
					params: {
						organization_id: organization.id,
						user_id: userId,
					},
				},
			);
			if (response.status !== 200) {
				throw new Error("Failed to fetch goals");
			}
			return response.data.data;
		},
	});

	const currentDate = new Date();
	const currentGoals =
		goals?.filter((goal) => new Date(goal.end_date) >= currentDate) || [];
	const finishedGoals =
		goals?.filter((goal) => new Date(goal.end_date) < currentDate) || [];

	return (
		<main className="w-full py-12 md:py-24 lg:py-32">
			<div className="container px-4 md:px-6">
				<div className="space-y-2 text-center">
					<h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
						{organization.name} Goals
					</h1>
					<p className="max-w-[700px] mx-auto text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
						Track and manage your organization goals with ease.
					</p>
				</div>
				<div className="flex justify-end mt-8">
					<NavLink
						to="/organization-goals/create-goal"
						className="flex items-center space-x-2 text-primary"
					>
						<Button>
							<Plus className="mr-2 h-4 w-4" />
							Create New Goal
						</Button>
					</NavLink>
				</div>
				<div className="mt-8">
					{goalsLoading ? (
						<div className="grid w-full gap-6">
							<SkeletonGoalCard />
							<SkeletonGoalCard />
						</div>
					) : (
						<>
							<h2 className="text-2xl font-semibold mb-4">Current Goals</h2>
							<div className="grid w-full gap-6">
								{currentGoals.length > 0 ? (
									currentGoals.map((goal) => (
										<GoalCard key={goal.id} goalWithMetrics={goal} />
									))
								) : (
									<div className="text-center">
										<p className="text-muted-foreground">
											No current goals found.
										</p>
									</div>
								)}
							</div>
						</>
					)}
				</div>
				{finishedGoals.length > 0 && (
					<>
						<Separator className="my-8" />
						<div>
							<h2 className="text-2xl font-semibold mb-4">Finished Goals</h2>
							<div className="grid w-full gap-6">
								{finishedGoals.map((goal) => (
									<GoalCard key={goal.id} goalWithMetrics={goal} />
								))}
							</div>
						</div>
					</>
				)}
				{!goalsLoading && goals?.length === 0 && (
					<div className="text-center mt-8">
						<h1 className="text-2xl font-bold">No goals found</h1>
						<p className="text-muted-foreground">
							You have not created any goals yet. Click the button above to
							create a new goal.
						</p>
					</div>
				)}
			</div>
		</main>
	);
};
