import { Button } from "components/ui/button";
import { RefreshCcw } from "lucide-react";
import { interactWithExtension } from "lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

export const LinkedinRefreshButton = () => {
  const [isRefreshing, setIsRefreshing] = useState(false);
  const queryClient = useQueryClient();

  const refreshLinkedinData = async (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      try {
        interactWithExtension<{ status: "ok"; error?: string }>(
          "onDemandLinkedinDataSync",
          (response) => {
            if (response?.status === "ok") {
              resolve(true);
            } else {
              reject(`Error refreshing LinkedIn Data: ${response?.error}`);
            }
          },
        );
      } catch (error) {
        reject(error);
      }
    });
  };

  const handleRefresh = async () => {
    try {
      setIsRefreshing(true);
      await refreshLinkedinData();
      await queryClient.invalidateQueries({
        queryKey: ["linkedinPosts"],
      });
      await queryClient.invalidateQueries({
        queryKey: ["linkedinLastUpdate"],
      });
    } catch (error) {
      console.error("Failed to refresh LinkedIn data:", error);
    } finally {
      setIsRefreshing(false);
    }
  };

  return (
    <Button
      className="ml-5"
      variant="default"
      onClick={() => void handleRefresh()}
      disabled={isRefreshing}
    >
      {isRefreshing ? (
        <RefreshCcw className="w-4 h-4 animate-spin" />
      ) : (
        <RefreshCcw className="w-4 h-4" />
      )}
    </Button>
  );
}; 