import { BlockNoteView } from "@blocknote/mantine";
import { useCreateBlockNote } from "@blocknote/react";
import { useEffect, useState } from "react";

import "@blocknote/mantine/style.css";
import "@blocknote/core/fonts/inter.css";
import { BlockNoteSchema, createInlineContentSpec } from "@blocknote/core";
import { useMutation } from "@tanstack/react-query";
import { Skeleton } from "components/ui/skeleton";
import { convertHTMLToPlainText } from "lib/utils";
import { usePostHog } from "posthog-js/react";
import { appConfig } from "../config";
import { type BenchmarkResult, ReadabilityCard } from "../readability-card";
import AIContentDetection from "./ai-content-detection";

type Props = {
	initialContent: string;
	onContentChange?: (content: string) => void;
	onReadabilityMetricsChange?: (metrics: BenchmarkResult[]) => void;
};

const TextEditorWithReadability = ({
	initialContent,
	onContentChange,
	onReadabilityMetricsChange,
}: Props) => {
	const [currentContent, setCurrentContent] = useState(
		convertHTMLToPlainText(initialContent),
	);

	const editor = useCreateBlockNote();
	const posthog = usePostHog();

	const {
		data: benchmarkResults,
		isPending: isBenchmarkResultsPending,
		mutate: mutateBenchmarkResults,
	} = useMutation({
		mutationFn: async () => {
			try {
				const result = await fetch(
					`${appConfig.API_MAIN_URL}/api/readability/benchmarks`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ text: currentContent }),
					},
				);

				const benchmarks: BenchmarkResult[] | undefined = await result.json();

				if (posthog) {
					posthog.capture("Readability Benchmark", {
						text: currentContent.substring(0, 100),
						results: benchmarks?.map(({ name, value }) => ({ name, value })),
					});
				}

				// Call the onReadabilityMetricsChange callback with the new benchmarks
				if (onReadabilityMetricsChange && benchmarks) {
					onReadabilityMetricsChange(benchmarks);
				}

				return benchmarks;
			} catch (error) {
				console.error("Error:", error);

				// todo add toast
				return [];
			}
		},
	});

	useEffect(() => {
		async function loadInitialStreamResponse() {
			const blocks = await editor.tryParseHTMLToBlocks(initialContent);
			editor.replaceBlocks(editor.document, blocks);
		}
		loadInitialStreamResponse();

		mutateBenchmarkResults();
	}, [initialContent, editor, mutateBenchmarkResults]);

	const getLatestContentInPlainText = async () => {
		const html = await editor.blocksToHTMLLossy(editor.document);
		const plainText =
			convertHTMLToPlainText(html) || convertHTMLToPlainText(initialContent);

		return plainText;
	};

	return (
		<>
			<section className="grid gap-4 my-5 md:grid-cols-3">
				{isBenchmarkResultsPending ? (
					<>
						<Skeleton className="h-96" />
						<Skeleton className="h-96" />
						<Skeleton className="h-96" />
					</>
				) : (
					benchmarkResults?.map(({ name, description, value, maxValue }) => (
						<ReadabilityCard
							name={name}
							description={description}
							value={value}
							maxValue={maxValue}
							refreshAction={() => mutateBenchmarkResults()}
							key={name}
						/>
					))
				)}
			</section>
			<section>
				<AIContentDetection text={currentContent} />
			</section>
			<BlockNoteView
				editor={editor}
				className="unreset"
				onChange={() => {
					const executeAsyncAction = async () => {
						const html = await editor.blocksToHTMLLossy(editor.document);

						if (onContentChange) {
							onContentChange(html);
						}

						setCurrentContent(await getLatestContentInPlainText());
					};

					executeAsyncAction();
				}}
			/>
		</>
	);
};

export default TextEditorWithReadability;
