import { useToast } from "components/hooks/use-toast";
import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Loader2 } from "lucide-react";
import { useState } from "react";

interface User {
	id: string;
	name: string;
	email: string;
}

interface CreateTeamDialogProps {
	isOpen: boolean;
	onClose: () => void;
	onCreateTeam: (name: string, userIds: string[]) => void;
	organizationName: string;
	organizationUsers: User[];
}

export const CreateTeamDialog = ({
	isOpen,
	onClose,
	onCreateTeam,
	organizationName,
	organizationUsers,
}: CreateTeamDialogProps) => {
	const [name, setName] = useState("");
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const { toast } = useToast();

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		if (name.trim() && selectedUsers.length > 0) {
			setIsLoading(true);
			try {
				await onCreateTeam(name, selectedUsers);
				setName("");
				setSelectedUsers([]);
				onClose();
			} catch (error) {
				console.error("Error creating team:", error);
			} finally {
				setIsLoading(false);
			}
		} else {
			toast({
				title: "Invalid input",
				description: "Please enter a team name and select at least one user.",
				variant: "destructive",
			});
		}
	};

	const handleUserToggle = (userId: string) => {
		setSelectedUsers((prev) =>
			prev.includes(userId)
				? prev.filter((id) => id !== userId)
				: [...prev, userId],
		);
	};

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Create New Team for {organizationName} </DialogTitle>
				</DialogHeader>
				<form onSubmit={handleSubmit} className="space-y-4">
					<div>
						<Label htmlFor="name">Team Name</Label>
						<Input
							id="name"
							value={name}
							onChange={(e) => setName(e.target.value)}
							placeholder="Enter team name"
						/>
					</div>
					<div>
						<Label>Select Users</Label>
						<div className="mt-2 space-y-2 max-h-60 overflow-y-auto">
							{organizationUsers.map((user) => (
								<div key={user.id} className="flex items-center space-x-2">
									<Checkbox
										id={`user-${user.id}`}
										checked={selectedUsers.includes(user.id)}
										onCheckedChange={() => handleUserToggle(user.id)}
									/>
									<Label htmlFor={`user-${user.id}`}>
										{user.name} ({user.email})
									</Label>
								</div>
							))}
						</div>
					</div>
					<div className="flex justify-end space-x-2">
						<Button type="button" variant="outline" onClick={onClose}>
							Cancel
						</Button>
						<Button
							type="submit"
							disabled={!name.trim() || selectedUsers.length === 0 || isLoading}
						>
							{isLoading ? (
								<>
									<Loader2 className="mr-2 h-4 w-4 animate-spin" />
									Creating...
								</>
							) : (
								"Create Team"
							)}
						</Button>
					</div>
				</form>
			</DialogContent>
		</Dialog>
	);
};
