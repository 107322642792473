import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Button } from "components/ui/button";
import { RefreshCcw } from "lucide-react";

interface ErrorStateProps {
  message: string;
  onRetry?: () => void;
}

export const ErrorState = ({ message, onRetry }: ErrorStateProps) => {
  return (
    <Card className="p-4">
      <CardHeader>
        <CardTitle className="text-red-500">Error</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <p className="text-center text-red-500">{message}</p>
        {onRetry && (
          <div className="flex justify-center">
            <Button onClick={onRetry}>
              <RefreshCcw className="w-4 h-4 mr-2" />
              Retry
            </Button>
          </div>
        )}
      </CardContent>
    </Card>
  );
}; 