import { DialogDescription, DialogTrigger } from "@radix-ui/react-dialog";
import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import { ScrollArea } from "components/ui/scroll-area";
import { Separator } from "components/ui/separator";
import { Loader2, Plus, X } from "lucide-react"; // Import icons
import React, { useCallback, useEffect, useState } from "react";
import type { Team } from "../teams";
import { ManageTeamDialog } from "./manage-team-dialog";
import { SkeletonTeamCard } from "./skeleton-team-card";

interface TeamCardProps {
	team: Team;
	organizationUsers: { id: string; name: string; email: string }[];
}

export const TeamCard = ({ team, organizationUsers }: TeamCardProps) => {
	const { toast } = useToast();
	const [isLoadingTeamUsers, setIsLoadingTeamUsers] = useState(true);
	const [teamUsers, setTeamUsers] = useState<
		{ id: string; name: string; email: string }[]
	>([]);

	const fetchTeamUsers = useCallback(async () => {
		setIsLoadingTeamUsers(true);
		try {
			const response = await axios.get(`/api/team/${team.id}/users`);
			setTeamUsers(response.data);
		} catch (error) {
			console.error("Error fetching team users:", error);
			toast({
				title: "Error",
				description: "Failed to fetch team users.",
				variant: "destructive",
			});
		} finally {
			setIsLoadingTeamUsers(false);
		}
	}, [team.id, toast]);

	useEffect(() => {
		fetchTeamUsers();
	}, [fetchTeamUsers]);

	if (isLoadingTeamUsers) {
		return <SkeletonTeamCard />;
	}

	return (
		<Card>
			<CardHeader>
				<h2 className="text-xl font-semibold">{team.name}</h2>
			</CardHeader>
			<CardContent className="flex justify-between items-center">
				<h4>{teamUsers.length} users</h4>
				<Dialog>
					<DialogTrigger asChild>
						<Button>Manage Users</Button>
					</DialogTrigger>
					<ManageTeamDialog
						team={team}
						organizationUsers={organizationUsers}
						fetchTeamUsers={fetchTeamUsers}
						teamUsers={teamUsers}
					/>
				</Dialog>
			</CardContent>
		</Card>
	);
};
