const Style = {
	onboarding:
		"relative w-[95%] mt-16 mx-auto mb-12 shadow-md md:w-[44em] md:min-h-[28em] dark:drop-shadow-sm",

	sidebar: `text-white p-5 rounded-t bg-brand-700 md:absolute md:top-0 md:left-0 md:bottom-0 
  md:z-10 md:rounded-tr-none md:rounded-bl md:w-48`,

	logo: "m-0",
	main: "relative min-h-[28em] p-8 pt-6 pb-14 bg-white rounded-b md:rounded md:pl-56 dark:bg-slate-800",
	header:
		"w-full pb-4 mb-4 bg-transparent border-b border-dotted border-slate-200 dark:border-slate-700",
	name: "capitalize text-xl font-semibold",
	description: "text-sm text-slate-400",
	checklist: "mt-4 md:absolute md:top-1/2 md:-translate-y-1/2 md:left-6",
	nav: "absolute bottom-3 left-2 right-6 md:left-56",
	prev: "float-left",
	next: "float-right",
	skip: "-top-7 right-0 text-sm text-white",
	article: "relative min-h-[17em] [&>p]:mb-4",
};

export default Style;
