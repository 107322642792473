/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
"use client";

import axios from "axios";
import { useAPI } from "components/lib";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ProgressChart } from "./progress-chart";
import { ProgressTable } from "./progress-table";
import { SkeletonChart } from "./skeleton-chart";
import { SkeletonTable } from "./skeleton-table";

export const GoalDetails = () => {
	const { id } = useParams();
	const [metrics, setMetrics] = useState<any[]>([]);
	const [metricTypes, setMetricTypes] = useState<string[]>([]);
	const [currentProgressData, setCurrentProgressData] = useState<any[]>([]);
	const [userInitialGoalMetrics, setUserInitialGoalMetrics] = useState<any[]>(
		[],
	);
	const [selectedUser, setSelectedUser] = useState(null);

	const [tableData, setTableData] = useState<any[]>([]);

	const { data: goal, loading: goalLoading } = useAPI(
		`/api/goal/get?goal_id=${id}`,
	);

	// Fetch metrics data when goal is loaded
	useEffect(() => {
		if (goal) {
			axios
				.get(`/api/goal/get-goal-metrics?goal_id=${id}`)
				.then((response) => {
					setMetrics(response.data.data);
				})
				.catch((error) => {
					console.error("Error fetching metrics:", error);
				});
		}
	}, [goal, id]);

	// Concatenate metricTypes to form metricsQuery
	useEffect(() => {
		if (metrics.length) {
			const initialGoalMetrics: any = [];

			const goalMetricIds = metrics.map((metric) => metric.id).join(",");
			axios
				.get(
					`/api/goal/get-user-initial-goal-metrics?goal_metric_ids=${goalMetricIds}`,
				)
				.then((response) => {
					const goalMetricsData = response.data.data;

					metrics.forEach((metric) => {
						const filteredData = goalMetricsData.filter(
							(data: { goal_metric_id: any }) =>
								data.goal_metric_id === metric.id,
						);
						initialGoalMetrics.push({
							metricType: metric.metric_type,
							weeklyGrowthRate: metric.weekly_growth_rate,
							data: filteredData.map(
								(data: {
									user_id: any;
									initial_value: any;
									start_date: any;
								}) => ({
									user_id: data.user_id,
									initial_value: data.initial_value,
									start_date: data.start_date,
								}),
							),
						});
					});

					setUserInitialGoalMetrics(initialGoalMetrics);
					const metricTypes = metrics.map((metric) => metric.metric_type);
					setMetricTypes(metricTypes);
				})
				.catch((error) => {
					console.error("Error fetching user initial goal metrics:", error);
				});
		}
	}, [metrics]);

	// Fetch metrics data when metricTypes are loaded
	useEffect(() => {
		if (metricTypes.length && goal) {
			const now = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
			axios
				.get(
					`/api/goal/get-current-users-progress?goal_id=${id}&start_date=${goal.start_date}&end_date=${now}&metrics=${metricTypes.join(",")}`,
				)
				.then((response) => {
					const responseData = response.data.data;
					setCurrentProgressData(responseData);
					setTableData(responseData);
				})
				.catch((error) => {
					console.error("Error fetching metrics:", error);
				});
		}
	}, [metricTypes, id, goal]);

	return (
		<div className="px-4 py-8 mx-auto w-full max-w-6xl md:px-6 lg:px-8">
			<div className="flex flex-col gap-8">
				<div className="text-center">
					{!goalLoading && goal ? (
						<>
							<h1 className="text-4xl font-bold">{goal.title}</h1>
							<p className="text-lg">{goal.description}</p>
						</>
					) : (
						<>
							<h1 className="text-3xl font-bold">Loading...</h1>
							<h3 className="text-muted-foreground">Loading...</h3>
						</>
					)}
				</div>
				<div className="flex flex-col gap-8 h-full">
					{currentProgressData.length > 0 ? (
						<ProgressChart
							id={id as string}
							data={currentProgressData}
							metrics={metrics}
							goal={goal}
							metricTypes={metricTypes}
							selectedUser={selectedUser}
							setSelectedUser={setSelectedUser}
							initialGoalMetrics={userInitialGoalMetrics}
						/>
					) : (
						<SkeletonChart />
					)}
					{tableData.length > 0 && metricTypes && userInitialGoalMetrics ? (
						<ProgressTable
							data={tableData}
							initialGoalMetrics={userInitialGoalMetrics}
							metricTypes={metricTypes}
							onRowClick={setSelectedUser}
						/>
					) : (
						<SkeletonTable />
					)}
				</div>
			</div>
		</div>
	);
};
