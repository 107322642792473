/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	CaretSortIcon,
	ChevronDownIcon,
	ChevronUpIcon,
} from "@radix-ui/react-icons";
import {
	type ColumnFiltersState,
	type SortingState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Input } from "components/ui/input";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Separator } from "components/ui/separator";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { useMemo, useState } from "react";

import { calculateScore, getBackgroundColorClass } from "../utils";

const SortableHeaderButton = ({
	column,
	label,
}: {
	column: any;
	label: any;
}) => {
	return (
		<Button
			className="px-0"
			variant="ghost"
			onClick={() => {
				column.toggleSorting();
			}}
		>
			{label}
			{column.getIsSorted() === "desc" ? (
				<ChevronDownIcon className="ml-2 w-4 h-4" />
			) : column.getIsSorted() === "asc" ? (
				<ChevronUpIcon className="ml-2 w-4 h-4" />
			) : (
				<CaretSortIcon className="ml-2 w-4 h-4 opacity-50" />
			)}
		</Button>
	);
};

const getColumns = (metrics: string[]) => [
	{
		accessorKey: "name",
		title: "name",
		header: ({ column }: { column: any }) => (
			<SortableHeaderButton column={column} label="Contributor" />
		),
		cell: (info: any) => info.getValue(),
	},
	...metrics.map((metric) => ({
		accessorKey: metric,
		title: metric,
		header: ({ column }: { column: any }) => (
			<SortableHeaderButton
				column={column}
				label={metric.charAt(0).toUpperCase() + metric.slice(1)}
			/>
		),
		cell: (info: any) => info.getValue(),
		sortingFn: (a: any, b: any) => {
			if (metric === "posts") return a.original[metric] - b.original[metric];
			const metricKey = metric + "Percentage";
			return a.original[metricKey] - b.original[metricKey];
		},
	})),
];

const calculateExpectedValue = (
	initialValue: number,
	weeklyGrowthRate: number,
	weekNumber: number,
) => initialValue * Math.pow(weeklyGrowthRate, weekNumber);

const mergeMetricsData = (
	weekData: any,
	metricTypes: string[],
	initialGoalMetrics: any,
	weekNumber: number,
) => {
	const metricsData: { [userId: string]: any } = {};

	metricTypes.forEach((metric) => {
		if (weekData[metric]) {
			weekData[metric].forEach((item: any) => {
				if (!metricsData[item.user_id]) {
					metricsData[item.user_id] = {
						user_id: item.user_id,
						name: item.user_name,
					};
				}

				const initialMetricData = initialGoalMetrics.find(
					(goal: any) => goal.metricType === metric,
				);
				const userInitialData = initialMetricData?.data.find(
					(user: any) => user.user_id === item.user_id,
				);
				const initialValue = userInitialData?.initial_value || 0;
				const weeklyGrowthRate = initialMetricData?.weeklyGrowthRate || 1;

				if (metric !== "posts") {
					const expectedValue = calculateExpectedValue(
						initialValue,
						weeklyGrowthRate,
						weekNumber,
					);

					const completionPercentage = Math.round(
						(item.value / expectedValue) * 100,
					);

					metricsData[item.user_id][metric] =
						`${item.value}/${Math.round(expectedValue)} (${completionPercentage}%)`;

					const metricPercentage = metric + "Percentage";
					metricsData[item.user_id][metricPercentage] = completionPercentage;
				} else {
					metricsData[item.user_id][metric] = `${item.value}/${initialValue}`;
				}
			});
		}
	});

	return Object.values(metricsData);
};

export const ProgressTable = ({
	data,
	metricTypes,
	initialGoalMetrics,
	onRowClick,
}: {
	data: any;
	initialGoalMetrics: any;
	metricTypes: string[];
	onRowClick: (user: any) => void;
}) => {
	const [selectedWeek, setSelectedWeek] = useState(data[data.length - 1].week);
	const [searchTerm, setSearchTerm] = useState("");

	const filteredData = useMemo(() => {
		const weekData =
			data.find((week: { week: any }) => week.week === selectedWeek) ||
			data[data.length - 1];
		const weekNumber =
			data.findIndex((week: { week: any }) => week.week === selectedWeek) + 1;
		const mergedData = mergeMetricsData(
			weekData,
			metricTypes,
			initialGoalMetrics,
			weekNumber,
		);
		if (!searchTerm) return mergedData;
		return mergedData.filter((item) =>
			item.name.toLowerCase().includes(searchTerm.toLowerCase()),
		);
	}, [data, searchTerm, selectedWeek, metricTypes, initialGoalMetrics]);

	const columns = useMemo(() => getColumns(metricTypes), [metricTypes]);

	const [sorting, setSorting] = useState<SortingState>([
		{ id: metricTypes[1], desc: true },
	]);
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const table = useReactTable({
		data: filteredData,
		columns: columns,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onColumnVisibilityChange: setColumnVisibility,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
		},
	});

	return (
		<Card className="flex flex-col h-full">
			<CardHeader>
				<CardTitle className="text-2xl">
					Individual Contributor Progress
				</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="mb-4">
					<div className="flex justify-between items-center">
						<div className="flex gap-4 items-center">
							<Input
								type="search"
								placeholder="Search by name..."
								value={searchTerm}
								onChange={(event) => setSearchTerm(event.target.value)}
								className="w-full max-w-sm bg-white"
							/>
						</div>
						<div className="flex">
							<Select
								value={selectedWeek}
								onValueChange={(week) => setSelectedWeek(week)}
							>
								<SelectTrigger className="w-[280px] bg-white">
									<SelectValue placeholder="Select a week" />
								</SelectTrigger>
								<SelectContent>
									<SelectGroup>
										<SelectLabel>Weeks in Goal</SelectLabel>
										{data.map((week: any) => (
											<SelectItem key={week.week} value={week.week}>
												Week {week.week}
											</SelectItem>
										))}
									</SelectGroup>
								</SelectContent>
							</Select>
						</div>
					</div>
				</div>
				<Separator className="my-4" />
				<Table>
					<TableHeader>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<TableHead key={header.id} colSpan={header.colSpan}>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</TableHead>
								))}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{table.getRowModel().rows.map((row) => {
							const score = calculateScore(row.original, metricTypes);
							const backgroundColorClass = getBackgroundColorClass(score);
							return (
								<TableRow
									key={row.id}
									className={`hover:cursor-pointer hover:bg-muted-foreground/10 ${backgroundColorClass}`}
									onClick={() => {
										onRowClick({
											user_id: row.original.user_id,
											user_name: row.original.name,
										});
									}}
								>
									{row.getVisibleCells().map((cell) => (
										<TableCell key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext(),
											)}
										</TableCell>
									))}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</CardContent>
		</Card>
	);
};
