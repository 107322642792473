import { Card, CardContent, CardHeader } from "components/ui/card";
import { Skeleton } from "components/ui/skeleton";

export const SkeletonTeamCard = () => {
	return (
		<Card>
			<CardHeader className="pb-2">
				<Skeleton className="h-6 w-2/3" />
			</CardHeader>
			<CardContent>
				<Skeleton className="h-4 w-full mb-2" />
				<Skeleton className="h-4 w-3/4" />
			</CardContent>
		</Card>
	);
};
