import { Content, Message, Row } from "components/lib";

interface Props {
	t: (key: string) => string;
}

export function NotFound(props: Props) {
	return (
		<Row>
			<Content>
				<Message
					type="error"
					title={props.t("error.404.title")}
					text={props.t("error.404.text")}
					buttonText={props.t("error.404.button")}
					buttonLink="/"
				/>
			</Content>
		</Row>
	);
}
