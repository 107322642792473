import { useQuery, useQueryClient } from "@tanstack/react-query";
import { appConfig } from "views/content-creation/config";
import { useContext } from "react";
import { AuthContext } from "components/lib";
import type { AuthContextType } from "types/authContext";
import type { PaginatedLeadsResponse } from "../types";
import axios from "axios";

interface UseUserLeadsReturn {
  data: PaginatedLeadsResponse | null;
  loading: boolean;
  error: Error | null;
  refetch: () => Promise<void>;
}

const fetchUserLeads = async ({
  userId,
  page,
  limit,
  searchTerm = '',
  token,
}: {
  userId: string;
  page: number;
  limit: number;
  searchTerm?: string;
  token: string;
}) => {
  const { data } = await axios.get(
    `${appConfig.API_MAIN_URL}/api/lead-lens/leads/${userId}`,
    {
      params: { page, limit, searchTerm },
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    }
  );
  return data;
};

export const useUserLeads = (
  userId: string | undefined,
  page = 1,
  limit = 10,
  searchTerm = ''
): UseUserLeadsReturn => {
  const auth = useContext<AuthContextType>(AuthContext);
  const queryClient = useQueryClient();
  
  const { data, isLoading, error, refetch: queryRefetch } = useQuery({
    queryKey: ['userLeads', userId, page, limit, searchTerm],
    queryFn: () => {
      if (!userId || !auth.user?.token) return null;
      return fetchUserLeads({
        userId,
        page,
        limit,
        searchTerm,
        token: auth.user.token
      });
    },
    enabled: !!userId && !!auth.user?.token,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const refetch = async (): Promise<void> => {
    if (!userId) return;
    await queryClient.invalidateQueries({
      queryKey: ['userLeads', userId],
      exact: false,
    });
    await queryRefetch();
  };

  return {
    data: data || null,
    loading: isLoading,
    error: error as Error | null,
    refetch
  };
}; 