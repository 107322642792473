/***
 *
 *   MAGIC SIGN IN
 *   Confirms magic token and redirects to dashboard if successful
 *
 **********/

import Axios from "axios";
import {
	Animate,
	AuthContext,
	Message,
	Row,
	useLocation,
	useNavigate,
} from "components/lib";
import React, { useContext, useEffect, useRef, useState } from "react";

export function MagicSignin(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const qs = location.search;
	const context = useRef(useContext(AuthContext));

	const [message, setMessage] = useState({
		type: "success",
		title: props.t("auth.signin.magic.message.success.title"),
		text: props.t("auth.signin.magic.message.success.text"),
	});

	useEffect(() => {
		const verifyToken = async (token) => {
			try {
				const res = await Axios.post("/api/auth/magic/verify", {
					token: token,
				});

				res.data["2fa_required"]
					? navigate(`/signin/otp?token=${res.data.token}`)
					: res.status === 200
						? navigate(context.current.signin(res))
						: invalidLink();
			} catch {
				invalidLink();
			}
		};

		if (qs.includes("?token=")) {
			// check token exists
			verifyToken(qs.slice(Math.max(0, qs.indexOf("?token=") + 7)));
		} else {
			invalidLink();
		}
	}, [qs, context, navigate]);

	function invalidLink() {
		setMessage({
			type: "error",
			title: props.t("auth.signin.magic.message.error.title"),
			text: props.t("auth.signin.magic.message.error.text"),
			buttonLink: "/signin",
			buttonText: props.t("auth.signin.magic.message.error.button"),
		});
	}

	return (
		<Animate>
			<Row>
				<div style={{ width: "28em", margin: "0 auto" }}>
					<Message
						type={message.type}
						title={message.title}
						text={message.text}
						buttonText={message.buttonText}
						buttonLink={message.buttonLink}
					/>
				</div>
			</Row>
		</Animate>
	);
}
