import { DatePickerWithRange } from "components/ui/date-range-picker";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { endOfDay, format, subDays } from "date-fns";
import { useMemo, useState } from "react";
import { sortedTableTimeFrameOptions } from "views/dashboard/utils";

interface DateRange {
	from: Date | undefined;
	to: Date | undefined;
}

interface TimeFrame {
	label: string;
	value: string | number;
	type?: string;
	from?: string;
	to?: string;
}

interface DateRangeSelectorProps {
	t: (key: string) => string;
	disabled?: boolean;
	onDateChange?: (dates: {
		startDate: string;
		endDate: string;
		timeFrame?: TimeFrame;
	}) => void;
	initialTimeFrame?: TimeFrame;
}

export const DateRangeSelector = ({
	t,
	disabled = false,
	onDateChange,
	initialTimeFrame,
}: DateRangeSelectorProps) => {
	const [timeFrame, setTimeFrame] = useState<TimeFrame>(
		initialTimeFrame || sortedTableTimeFrameOptions[1],
	);
	const [dateRange, setDateRange] = useState<DateRange>(() => ({
		from: timeFrame.from ? new Date(timeFrame.from) : undefined,
		to: timeFrame.to ? new Date(timeFrame.to) : undefined,
	}));
	const [customDateLabel, setCustomDateLabel] = useState("");

	const startDate = useMemo(() => {
		try {
			if (timeFrame.value === "custom") {
				if (timeFrame.from) {
					return format(new Date(timeFrame.from), "yyyy-MM-dd'T'HH:mm:ssxxx");
				}
				if (dateRange.from) {
					return format(dateRange.from, "yyyy-MM-dd'T'HH:mm:ssxxx");
				}
				return format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx");
			}
			return format(
				subDays(new Date(), Number(timeFrame.value)),
				"yyyy-MM-dd'T'HH:mm:ssxxx",
			);
		} catch (error) {
			return format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx");
		}
	}, [timeFrame, dateRange.from]);

	const endDate = useMemo(() => {
		try {
			if (timeFrame.value === "custom") {
				if (timeFrame.to) {
					return format(new Date(timeFrame.to), "yyyy-MM-dd'T'HH:mm:ssxxx");
				}
				if (dateRange.to) {
					return format(dateRange.to, "yyyy-MM-dd'T'HH:mm:ssxxx");
				}
				return format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx");
			}
			return format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx");
		} catch (error) {
			return format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx");
		}
	}, [timeFrame, dateRange.to]);

	const handleTimeFrameChange = (selectedTimeFrame: TimeFrame) => {
		setTimeFrame(selectedTimeFrame);

		if (selectedTimeFrame.value !== "custom") {
			setCustomDateLabel("");
			setDateRange({ from: undefined, to: undefined });

			if (onDateChange) {
				onDateChange({
					startDate: format(
						subDays(new Date(), Number(selectedTimeFrame.value)),
						"yyyy-MM-dd'T'HH:mm:ssxxx",
					),
					endDate: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx"),
					timeFrame: selectedTimeFrame,
				});
			}
		}
	};

	return (
		<div className="space-y-4">
			<div className="flex gap-4">
				<Select
					value={customDateLabel ? "custom" : String(timeFrame.value)}
					onValueChange={(value) =>
						handleTimeFrameChange(
							sortedTableTimeFrameOptions.find(
								(option) => String(option.value) === value,
							) || sortedTableTimeFrameOptions[0],
						)
					}
					disabled={disabled}
				>
					<SelectTrigger className="max-w-60 bg-white">
						<SelectValue>{customDateLabel || t(timeFrame.label)}</SelectValue>
					</SelectTrigger>
					<SelectContent>
						<SelectGroup>
							<SelectLabel>Time Frames</SelectLabel>
							{sortedTableTimeFrameOptions.map((option) => (
								<SelectItem key={option.value} value={String(option.value)}>
									{t(option.label)}
								</SelectItem>
							))}
						</SelectGroup>
					</SelectContent>
				</Select>

				{timeFrame.value === "custom" && (
					<DatePickerWithRange
						from={dateRange.from ?? undefined}
						to={dateRange.to ?? undefined}
						onDateChange={(range) => {
							if (!range?.from || !range?.to) return;
							const endOfTo = endOfDay(range.to);
							setDateRange({ from: range.from, to: endOfTo });
							const customLabel = `${format(range.from, "MMM d, yyyy")} - ${format(endOfTo, "MMM d, yyyy")}`;
							setCustomDateLabel(customLabel);

							const customTimeFrame = {
								label: customLabel,
								value: "custom",
								type: "custom",
								from: range.from.toISOString(),
								to: endOfTo.toISOString(),
							};

							setTimeFrame(customTimeFrame);

							if (onDateChange) {
								onDateChange({
									startDate: format(range.from, "yyyy-MM-dd'T'HH:mm:ssxxx"),
									endDate: format(endOfTo, "yyyy-MM-dd'T'HH:mm:ssxxx"),
									timeFrame: customTimeFrame,
								});
							}
						}}
						disabled={disabled}
					/>
				)}
			</div>

			<div>
				<p className="text-muted-foreground">
					Showing statistics from{" "}
					<span className="font-medium">
						{startDate ? format(new Date(startDate), "MMM d, yyyy") : "-"}
					</span>{" "}
					to{" "}
					<span className="font-medium">
						{endDate ? format(new Date(endDate), "MMM d, yyyy") : "-"}
					</span>
				</p>
			</div>
		</div>
	);
};
