/***
 *
 *   BILLING
 *   Update card details
 *
 **********/

import { Card, CreditCard, PaymentForm, useAPI } from "components/lib";
import React, { useEffect, useState } from "react";

export function BillingCard(props) {
	const [card, setCard] = useState(null);
	const data = useAPI("/api/organization/card");

	useEffect(() => {
		if (data.data) setCard(data.data);
	}, [data.data]);

	return (
		<Card restrictWidth loading={data.loading} className={props.className}>
			{card && (
				<CreditCard
					brand={card.brand}
					last_four={card.last4}
					expires={`${card.exp_month}/${card.exp_year}`}
				/>
			)}

			<PaymentForm
				className="restrict-width"
				inputs={{
					token: {
						label: props.t("organization.billing.card.form.token.label"),
						type: "creditcard",
						required: true,
					},
				}}
				url="/api/organization/card"
				method="PATCH"
				callback={(res) => setCard(res.data.data)}
				buttonText={props.t("organization.billing.card.form.button")}
			/>
		</Card>
	);
}
