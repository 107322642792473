import {
	AuthContext,
	Button,
	Glass,
	Link,
	Logo,
	Row,
	useTranslation,
} from "components/lib";
import { Fragment, useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import Style from "./auth.tailwind.js";

export function AuthNav() {
	// state, context
	const [mobileNavOpen, setMobileNavOpen] = useState(false);

	return (
		<Row color="dark" className={Style.wrapper}>
			<div className={Style.flexContainer}>
				<Logo className={Style.logo} />

				{!mobileNavOpen && (
					<Button
						icon="menu"
						iconColor="light"
						iconSize={24}
						title="Open Nav"
						position="absolute"
						className={Style.toggleButtonOpen}
						action={() => setMobileNavOpen(true)}
					/>
				)}

				<nav className={Style.nav}>
					<div className={Style.desktop}>
						<Links />
					</div>

					{mobileNavOpen && (
						<div className={Style.mobile}>
							<Glass>
								<Links />

								<Button
									icon="x"
									iconColor="dark"
									size={24}
									title="Open Nav"
									position="absolute"
									className={Style.toggleButtonClosed}
									action={() => setMobileNavOpen(false)}
								/>
							</Glass>
						</div>
					)}
				</nav>
			</div>
		</Row>
	);
}

function Links() {
	const { t } = useTranslation();
	const context = useContext(AuthContext);
	const location = useLocation();

	const hasUserIdParam = new URLSearchParams(location.search).has("id");

	return (
		<div className={Style.links}>
			{context.user?.token ? (
				<Button
					small
					goto="/dashboard"
					text={t("auth.nav.dashboard")}
					className={Style.button}
				/>
			) : (
				<Fragment>
					<div className={Style.links}>
						{/* <Link
              to="/signin"
              className={Style.button}
              style={{ color: "white" }}
            >
              {t("auth.nav.signin")}
            </Link> */}
						{/* {!hasUserIdParam && (
              <div className={Style.buttonWrapper}>
                <Button
                  small
                  goto="/signup"
                  text={t("auth.nav.signup")}
                  className={Style.button}
                />
              </div>
            )} */}
					</div>
				</Fragment>
			)}
		</div>
	);
}
