import "react-toastify/dist/ReactToastify.css";

import { LoadingOutlined } from "@ant-design/icons";
import { Button, type ButtonProps } from "components/ui/button";
import { useState } from "react";
import { toast } from "react-toastify";

type AsyncButtonProps = {
	asyncFunction: () => Promise<void>;
	label: string;
	successMessage?: string;
	errorMessage?: string;
	endContent?: React.ReactNode;
	variant?: ButtonProps["variant"];
} & ButtonProps;

export const AsyncButton: React.FC<AsyncButtonProps> = ({
	asyncFunction,
	label,
	successMessage,
	errorMessage,
	variant,
	...props // Rest operator to collect all other props
}) => {
	const [loading, setLoading] = useState(false);

	const handleClick = async () => {
		setLoading(true);
		try {
			await asyncFunction();
			if (successMessage) {
				toast.success(
					<div>
						<h4> {successMessage ?? "Operación exitosa"} </h4>
					</div>,
					{
						autoClose: 4000,
					},
				);
			}
		} catch (error: unknown) {
			if (errorMessage) {
				const errorMessageComponent = (
					<div>
						<h4> {errorMessage} </h4>
						<p> {error instanceof Error && error.message} </p>
					</div>
				);
				console.error(error);
				toast.error(errorMessageComponent, {
					autoClose: 4000,
				});
			} else {
				const errorMessageComponent = (
					<div>
						<h4> Error no esperado </h4>
						<p> {error instanceof Error && error.message} </p>
					</div>
				);
				toast.error(errorMessageComponent, {
					autoClose: 4000,
				});
			}
		}
		setLoading(false);
	};

	return (
		<Button
			size={"lg"}
			variant={variant ?? "outline"}
			onClick={() => void handleClick()}
			{...props} // Spread operator to pass all other props to the Button
		>
			{label} {loading && <LoadingOutlined />}
		</Button>
	);
};
