import { Organization } from "views/organization";
import { TwoFA } from "views/organization/2fa";
import { APIKeyEditor } from "views/organization/apikey/edit";
import { APIKeyList } from "views/organization/apikey/list";
import { Billing } from "views/organization/billing";
import { Notifications } from "views/organization/notifications";
import { Password } from "views/organization/password";
import { Profile } from "views/organization/profile";
import { Upgrade } from "views/organization/upgrade";
import { UserSettings } from "views/organization/userSettings";
import { Users } from "views/organization/users";
const Routes = [
	{
		path: "/organization",
		view: Organization,
		layout: "app",
		permission: "user",
		title: "organization.index.title",
	},
	{
		path: "/organization/profile",
		view: Profile,
		layout: "app",
		permission: "user",
		title: "organization.profile.title",
	},
	{
		path: "/organization/password",
		view: Password,
		layout: "app",
		permission: "user",
		title: "organization.password.title",
	},
	{
		path: "/organization/2fa",
		view: TwoFA,
		layout: "app",
		permission: "user",
		title: "organization.2fa.title",
	},
	{
		path: "/organization/billing",
		view: Billing,
		layout: "app",
		permission: "owner",
		title: "organization.billing.title",
	},
	{
		path: "/organization/upgrade",
		view: Upgrade,
		layout: "app",
		permission: "owner",
		title: "organization.upgrade.title",
	},
	{
		path: "/organization/users",
		view: Users,
		layout: "app",
		permission: "admin",
		title: "organization.users.title",
	},
	{
		path: "/organization/notifications",
		view: Notifications,
		layout: "app",
		permission: "user",
		title: "organization.notifications.title",
	},
	{
		path: "/organization/apikeys",
		view: APIKeyList,
		layout: "app",
		permission: "developer",
		title: "organization.api_keys.title",
	},
	{
		path: "/organization/apikeys/create",
		view: APIKeyEditor,
		layout: "app",
		permission: "developer",
		title: "organization.api_keys.title",
	},
	{
		path: "/organization/apikeys/edit",
		view: APIKeyEditor,
		layout: "app",
		permission: "developer",
		title: "organization.api_keys.title",
	},
	{
		path: "/organization/user-settings",
		view: UserSettings,
		layout: "app",
		permission: "user",
		title: "organization.user_settings.title",
	},
];

export default Routes;
