import { Input } from "components/ui/input";
import { useState } from "react";
import LinkedInMetricsTable from "./linkedin-metrics-table";
import WordPressMetricsTable from "./wordpress-metrics-table";

const CollaboratorMetricsTable = ({
	metrics,
	t,
	usersSyncStatus,
	platform,
}) => {
	const [nameFilter, setNameFilter] = useState("");

	const filteredMetrics = metrics.filter((metric) =>
		metric.user_name.toLowerCase().includes(nameFilter.toLowerCase()),
	);

	return (
		<div className="w-full">
			<div className="flex justify-between items-center py-4">
				<Input
					placeholder={t("dashboard.collaborator_metrics.filter_user_names")}
					value={nameFilter}
					onChange={(event) => setNameFilter(event.target.value)}
					className="max-w-sm"
				/>
			</div>

			{platform === "linkedin" ? (
				<LinkedInMetricsTable
					metrics={filteredMetrics}
					t={t}
					usersSyncStatus={usersSyncStatus}
				/>
			) : (
				<WordPressMetricsTable metrics={filteredMetrics} t={t} />
			)}
		</div>
	);
};

export default CollaboratorMetricsTable;
