/***
 *
 *   SETUP AUTHENTICATION
 *   Configure networks for social sign in
 *
 **********/

import { Form, Helper } from "components/lib";
import { Fragment } from "react";

export function SetupAuth() {
	return (
		<Fragment>
			<Helper
				text="Need help? Refer to the setup guide"
				url="https://docs.usegravity.app/gravity-server/authentication/social-sign-on"
			/>

			<Form
				inputs={{
					facebook_app_id: {
						type: "password",
						label: "Facebook App ID",
					},
					facebook_app_secret: {
						type: "password",
						label: "Facebook App Secret",
					},
					twitter_api_key: {
						type: "password",
						label: "Twitter App ID",
					},
					twitter_api_secret: {
						type: "password",
						label: "Twitter API Secret",
					},
				}}
				url="/api/setup/auth"
				method="POST"
				buttonText="Save"
			/>
		</Fragment>
	);
}
