import { SetupOnboarding } from "views/setup/onboarding";

import Settings from "../settings.json";

const Routes = [
	{
		path: "/setup",
		view: SetupOnboarding,
		layout: "onboarding",
		title: `Welcome to ${Settings.appName}`,
	},
];

export default Routes;
