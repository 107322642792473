import { Card, CardContent, CardFooter } from "components/ui/card";
import { Check, Eye, MessageSquare, Share2, ThumbsUp } from "lucide-react";
import { useState } from "react";
import type { LinkedinPostType } from "../../../types";

type LinkedinPostProps = {
	post: LinkedinPostType;
	isSelected: boolean;
	onSelect: (post: LinkedinPostType) => void;
};

export const LinkedinPost: React.FC<LinkedinPostProps> = ({
	post,
	isSelected,
	onSelect,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleExpand = (e: React.MouseEvent) => {
		e.stopPropagation();
		setIsExpanded(!isExpanded);
	};

	return (
		<Card
			className={`bg-white my-4 pt-4 h-full ${isSelected ? "ring-2 ring-primary" : ""} cursor-pointer transition-colors flex flex-col relative`}
			onClick={() => onSelect(post)}
		>
			<CardContent className="flex-grow overflow-hidden">
				{isSelected && (
					<div className="absolute top-2 right-2 bg-primary text-white rounded-full p-1">
						<Check className="w-4 h-4" />
					</div>
				)}
				<p
					className={`${isExpanded ? "" : "line-clamp-6"}`}
					style={{ whiteSpace: "pre-line" }}
				>
					{post.content}
				</p>
				<button
					type="button"
					onClick={toggleExpand}
					className="text-primary hover:underline mt-2"
				>
					{isExpanded ? "View less" : "View more"}
				</button>
			</CardContent>
			<CardFooter className="flex justify-end items-center text-sm text-muted-foreground mt-auto">
				<div className="flex space-x-4">
					<span className="flex items-center">
						<Eye className="w-4 h-4 mr-1" /> {post.impressions ?? 0}
					</span>
					<span className="flex items-center">
						<ThumbsUp className="w-4 h-4 mr-1" /> {post.likes ?? 0}
					</span>
					<span className="flex items-center">
						<Share2 className="w-4 h-4 mr-1" /> {post.shares ?? 0}
					</span>
					<span className="flex items-center">
						<MessageSquare className="w-4 h-4 mr-1" /> {post.comments ?? 0}
					</span>
				</div>
			</CardFooter>
		</Card>
	);
};

export default LinkedinPost;
