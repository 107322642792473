import { Skeleton } from "components/ui/skeleton";

export function SkeletonGoalCard() {
	return (
		<div className="w-full space-y-4">
			<div className="flex justify-between items-center">
				<div>
					<Skeleton className="h-6 w-48" />
					<Skeleton className="h-4 w-64 mt-2" />
				</div>
				<Skeleton className="h-8 w-24 rounded-full" />
			</div>
			<div className="space-y-2">
				<Skeleton className="h-4 w-32" />
				<Skeleton className="h-2 w-full rounded-full" />
			</div>
		</div>
	);
}
