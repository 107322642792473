/***
 *
 *   SIGN UP STEP 2
 *   Signup form for organization owners
 *   Step 1: create organization
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import {
	Animate,
	AuthContext,
	Card,
	Event,
	Link,
	PaymentForm,
	Row,
	useNavigate,
	usePlans,
} from "components/lib";
import { useContext } from "react";

export function SignupPlan(props) {
	const context = useContext(AuthContext);
	const navigate = useNavigate();
	const plans = usePlans();
	const plan = window.location.hash.slice(1);

	if (!plans.data) return false;

	return (
		<Animate type="pop">
			<Row title={props.t("auth.signup.plan.title")}>
				<Card loading={false} restrictWidth center transparent>
					<PaymentForm
						inputs={{
							plan: {
								label: props.t("auth.signup.plan.form.plan.label"),
								type: "select",
								options: plans.data.list,
								default: plan,
								required: true,
							},
							token: {
								label: props.t("auth.signup.plan.form.token.label"),
								type: "creditcard",
								required: true,
							},
						}}
						url="/api/organization/plan"
						method="POST"
						buttonText={props.t("auth.signup.plan.form.button")}
						callback={(res) => {
							// save the plan to context, then redirect
							Event("selected_plan", { plan: res.data.plan });
							context.update({
								plan: res.data.plan,
								subscription: res.data.subscription,
							});
							navigate("/dashboard");
						}}
					/>

					<footer className="mt-4">
						<Link
							url="/organization/profile"
							text={props.t("auth.signup.plan.footer.link_text")}
						/>
					</footer>
				</Card>
			</Row>
		</Animate>
	);
}
