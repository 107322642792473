import { Button } from "components/ui/button";
import { Checkbox } from "components/ui/checkbox";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { ScrollArea } from "components/ui/scroll-area";
import { useEffect, useMemo, useState } from "react";
import type { UserPredictedMetricData } from "../types";
import type { GoalUser } from "./goal-creation";

interface UserSelectionDialogProps {
	metrics: UserPredictedMetricData;
	organizationUsers: GoalUser[];
	selectedUsers: GoalUser[];
	onSelectionChange: (selectedUsers: GoalUser[]) => void;
}

export function UserSelectionDialog({
	metrics,
	organizationUsers,
	selectedUsers,
	onSelectionChange,
}: UserSelectionDialogProps) {
	const [open, setOpen] = useState(false);

	const handleUserToggle = (user: GoalUser) => {
		const updatedUsers = selectedUsers.some((u) => u.id === user.id)
			? selectedUsers.filter((u) => u.id !== user.id)
			: [...selectedUsers, user];
		onSelectionChange(updatedUsers);
	};

	const handlePostsChange = (userId: string, posts: number) => {
		const updatedUsers = selectedUsers.map((user) =>
			user.id === userId ? { ...user, posts } : user,
		);
		onSelectionChange(updatedUsers);
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger className="w-full bg-white" asChild>
				<Button variant="outline">Select Users</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[800px]">
				<DialogHeader>
					<DialogTitle>Select Users for Goal</DialogTitle>
					<DialogDescription>
						Choose which users to include in this goal and set their post
						targets. All users are selected by default.
					</DialogDescription>
				</DialogHeader>
				<ScrollArea className="mt-4 h-[300px] pr-4">
					{organizationUsers.map((user) => {
						const selectedUser = selectedUsers.find((u) => u.id === user.id);
						return (
							<div key={user.id} className="flex items-center space-x-2 mb-2">
								<Checkbox
									id={user.id}
									checked={!!selectedUser}
									onCheckedChange={() => handleUserToggle(user)}
								/>
								<label
									htmlFor={user.id}
									className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex-grow"
								>
									{user.name}
								</label>

								<div className="flex space-x-4 text-sm text-gray-500">
									<span>Posts</span>
									<Input
										type="number"
										min="1"
										value={selectedUser?.posts || user.posts}
										onChange={(e) =>
											handlePostsChange(
												user.id,
												Number.parseInt(e.target.value) || 1,
											)
										}
										className="w-12"
										placeholder="Posts"
									/>
									<span>Likes: {Math.round(metrics[user.id]?.likes || 0)}</span>
									<span>
										Comments: {Math.round(metrics[user.id]?.comments || 0)}
									</span>
									<span>
										Shares: {Math.round(metrics[user.id]?.shares || 0)}
									</span>
									<span>
										Impressions:{" "}
										{Math.round(metrics[user.id]?.impressions || 0)}
									</span>
								</div>
							</div>
						);
					})}
				</ScrollArea>
				<DialogFooter>
					<Button onClick={() => setOpen(false)}>Save Selection</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}
