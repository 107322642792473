import type { Column } from "@tanstack/react-table";
import { Button } from "components/ui/button";
import { ChevronDown, ChevronUp } from "lucide-react";

interface SortableHeaderButtonProps<TData> {
	column: Column<TData>;
	title: string;
}

export function SortableHeaderButton<TData>({
	column,
	title,
}: SortableHeaderButtonProps<TData>) {
	return (
		<Button
			className="px-0"
			variant="ghost"
			onClick={() => column.toggleSorting()}
		>
			{title}
			{column.getIsSorted() === "desc" ? (
				<ChevronDown className="ml-2 w-4 h-4" />
			) : (
				column.getIsSorted() === "asc" && <ChevronUp className="ml-2 w-4 h-4" />
			)}
		</Button>
	);
}
