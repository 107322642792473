import { GoalCreation } from "views/organization-goals/_components/goal-creation";
import { GoalDetails } from "views/organization-goals/_components/goal-detail";
import { OrganizationGoals } from "views/organization-goals/organization-goals";

const routes = [
	{
		path: "/organization-goals",
		view: OrganizationGoals,
		layout: "app",
		permission: "admin",
		title: "organization-goals.title",
	},
	{
		path: "/organization-goals/create-goal",
		view: GoalCreation,
		layout: "app",
		permission: "admin",
		title: "organization-goals.create-goal",
	},
	{
		path: "/organization-goals/:id",
		view: GoalDetails,
		layout: "app",
		permission: "admin",
		title: "organization-goals.goal-details",
	},
];

export default routes;
