"use client";

import { CalendarIcon } from "@radix-ui/react-icons";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { format } from "date-fns";
import type { Locale } from "date-fns";
import { cn } from "lib/utils";
import * as React from "react";
import type { DateRange } from "react-day-picker";

interface DatePickerWithRangeProps {
	className?: string;
	from: Date | undefined;
	to: Date | undefined;
	onDateChange?: (newDate: DateRange | undefined) => void;
	locale?: Locale;
	disabled?: boolean;
}

export function DatePickerWithRange({
	className,
	from,
	to,
	onDateChange,
	locale,
	disabled,
}: DatePickerWithRangeProps) {
	const [date, setDate] = React.useState<DateRange | undefined>({
		from: from,
		to: to,
	});

	const handleDateSelect = (newDate: DateRange | undefined) => {
		setDate(newDate);

		if (onDateChange && newDate && newDate.from && newDate.to) {
			onDateChange(newDate);
		}
	};

	return (
		<div className={cn("grid gap-2", className)}>
			<Popover>
				<PopoverTrigger asChild>
					<Button
						id="date"
						variant={"outline"}
						className={cn(
							"w-full justify-start text-left font-normal bg-white",
							!date && "text-muted-foreground",
						)}
						disabled={disabled}
					>
						<CalendarIcon className="w-4 h-4 mr-2" />
						{date?.from ? (
							date.to ? (
								<>
									{format(date.from, "LLL dd, y")} -{" "}
									{format(date.to, "LLL dd, y")}
								</>
							) : (
								format(date.from, "LLL dd, y")
							)
						) : (
							<span>Pick a date</span>
						)}
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-0" align="start">
					<Calendar
						initialFocus
						mode="range"
						defaultMonth={date?.from}
						selected={date}
						onSelect={handleDateSelect}
						numberOfMonths={2}
						locale={locale as Locale & string}
					/>
				</PopoverContent>
			</Popover>
		</div>
	);
}
