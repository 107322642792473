/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import { Animate, Card, Form, Row } from "components/lib";

export function ForgotPassword(props) {
	return (
		<Animate type="pop">
			<Row title={props.t("auth.signin.forgotpassword.title")}>
				<Card restrictWidth center transparent>
					<p className="mb-5">
						{props.t("auth.signin.forgotpassword.description")}
					</p>

					<Form
						inputs={{
							email: {
								label: props.t("auth.signin.forgotpassword.form.email.label"),
								type: "email",
								required: true,
							},
						}}
						url="/api/auth/password/reset/request"
						method="POST"
						buttonText={props.t("auth.signin.forgotpassword.form.button")}
					/>
				</Card>
			</Row>
		</Animate>
	);
}
