/***
 *
 *   CLASS HELPER
 *   Helper function for applying SCSS or Tailwind classes
 *
 *   PARAMS
 *   debug: log the output to the console (boolean, optional)
 *   props: props object (object, required)
 *   styles: SCSS or tailwind styles (required)
 *
 **********/

export function ClassHelper(styles, props, debug = false) {
	let str = "";

	if (typeof styles === "object") {
		if (styles.base) str += styles.base; // always apply base styles

		if (Object.keys(props).length > 0) {
			for (const prop of Object.keys(props)) {
				if (prop === "className" && props[prop]) {
					// always apply className and base
					str = str.trim();
					str += ` ${props[prop]}`;
				} else if (
					props[prop] &&
					Object.prototype.hasOwnProperty.call(styles, prop)
				) {
					// eslint-disable-next-line no-console
					if (debug) console.log("styles", prop, props[prop], styles[prop]);

					str = str.trim();
					str += ` ${styles[prop]}`;
				}
			}
		}
	}

	// eslint-disable-next-line no-console
	if (debug) console.log("final.class", str.trim());

	return str.trim();
}
