const Style = {
	switch: "relative block w-full mb-6",
	label: "absolute left-20 top-1 normal-case",
	track:
		"absolute top-0 left-0 w-14 h-8 cursor-pointer overlow-hidden rounded-full ease-in-out duration-300",
	trackOn: "bg-emerald-500",
	trackOff: "bg-slate-300",
	handle: `absolute top-1/2 text-white h-6 w-6 text-sm text-enter font-semibold pointer-events-none 
    bg-white rounded-full -translate-y-1/2 ease-in-out duration-300`,

	handleOff: "left-1",
	handleOn: "left-7",
};

export default Style;
