import type{ ColumnDef } from "@tanstack/react-table";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import { Building2, MapPin, CheckCircle, ArrowUpDown, HelpCircle, Loader2 } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "components/ui/tooltip";
import type { PostInteraction } from "../../types";
import { TooltipCell, TooltipHeader } from "components/tooltip/table-tooltips";

interface ColumnConfig {
  syncingProfiles: Set<string>;
  onGenerateIcebreaker: (linkedinId: string) => Promise<void>;
}

export const createColumns = ({ syncingProfiles, onGenerateIcebreaker }: ColumnConfig): ColumnDef<PostInteraction>[] => [
  {
    id: "fullName",
    accessorFn: (row) => `${row.profile.firstName} ${row.profile.lastName}`,
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <Button
          variant={isSorted ? "secondary" : "ghost"}
          onClick={() => column.toggleSorting(isSorted === "asc")}
          className="hover:bg-muted"
        >
          Full Name
          <ArrowUpDown className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`} />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="flex items-center gap-2">
        {row.original.profile.profileImageUrl && (
          <img
            src={row.original.profile.profileImageUrl}
            alt={`${row.original.profile.firstName} ${row.original.profile.lastName}`}
            className="w-8 h-8 rounded-full"
          />
        )}
        <span className="font-medium">
          {row.original.profile.firstName} {row.original.profile.lastName}
        </span>
      </div>
    ),
  },
  {
    id: "title",
    accessorFn: (row) => row.profile.title,
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <Button
          variant={isSorted ? "secondary" : "ghost"}
          onClick={() => column.toggleSorting(isSorted === "asc")}
          className="hover:bg-muted"
        >
          Title
          <ArrowUpDown className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`} />
        </Button>
      );
    },
    cell: ({ row }) => (
      <div className="flex items-center gap-2">
        <span>{row.original.profile.title}</span>
      </div>
    ),
  },
  {
    id: "location",
    accessorFn: (row) => row.profile.locationName,
    header: "Location",
    cell: ({ row }) => (
      <div className="flex items-center gap-2">
        <MapPin className="h-4 w-4 text-muted-foreground" />
        <span>{row.original.profile.locationName}</span>
      </div>
    ),
  },
  {
    id: "company",
    accessorFn: (row) => row.profile.company,
    header: "Company",
    cell: ({ row }) => (
      <div className="flex items-center gap-2">
        <Building2 className="h-4 w-4 text-muted-foreground" />
        <span>{row.original.profile.company}</span>
      </div>
    ),
  },
  {
    id: "engagementCount",
    accessorFn: (row) => row.interactions?.count,
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={isSorted ? "secondary" : "ghost"}
              onClick={() => column.toggleSorting(isSorted === "asc")}
              className="hover:bg-muted flex items-center gap-1 mx-auto"
            >
              Engagement Count
              <HelpCircle className="h-4 w-4 text-muted-foreground" />
              <ArrowUpDown
                className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="max-w-xs">
              Total number of interactions this lead has had with your LinkedIn content
            </p>
          </TooltipContent>
        </Tooltip>
      );
    },
    cell: ({ row }) => {
      const count = row.original.interactions?.count;
      return count ? (
        <TooltipCell
          value={count}
          description="Total number of interactions this lead has had with your LinkedIn content"
        />
      ) : (
        <span className="text-muted-foreground">0</span>
      );
    },
  },
  {
    id: "icpScore",
    accessorFn: (row) => row.profile.lead?.icpScore,
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={isSorted ? "secondary" : "ghost"}
              onClick={() => column.toggleSorting(isSorted === "asc")}
              className="hover:bg-muted flex items-center gap-1 mx-auto"
            >
              Lead Score
              <HelpCircle className="h-4 w-4 text-muted-foreground" />
              <ArrowUpDown
                className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="max-w-xs">
              Match score with your Ideal Customer Profile
            </p>
          </TooltipContent>
        </Tooltip>
      );
    },
    cell: ({ row }) => {
      const score = row.original.profile.lead?.icpScore;
      return (
        <TooltipCell
          value={score || 0}
          description="Match score with your Ideal Customer Profile"
        />
      );
    },
  },
  {
    id: "icebreaker",
    accessorFn: (row) => row.profile.lead?.icebreaker,
    header: ({ column }) => {
      const isSorted = column.getIsSorted();
      return (
        <TooltipHeader
          title="Icebreaker"
          description="AI-generated conversation starter based on profile"
        />
      );
    },
    cell: ({ row }) => {
      const icebreaker = row.original.profile.lead?.icebreaker;
      const isSyncing = syncingProfiles.has(row.original.profile.linkedinId);

      return (
        <div className="max-w-md">
          {icebreaker ? (
            <TooltipCell
              value={
                <p className="text-sm line-clamp-2 hover:text-primary transition-colors">
                  {icebreaker}
                </p>
              }
              description={icebreaker}
            />
          ) : (
            <Button
              variant="outline"
              size="sm"
              onClick={(e) => {
                e.stopPropagation();
                onGenerateIcebreaker(row.original.profile.linkedinId);
              }}
              disabled={isSyncing}
              className="w-full"
            >
              {isSyncing ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  Generating...
                </>
              ) : (
                <>Generate Icebreaker</>
              )}
            </Button>
          )}
        </div>
      );
    }
  }
]; 