const Style = {
	creditCard: `relative block w-64 h-36 px-5 py-4 mx-auto mt-2 mb-8 text-white rounded-md 
  bg-gradient-to-b from-blue-400 to-blue-500 drop-shadow-md`,

	brand: "font-semibold uppercase mb-5",
	number: "text-xl font-semibold mb-3",
	col: "float-left mr-4",
	title: "font-semibold text-xs",
	value: "text-xs",
};

export default Style;
