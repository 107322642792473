/***
 *
 *   RESET PASSWORD
 *   User can set a new password using the token
 *
 **********/

import {
	Animate,
	AuthContext,
	Card,
	Form,
	Row,
	useLocation,
	useNavigate,
} from "components/lib";
import { useContext } from "react";

export function ResetPassword(props) {
	// context
	const navigate = useNavigate();
	const location = useLocation();
	const context = useContext(AuthContext);

	// check for token
	const qs = location.search;
	const token = qs.slice(Math.max(0, qs.indexOf("?token=") + 7));

	return (
		<Animate type="pop">
			<Row title={props.t("auth.signin.resetpassword.title")}>
				<Card restrictWidth center transparent>
					<Form
						inputs={{
							jwt: {
								type: "hidden",
								value: token,
							},
							email: {
								label: props.t("auth.signin.resetpassword.form.email.label"),
								type: "email",
								required: true,
							},
							password: {
								label: props.t("auth.signin.resetpassword.form.password.label"),
								type: "password",
								required: true,
								complexPassword: true,
							},
						}}
						url="/api/auth/password/reset"
						method="POST"
						buttonText={props.t("auth.signin.resetpassword.form.button")}
						callback={(res) => {
							res.data["2fa_required"]
								? navigate(`/signin/otp?token=${res.data.token}`)
								: navigate(context.signin(res));
						}}
					/>
				</Card>
			</Row>
		</Animate>
	);
}
