import type { ColumnDef, SortingState } from "@tanstack/react-table";
import {
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import { Button } from "components/ui/button";
import {
	Pagination,
	PaginationContent,
	PaginationItem,
	PaginationLink,
	PaginationNext,
	PaginationPrevious,
} from "components/ui/pagination";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { format } from "date-fns";
import { ArrowUpDown, Eye, Loader2, RefreshCcw } from "lucide-react";
import { useState } from "react";
import type { Post } from "../types";
import { TablePagination } from "components/table-pagination";
import { Tab } from "@nextui-org/react";

interface LeadsPerPostTableProps {
	posts: Post[];
	onPostClick: (post: Post) => void;
	onSyncLeads: (postId: string) => Promise<void>;
	currentPage: number;
	totalPages: number;
	onPageChange: (page: number) => void;
	syncingPosts: Set<string>;
}

export const LeadsPerPostTable = ({
	posts,
	onPostClick,
	onSyncLeads,
	currentPage,
	totalPages,
	onPageChange,
	syncingPosts,
}: LeadsPerPostTableProps) => {
	const columns: ColumnDef<Post>[] = [
		{
			accessorKey: "content",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
						variant={isSorted ? "secondary" : "ghost"}
						onClick={() => column.toggleSorting(isSorted === "asc")}
						className="hover:bg-muted"
					>
						Post Content
						<ArrowUpDown
							className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
						/>
					</Button>
				);
			},
			cell: ({ row }) => {
				const content = row.getValue("content") as string;
				return content.length > 100
					? `${content.substring(0, 100)}...`
					: content;
			},
		},
		{
			accessorKey: "creation_time",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
						variant={isSorted ? "secondary" : "ghost"}
						onClick={() => column.toggleSorting(isSorted === "asc")}
						className="hover:bg-muted"
					>
						Date
						<ArrowUpDown
							className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
						/>
					</Button>
				);
			},
			cell: ({ row }) =>
				format(new Date(row.getValue("creation_time")), "MMM d, yyyy HH:mm"),
		},
		{
			accessorKey: "metrics.likes",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
						variant={isSorted ? "secondary" : "ghost"}
						onClick={() => column.toggleSorting(isSorted === "asc")}
						className="hover:bg-muted"
					>
						Likes
						<ArrowUpDown
							className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
						/>
					</Button>
				);
			},
		},
		{
			accessorKey: "metrics.comments",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
						variant={isSorted ? "secondary" : "ghost"}
						onClick={() => column.toggleSorting(isSorted === "asc")}
						className="hover:bg-muted"
					>
						Comments
						<ArrowUpDown
							className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
						/>
					</Button>
				);
			},
		},
		{
			accessorKey: "metrics.shares",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
						variant={isSorted ? "secondary" : "ghost"}
						onClick={() => column.toggleSorting(isSorted === "asc")}
						className="hover:bg-muted"
					>
						Shares
						<ArrowUpDown
							className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
						/>
					</Button>
				);
			},
		},
		{
			accessorKey: "qualifiedLeads",
			header: ({ column }) => {
				const isSorted = column.getIsSorted();
				return (
					<Button
						variant={isSorted ? "secondary" : "ghost"}
						onClick={() => column.toggleSorting(isSorted === "asc")}
						className="hover:bg-muted"
					>
						Qualified Leads
						<ArrowUpDown
							className={`ml-2 h-4 w-4 ${isSorted ? "text-primary" : "text-muted-foreground"}`}
						/>
					</Button>
				);
			},
		},
		{
			id: "actions",
			cell: ({ row }) => {
				const post = row.original;
				const isSyncing = syncingPosts.has(post.linkedin_id);
				return (
					<div className="flex flex-row items-center gap-2 justify-start">
						{post.last_lead_sync ? (
							<>
								<Button
									variant="outline"
									size="sm"
									onClick={(e) => {
										e.stopPropagation();
										onPostClick(post);
									}}
								>
									<Eye className="mr-2 h-4 w-4" />
									View Leads
								</Button>
								<Button
									variant="outline"
									size="sm"
									disabled={isSyncing}
									onClick={(e) => handleSyncClick(e, post.linkedin_id)}
								>
									{isSyncing ? (
										<>
											<Loader2 className="mr-2 h-4 w-4 animate-spin" />
											Syncing...
										</>
									) : (
										<>
											<RefreshCcw className="mr-2 h-4 w-4" />
											Sync Again
										</>
									)}
								</Button>
							</>
						) : (
							<Button
								variant="outline"
								size="sm"
								disabled={isSyncing}
								onClick={(e) => handleSyncClick(e, post.linkedin_id)}
							>
								{isSyncing ? (
									<>
										<Loader2 className="mr-2 h-4 w-4 animate-spin" />
										Syncing...
									</>
								) : (
									<>
										<RefreshCcw className="mr-2 h-4 w-4" />
										Sync Leads
									</>
								)}
							</Button>
						)}
					</div>
				);
			},
		},
	];

	const [sorting, setSorting] = useState<SortingState>([
		{
			id: "creation_time",
			desc: true,
		},
	]);

	const table = useReactTable({
		data: posts,
		columns,
		getCoreRowModel: getCoreRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		state: {
			sorting,
		},
	});

	const handleSyncClick = async (e: React.MouseEvent, postId: string) => {
		e.stopPropagation();
		await onSyncLeads(postId);
	};

	const formatDate = (dateString: string) => {
		return format(new Date(dateString), "MMM d, yyyy HH:mm");
	};

	return (
		<div className="space-y-4">
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<TableHead key={header.id}>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
								</TableHead>
							))}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{table.getRowModel().rows.map((row) => (
						<TableRow
							key={row.id}
							className="hover:bg-muted/50 cursor-pointer"
							onClick={() =>
								!syncingPosts.has(row.original.linkedin_id) &&
								onPostClick(row.original)
							}
						>
							{row.getVisibleCells().map((cell) => (
								<TableCell key={cell.id}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>

			<TablePagination
				currentPage={currentPage}
				totalPages={totalPages}
				onPageChange={onPageChange}
			/>
		</div>
	);
}; 