import { Stats } from "views/stats/Stats";

const routes = [
	{
		path: "/stats/:userId?",
		view: Stats,
		layout: "app",
		permission: "user",
		title: "My Statistics",
	},
];

export default routes;
