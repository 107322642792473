const Style = {
	subnav: `relative pt-5 px-5 bg-white -ml-5 -mr-6 -mt-5 mb-5 border-solid border-slate-100 border-t 
    dark:bg-slate-800 dark:border-0`,

	item: "inline-block text-slate-600 mr-5 pb-5 dark:text-slate-200",
	item_active:
		"inline-block text-slate-600 mr-5 pb-5 border-solid border-sky-500 border-b-2 dark:text-slate-200",
};

export default Style;
