import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Textarea } from "components/ui/textarea";
import { cn } from "lib/utils";
import { ChevronRight, Trash2 } from "lucide-react";
import { useState } from "react";
import type { VoiceConfig } from "types/customVoice";

type JsonPrimitive = string | number | boolean | null;
type JsonArray = JsonValue[];
type JsonObject = {
	[key: string]: JsonValue;
} & Partial<VoiceConfig>;
type JsonValue = JsonPrimitive | JsonArray | JsonObject;

interface ObjectEditorProps {
	data: VoiceConfig;
	onSave: (data: VoiceConfig) => void;
}

export function ObjectEditor({ data: initialData, onSave }: ObjectEditorProps) {
	const [data, setData] = useState<VoiceConfig>(initialData);
	const [expandedSections, setExpandedSections] = useState<string[]>([]);

	const toggleSection = (path: string) => {
		setExpandedSections((prev) =>
			prev.includes(path) ? prev.filter((p) => p !== path) : [...prev, path],
		);
	};

	const updateValue = (path: string, value: JsonValue) => {
		setData((prevData) => {
			const newData = { ...prevData };
			let current = newData as unknown as JsonObject;
			const keys = path.split(".");
			const lastKey = keys[keys.length - 1];

			for (const key of keys.slice(0, -1)) {
				if (!(key in current)) {
					current[key] = {};
				}
				current = current[key] as JsonObject;
			}

			current[lastKey] = value;
			return newData;
		});
	};

	const removeArrayItem = (path: string, index: number) => {
		setData((prevData) => {
			const newData = { ...prevData };
			const keys = path.split(".");
			let current = newData as unknown as JsonObject;

			for (let i = 0; i < keys.length - 1; i++) {
				if (keys[i] === "text") continue;
				current = current[keys[i] as keyof typeof current] as JsonObject;
			}

			const lastKey = keys[keys.length - 1];
			const array = current[lastKey] as JsonArray;

			if (Array.isArray(array)) {
				array.splice(index, 1);
			}

			return newData;
		});
	};

	const renderValue = (value: JsonValue, path: string) => {
		if (typeof value === "string") {
			const isLinkedInExample = path.includes("linkedinPostExamples");
			if (value.length > 100 || isLinkedInExample) {
				if (isLinkedInExample) {
					return (
						<details className="mt-1">
							<summary className="cursor-pointer hover:bg-slate-50/50 p-2 rounded-md">
								View/Edit Post
							</summary>
							<div className="relative mt-2">
								<Textarea
									value={value}
									onChange={(e) => updateValue(path, e.target.value)}
									className="min-h-[200px] w-full pr-8"
								/>
								<Button
									variant="ghost"
									size="icon"
									className="absolute right-2 top-2 h-6 w-6 text-muted-foreground hover:text-muted-foreground/80"
									onClick={(e) => {
										e.stopPropagation();
										// Get the correct path for LinkedIn posts
										const pathParts = path.split(".");
										const index = Number(
											pathParts[pathParts.indexOf("linkedinPostExamples") + 1],
										);
										if (!Number.isNaN(index)) {
											removeArrayItem("linkedinPostExamples", index);
										}
									}}
								>
									<Trash2 className="h-4 w-4" />
									<span className="sr-only">Delete post</span>
								</Button>
							</div>
						</details>
					);
				}
				return (
					<div className="relative mt-1">
						<Textarea
							value={value}
							onChange={(e) => updateValue(path, e.target.value)}
							className="w-full pr-8"
						/>
						<Button
							variant="ghost"
							size="icon"
							className="absolute right-2 top-2 h-6 w-6 text-muted-foreground hover:text-muted-foreground/80"
							onClick={(e) => {
								e.stopPropagation();
								const pathParts = path.split(".");
								const index = Number(pathParts.pop());
								const parentPath = pathParts.join(".");
								if (!Number.isNaN(index)) {
									removeArrayItem(parentPath, index);
								}
							}}
						>
							<Trash2 className="h-4 w-4" />
							<span className="sr-only">Delete item</span>
						</Button>
					</div>
				);
			}
			return (
				<div className="relative mt-1">
					<Input
						type="text"
						value={value}
						onChange={(e) => updateValue(path, e.target.value)}
						className="w-full pr-8"
					/>
					<Button
						variant="ghost"
						size="icon"
						className="absolute right-2 top-1/2 -translate-y-1/2 h-6 w-6 text-muted-foreground hover:text-muted-foreground/80"
						onClick={(e) => {
							e.stopPropagation();
							const pathParts = path.split(".");
							const index = Number(pathParts.pop());
							const parentPath = pathParts.join(".");
							if (!Number.isNaN(index)) {
								removeArrayItem(parentPath, index);
							}
						}}
					>
						<Trash2 className="h-4 w-4" />
						<span className="sr-only">Delete item</span>
					</Button>
				</div>
			);
		}
		if (typeof value === "number") {
			return (
				<Input
					type="number"
					value={value}
					onChange={(e) => updateValue(path, Number.parseFloat(e.target.value))}
					className="w-full mt-1"
				/>
			);
		}
		if (typeof value === "boolean") {
			return (
				<Input
					type="checkbox"
					checked={value}
					onChange={(e) => updateValue(path, e.target.checked)}
					className="mt-1"
				/>
			);
		}
		if (Array.isArray(value)) {
			return (
				<div className="space-y-2">
					{value.map((item, index) => (
						<div
							key={`${path}-${Math.random()}`}
							className="flex items-center gap-2"
						>
							<div className="flex-1">
								{renderValue(item, `${path}.${index}`)}
							</div>
						</div>
					))}
					{!path.includes("linkedinPostExamples") && (
						<Button
							variant="outline"
							size="sm"
							onClick={() => {
								const newValue = [...value, ""];
								updateValue(path, newValue);
							}}
						>
							Add Item
						</Button>
					)}
				</div>
			);
		}
		if (typeof value === "object" && value !== null) {
			return renderObject(value, path);
		}
		return null;
	};

	const renderObject = (obj: JsonObject | VoiceConfig, basePath = "") => {
		return (
			<div className="space-y-4 max-w-full">
				{Object.entries(obj).map(([key, value]) => {
					const path = basePath ? `${basePath}.${key}` : key;
					const isExpandable =
						typeof value === "object" &&
						value !== null &&
						!Array.isArray(value);
					const isExpanded = expandedSections.includes(path);

					return (
						<div key={key} className="space-y-1">
							<div
								className="flex items-center cursor-pointer hover:bg-slate-50/50 rounded-md p-2 transition-colors sticky top-0 z-10"
								onClick={() => isExpandable && toggleSection(path)}
								onKeyDown={(e) => {
									if (e.key === "Enter" || e.key === " ") {
										isExpandable && toggleSection(path);
									}
								}}
								role="button"
								tabIndex={0}
							>
								{isExpandable && (
									<div
										className="p-1 transition-transform duration-200"
										style={{
											transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
										}}
									>
										<ChevronRight className="h-4 w-4" />
									</div>
								)}
								<Label className="text-sm font-medium capitalize">
									{key.replace(/([A-Z])/g, " $1").toLowerCase()}
								</Label>
							</div>
							<div
								className={cn(
									"pl-6 overflow-hidden transition-all duration-200",
									isExpanded
										? "max-h-[300px] opacity-100 overflow-y-auto pr-2"
										: "max-h-0 opacity-0",
									!isExpandable &&
										"max-h-[300px] opacity-100 overflow-y-auto pr-2",
								)}
							>
								{renderValue(value, path)}
							</div>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<div className="space-y-6">
			<div className="max-w-full max-h-[60vh] overflow-y-auto pr-4">
				{renderObject(data)}
			</div>
		</div>
	);
}
