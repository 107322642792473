/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useAPI } from "components/lib";
import { Button } from "components/ui/button";
import { Card, CardContent, CardFooter, CardHeader } from "components/ui/card";
import { Dialog, DialogTrigger } from "components/ui/dialog";
import { Progress } from "components/ui/progress";
import { Skeleton } from "components/ui/skeleton";
import { endOfWeek, format, startOfWeek } from "date-fns";
import { useEffect, useState } from "react";

import type { GoalWithMetrics } from "../types";
import {
	calculateCurrentWeekObjective,
	calculateDueTime,
	calculateWeekNumber,
	calculateWeightedCompletion,
} from "../utils";
import { ManageUsersDialog } from "./manage-users-dialog";

export const GoalCard = ({
	goalWithMetrics,
}: { goalWithMetrics: GoalWithMetrics }) => {
	const currentDate = new Date();
	const goalStartDate = new Date(goalWithMetrics.start_date);
	const goalEndDate = new Date(goalWithMetrics.end_date);
	const isGoalFinished = currentDate > goalEndDate;

	let currentStartOfWeek: string;
	let currentEndOfWeek: string;

	if (currentDate < goalEndDate) {
		// If current date is before the goal end date, use the current week
		currentStartOfWeek = format(
			startOfWeek(currentDate, { weekStartsOn: 1 }),
			"yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
		);
		currentEndOfWeek = format(
			endOfWeek(currentDate, { weekStartsOn: 1 }),
			"yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
		);
	} else {
		// If current date is after or equal to the goal end date, use the last week of the goal
		currentStartOfWeek = format(
			startOfWeek(goalEndDate, { weekStartsOn: 1 }),
			"yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
		);
		currentEndOfWeek = format(
			endOfWeek(goalEndDate, { weekStartsOn: 1 }),
			"yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
		);
	}

	const totalDuration = goalEndDate.getTime() - goalStartDate.getTime();
	const elapsedTime = currentDate.getTime() - goalStartDate.getTime();
	const timeProgress = Math.min((elapsedTime / totalDuration) * 100, 100);

	const remainingTime = goalEndDate.getTime() - currentDate.getTime();
	const days = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
	const timeLeft = isGoalFinished ? "Finished" : `${days} days left`;

	const [completion, setCompletion] = useState(0);
	const [loading, setLoading] = useState(true);

	const metricTypes = [];
	if (goalWithMetrics.impressions) metricTypes.push("impressions");
	if (goalWithMetrics.likes) metricTypes.push("likes");
	if (goalWithMetrics.posts) metricTypes.push("posts");
	if (goalWithMetrics.comments) metricTypes.push("comments");
	// if (shares) metricTypes.push("shares");

	const metricsQuery = metricTypes.join(",");

	const { data: metrics, loading: metricsLoading } = useAPI(
		`/api/goal/get-current-users-progress?goal_id=${goalWithMetrics.id}&start_date=${currentStartOfWeek}&end_date=${currentEndOfWeek}&metrics=${metricsQuery}`,
	);

	const due = calculateDueTime(goalWithMetrics.end_date);

	useEffect(() => {
		if (!metrics) return;

		const weekNumber = calculateWeekNumber(goalWithMetrics.start_date);
		const calculatedObjectives = {
			impressionsObjective: goalWithMetrics.impressions
				? calculateCurrentWeekObjective(goalWithMetrics.impressions, weekNumber)
				: undefined,
			likesObjective: goalWithMetrics.likes
				? calculateCurrentWeekObjective(goalWithMetrics.likes, weekNumber)
				: undefined,
			commentsObjective: goalWithMetrics.comments
				? calculateCurrentWeekObjective(goalWithMetrics.comments, weekNumber)
				: undefined,
			postsObjective: goalWithMetrics.posts
				? calculateCurrentWeekObjective(goalWithMetrics.posts, weekNumber)
				: undefined,
		};

		const calculatedCompletion = calculateWeightedCompletion(
			metrics,
			calculatedObjectives,
		);

		setCompletion(calculatedCompletion);
		setLoading(false);
	}, [metrics, goalWithMetrics]);

	return (
		<Card
			className={`w-full ${isGoalFinished ? "border-2 border-yellow-500" : ""}`}
		>
			<CardHeader>
				<div className="flex justify-between items-center">
					<div>
						<h3 className="m-0 text-lg font-semibold">
							{goalWithMetrics.title}
						</h3>
						<p className="text-sm text-muted-foreground">
							{goalWithMetrics.description}
						</p>
						{isGoalFinished && (
							<span className="inline-block mt-2 px-2 py-1 text-xs font-semibold text-yellow-800 bg-yellow-200 rounded-full">
								Goal Finished
							</span>
						)}
					</div>
					{loading || metricsLoading ? (
						<Skeleton className="w-24 h-8 rounded-full" />
					) : (
						<div className="px-3 py-1 text-xs font-medium rounded-full bg-primary text-primary-foreground">
							{isGoalFinished
								? `Last Week's Completion: ${completion}%`
								: `${completion}% Complete`}
						</div>
					)}
				</div>
			</CardHeader>
			<CardContent className="grid gap-2">
				<div className="flex justify-between items-center text-sm text-muted-foreground">
					<span>{isGoalFinished ? "Ended" : due}</span>
					<span>{Math.round(timeProgress)}% of time elapsed</span>
				</div>
				<Progress
					value={timeProgress}
					className="w-full"
					bgColor={isGoalFinished ? "bg-yellow-500" : "bg-primary"}
				/>
			</CardContent>
			<CardFooter className="flex justify-between">
				{!isGoalFinished && (
					<Dialog>
						<DialogTrigger asChild>
							<Button variant="outline">Manage Users</Button>
						</DialogTrigger>
						<ManageUsersDialog
							goal={{
								organization_id: goalWithMetrics.organization_id,
								title: goalWithMetrics.title,
								description: goalWithMetrics.description,
								start_date: goalWithMetrics.start_date,
								end_date: goalWithMetrics.end_date,
								id: goalWithMetrics.id,
							}}
						/>
					</Dialog>
				)}
			</CardFooter>
		</Card>
	);
};
