/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
	keepPreviousData,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { AuthContext } from "components/lib";
import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { interactWithExtension } from "lib/utils";
import { RefreshCcw } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { useContext, useEffect, useState } from "react";
import ExtensionMessage from "views/dashboard/_components/extension-message";

import { appConfig } from "../../../config";
import type { GetMorePostsFetchType, LinkedinPostType } from "../../../types";
import { LinkedinPost } from "../../linkedin-post";
import {
	WordPressCredentials,
	type WordpressCredentialsState,
} from "./wordpress-credentials";

const fetchLinkedinPostsFromDB = async ({
	userId,
	page,
	count,
}: {
	userId: string;
	page: number;
	count: number;
}) => {
	try {
		const response = await fetch(
			`${appConfig.API_MAIN_URL}/api/linkedin/posts?userId=${userId}&count=${count}&page=${page}`,
		);

		if (!response.ok) {
			throw new Error("Error fetching linkedin posts");
		}

		const posts: GetMorePostsFetchType = await response.json();
		return posts;
	} catch (error) {
		throw new Error("Error fetching linkedin posts");
	}
};

async function getLinkedinPostData(link: string): Promise<LinkedinPostType> {
	const response = await fetch(
		`${appConfig.API_MAIN_URL}/api/linkedin/scrape-single-post?link=${link}`,
		{
			method: "GET",
			headers: {
				"Content-Type": "text/html",
			},
		},
	);

	if (!response.ok) {
		throw new Error("Invalid linkedIn post URL");
	}

	const json = await response.json();
	return json as LinkedinPostType;
}

type Props = {
	t: (key: string) => string;
};

export const WordpressBlogFromLinkedinPost: React.FC<Props> = ({ t }) => {
	const auth: any = useContext(AuthContext);
	const posthog = usePostHog();
	const [page, setPage] = useState(0);
	const [totalPosts, setTotalPosts] = useState<LinkedinPostType[]>([]);
	const userId = auth.user.id;
	const organizationId = auth.user.organization_id;

	const queryClient = useQueryClient();

	const count = 5;

	const { data, isError, isFetching, isPlaceholderData } = useQuery({
		queryKey: ["linkedinPosts", page],
		queryFn: () => fetchLinkedinPostsFromDB({ userId, page, count }),
		placeholderData: keepPreviousData,
	});

	useEffect(() => {
		if (data?.posts) {
			setTotalPosts(() => data.posts);
		}
	}, [data?.posts]);

	const [wordpressConfig, setWordpressConfig] =
		useState<WordpressCredentialsState | null>(null);

	const [postLink, setPostLink] = useState<string>("");
	const [specificPost, setSpecificPost] = useState<LinkedinPostType | null>(
		null,
	);
	const [fetchError, setFetchError] = useState<string | null>(null);

	useEffect(() => {
		if (posthog) {
			posthog.capture("blog_from_linkedin_post_started");
		}
	}, [posthog]);

	const handleFetchSpecificPost = async () => {
		try {
			const postData = await getLinkedinPostData(postLink);
			setSpecificPost(postData);
			setFetchError(null);
		} catch (error: any) {
			console.error("Error fetching specific LinkedIn post", error);
			setFetchError(error.message);
		}
	};

	const refreshLinkedinData = async (): Promise<boolean> => {
		return new Promise((resolve, reject) => {
			try {
				interactWithExtension<{ status: "ok"; error?: string }>(
					"onDemandLinkedinDataSync",
					(response) => {
						if (response?.status === "ok") {
							resolve(true);
						} else {
							reject(`Error refreshing LinkedIn Data: ${response?.error}`);
						}
					},
				);
			} catch (error) {
				reject(error);
			}
		});
	};

	const [isRefreshing, setIsRefreshing] = useState(false);

	const handleRefreshLinkedinData = async () => {
		try {
			setIsRefreshing(true);
			setPage(0);
			await refreshLinkedinData();
			await queryClient.invalidateQueries({
				queryKey: ["linkedinPosts", page],
			});
			setIsRefreshing(false);
		} catch (error) {
			console.error("Failed to refresh LinkedIn data:", error);
			setIsRefreshing(false);
			// Handle error (e.g., show an error message to the user)
		}
	};

	return (
		<div className="flex-1 mx-auto space-y-6 max-w-6xl">
			<ExtensionMessage text="Install the extension to access all the features available" />

			<Card>
				<CardHeader>
					<CardTitle>WordPress Configuration</CardTitle>
				</CardHeader>
				<CardContent>
					<WordPressCredentials
						userId={userId}
						organizationId={organizationId}
						wordpressConfig={wordpressConfig}
						setWordpressConfig={setWordpressConfig}
					/>
				</CardContent>
			</Card>
			<Card>
				<CardHeader>
					<CardTitle>Create Blog Post from Specific LinkedIn Post</CardTitle>
				</CardHeader>
				<CardContent>
					<div className="space-y-4">
						<div className="flex items-center space-x-2">
							<div className="flex-grow">
								<Label htmlFor="postLink" className="sr-only">
									LinkedIn Post Link:
								</Label>
								<Input
									id="postLink"
									value={postLink}
									onChange={(e) => setPostLink(e.target.value)}
									placeholder="Enter LinkedIn post URL"
								/>
							</div>
							<Button
								onClick={() => void handleFetchSpecificPost()}
								disabled={!postLink}
							>
								Fetch Post
							</Button>
						</div>
						{fetchError && <div className="text-red-500">{fetchError}</div>}
						{specificPost && (
							<LinkedinPost
								post={specificPost}
								wordpressConfig={wordpressConfig}
								wordpressUser={
									wordpressConfig?.user
										? {
												id: wordpressConfig.user.id,
												username: wordpressConfig.user.username,
												email: wordpressConfig.user.email ?? undefined,
												name: wordpressConfig.user.name,
											}
										: null
								}
								canCreate
								t={t}
								userId={userId}
								organizationId={organizationId}
							/>
						)}
					</div>
				</CardContent>
			</Card>

			<Card>
				<CardHeader>
					<CardTitle>
						Recent LinkedIn Posts
						<Button
							className="ml-5"
							onClick={() => void handleRefreshLinkedinData()}
							disabled={isRefreshing}
						>
							{isRefreshing ? (
								"Loading fresh LinkedIn data"
							) : (
								<RefreshCcw className="w-4 h-4" />
							)}
						</Button>
					</CardTitle>
				</CardHeader>
				<CardContent>
					{totalPosts.length > 0 ? (
						<div className="grid gap-6 xl:grid-cols-3 md:grid-cols-2">
							{totalPosts.map((post) => (
								<LinkedinPost
									key={`${post.linkedin_id}`}
									post={post}
									wordpressConfig={wordpressConfig}
									wordpressUser={
										wordpressConfig?.user
											? {
													id: wordpressConfig.user.id,
													username: wordpressConfig.user.username,
													email: wordpressConfig.user.email ?? undefined,
													name: wordpressConfig.user.name,
												}
											: null
									}
									canCreate
									t={t}
									userId={userId}
									organizationId={organizationId}
								/>
							))}
						</div>
					) : (
						<p>No posts available.</p>
					)}

					{isFetching || isRefreshing ? (
						<div className="flex justify-center mt-4">
							<Button variant="outline" disabled className="animate-pulse">
								Loading...
							</Button>
						</div>
					) : (
						<div className="flex justify-center mt-4">
							<Button
								variant="outline"
								onClick={() => {
									if (!isPlaceholderData && data?.hasMore) {
										setPage((old) => old + 1);
									}
								}}
								disabled={isPlaceholderData || !data?.hasMore}
							>
								Show more
							</Button>
						</div>
					)}

					{isError && (
						<div className="mt-4 text-center text-red-500">
							Error fetching posts, try again!
						</div>
					)}
				</CardContent>
			</Card>
		</div>
	);
};
