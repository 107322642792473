/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * v0 by Vercel.
 * @see https://v0.dev/t/fBUBBMKrRpC
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { DialogTrigger } from "@radix-ui/react-dialog";
import { ExternalLinkIcon } from "@radix-ui/react-icons";
import { Button, buttonVariants } from "components/ui/button";
import { Dialog, DialogContent } from "components/ui/dialog";
import { useState } from "react";

import type { LinkedinPostType } from "../types";
import { PostGenerator } from "./post-generation";

const TextTruncate = ({ text }: { text: string }) => {
	const [showFullText, setShowFullText] = useState(false);

	const toggleFullText = () => {
		setShowFullText(!showFullText);
	};

	const truncatedText = text.split("\n").slice(0, 3).join("\n") + "...";

	return (
		<div>
			<p className="text-ellipsis" style={{ whiteSpace: "pre-line" }}>
				{showFullText ? text : truncatedText}
			</p>
			{text.split("\n").length > 3 && (
				<span
					onClick={toggleFullText}
					style={{ color: "gray", cursor: "pointer" }}
				>
					{showFullText ? "View less" : "View more"}
				</span>
			)}
		</div>
	);
};

export function LinkedinPost({
	post,
	wordpressConfig,
	wordpressUser,
	t,
	userId,
	organizationId,
	canCreate = true,
}: {
	post: LinkedinPostType;
	wordpressConfig?: {
		username?: string;
		applicationPassword?: string;
		webUrl?: string;
	} | null;
	wordpressUser?: {
		id?: string;
		username?: string;
		email?: string;
		name?: string;
	} | null;
	t: (path: string) => string;
	userId: string;
	organizationId: string;
	canCreate?: boolean;
}) {
	return (
		<div className="flex flex-col justify-between my-5 h-full bg-white rounded-md border border-gray-200 shadow-md">
			<div>
				<div className="p-4">
					<div className="flex items-center space-x-3">
						<div>
							<div className="text-xs text-gray-500">
								{new Date(post.creation_time).toLocaleDateString()}
							</div>
						</div>
					</div>
					<div className="mt-3 text-sm text-black">
						<TextTruncate text={post.content} />
					</div>
				</div>
				<div className="flex justify-center mb-5">
					{post.image_id && (
						<div className="mt-4">
							<img
								alt={`${post.content.substring(0, 20)}...`}
								src={`https://media.licdn.com/dms/image/${post.image_id}`}
								className="rounded-md shadow-lg"
								style={{ objectFit: "cover" }}
								width="400"
								referrerPolicy="no-referrer"
							/>
						</div>
					)}
				</div>
			</div>
			<div>
				<div className="flex justify-between items-center p-3 px-4 text-sm text-gray-500 border-t">
					<div className="flex items-center">
						<EyeIcon className="w-5 h-5" />
						<span className="ml-1">{post.impressions ?? 0} impressions</span>
					</div>
					<div className="flex items-center">
						<ThumbsUpIcon className="w-5 h-5" />
						<span className="ml-1">{post.likes ?? 0} likes</span>
					</div>
					<div className="flex items-center">
						<span className="ml-1">{post.shares ?? 0} shares</span>
					</div>
					<div className="flex items-center">
						<span className="ml-1">{post.comments ?? 0} comments</span>
					</div>
				</div>
				<div className="px-3 py-2 text-sm text-gray-500 border-t">
					<div className="flex justify-between items-center">
						<a
							className={`${buttonVariants({ variant: "link" })}`}
							href={`https://www.linkedin.com/feed/update/urn:li:activity:${post.linkedin_id}`}
							target="_blank"
							rel="noreferrer"
						>
							<ExternalLinkIcon />
							<span>{t("content-creation.linkedin_post.see_in_linkedin")}</span>
						</a>
						<div className="max-h-[80vh] overflow-y-auto" />
						{canCreate && wordpressConfig && wordpressUser && (
							<Dialog>
								<DialogTrigger asChild>
									<Button>
										{t("content-creation.linkedin_post.start_blog_creation")} ✨
									</Button>
								</DialogTrigger>
								<DialogContent className="max-w-[100vw] h-[100vh] overflow-y-auto ">
									<PostGenerator
										post={post}
										t={t}
										wordpressConfig={wordpressConfig}
										wordpressUser={wordpressUser}
										userId={userId}
										organizationId={organizationId}
									/>
								</DialogContent>
							</Dialog>
						)}
						{!wordpressConfig &&
							canCreate &&
							"Please configure your Wordpress settings to create a blog post 👆"}
					</div>
				</div>
			</div>
		</div>
	);
}

function EyeIcon(props: any) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
			<circle cx="12" cy="12" r="3" />
		</svg>
	);
}

function ThumbsUpIcon(props: any) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<path d="M7 10v12" />
			<path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
		</svg>
	);
}
