import { useState } from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from "components/ui/tooltip";
import { format, subYears } from "date-fns";
import { Info } from "lucide-react";
import CardSkeleton from "./card-skeleton";

interface ConsistencyTrackerProps {
	yearlyStats: {
		date: string;
		post_count: number;
	}[];
	loading?: boolean;
}

export const ConsistencyTracker: React.FC<ConsistencyTrackerProps> = ({
	yearlyStats,
	loading,
}) => {
	const [selectedDay, setSelectedDay] = useState<string | null>(null);
	const [selectedCount, setSelectedCount] = useState<number | null>(null);

	const endDate = new Date();
	const startDate = subYears(endDate, 1);

	const maxCount = Math.max(...yearlyStats.map((d) => d.post_count));

	const getColor = (count: number) => {
		if (count === 0) return "color-empty";
		const intensity = Math.ceil((count / maxCount) * 4);
		return `color-scale-${intensity}`;
	};

	const handleMouseOver = (
		_event: React.MouseEvent<SVGRectElement>,
		value: ({ date: string } & { count?: number }) | undefined,
	) => {
		if (value) {
			setSelectedDay(value.date);
			setSelectedCount(value.count ?? 0);
		} else {
			setSelectedDay(null);
			setSelectedCount(null);
		}
	};

	if (loading) {
		return <CardSkeleton />;
	}

	return (
		<Card className="bg-white">
			<CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
				<CardTitle className="text-xl font-semibold">
					Post Consistency Tracker
				</CardTitle>
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger>
							<Info className="h-4 w-4 text-muted-foreground" />
						</TooltipTrigger>
						<TooltipContent>
							<p>
								This heatmap shows your posting activity over the last year.
							</p>
							<p>Darker colors indicate more posts on that day.</p>
						</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</CardHeader>
			<CardContent>
				<div className="space-y-1 mb-4">
					<div className="text-xs text-muted-foreground">
						Over last 365 days ({format(startDate, "MMM d, yyyy")} -{" "}
						{format(endDate, "MMM d, yyyy")})
					</div>
				</div>
				<CalendarHeatmap
					startDate={startDate}
					endDate={endDate}
					values={yearlyStats.map((stat) => ({
						date: stat.date,
						count: stat.post_count,
					}))}
					classForValue={(value: any) => getColor(value ? value.count : 0)}
					onMouseOver={handleMouseOver}
					showWeekdayLabels
					gutterSize={4}
				/>
				<div className="flex justify-between text-xs text-muted-foreground mt-2 min-h-[40px]">
					<div className="text-xs font-medium h-4">
						{selectedDay && (
							<span>
								{selectedCount} post{selectedCount !== 1 ? "s" : ""} on{" "}
								{format(new Date(selectedDay), "MMM dd, yyyy")}
							</span>
						)}
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default ConsistencyTracker;
