"use client";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import { AuthContext } from "components/lib";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Skeleton } from "components/ui/skeleton";
import { Textarea } from "components/ui/textarea";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { Calendar } from "lucide-react";
import { CalendarIcon } from "lucide-react";
import { X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import TextEditorWithReadability from "../../_components/text-editor-with-benchmarks";
import { appConfig } from "../../config";

const BlogPostSkeleton = () => {
	return (
		<div className="container mx-auto p-4">
			<div className="flex justify-between items-center mb-8">
				<Skeleton className="h-12 w-48" />
				<div className="flex space-x-4">
					<Skeleton className="h-10 w-40" />
					<Skeleton className="h-10 w-32" />
				</div>
			</div>

			<div className="flex flex-col lg:flex-row gap-8">
				<div className="lg:w-3/4 space-y-8">
					<div className="space-y-4">
						<Skeleton className="h-6 w-20" />
						<Skeleton className="h-12 w-full" />
					</div>

					<div className="space-y-4">
						<Skeleton className="h-6 w-24" />
						<Skeleton className="h-[calc(100vh-250px)] w-full" />
					</div>
				</div>

				<div className="lg:w-1/4 space-y-8">
					<div className="space-y-4">
						<Skeleton className="h-6 w-24" />
						<Skeleton className="h-32 w-full" />
					</div>

					<div className="space-y-4">
						<Skeleton className="h-6 w-28" />
						<Skeleton className="h-10 w-full" />
						<div className="flex flex-wrap gap-2 mt-2">
							<Skeleton className="h-8 w-24" />
							<Skeleton className="h-8 w-32" />
						</div>
					</div>

					<div className="space-y-4">
						<Skeleton className="h-6 w-24" />
						<Skeleton className="h-10 w-full" />
					</div>

					<div className="space-y-4">
						<Skeleton className="h-6 w-36" />
						<Skeleton className="h-10 w-full" />
					</div>

					<div className="space-y-4">
						<Skeleton className="h-6 w-40" />
						<Skeleton className="h-10 w-full" />
					</div>

					<div className="space-y-4">
						<Skeleton className="h-6 w-20" />
						<Skeleton className="h-10 w-full" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default function EditBlogPost() {
	const params = useParams();
	const [searchParams] = useSearchParams();
	const blogId = params.id as string;
	const rawDomain = searchParams.get("domain");
	const domain = rawDomain?.split("?")[0];
	const auth: any = useContext(AuthContext);
	const organizationId = auth.user?.organization_id;
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [excerpt, setExcerpt] = useState("");
	const [categories, setCategories] = useState<string[]>([]);
	const [tags, setTags] = useState("");
	const [featuredImage, setFeaturedImage] = useState<File | null>(null);
	const [publishDate, setPublishDate] = useState<Date | undefined>();
	const [status, setStatus] = useState(searchParams.get("status") || "draft");
	const [isPageLoading, setIsPageLoading] = useState(true);
	const { toast } = useToast();
	const [isPublishing, setIsPublishing] = useState(false);
	const [isSavingDraft, setIsSavingDraft] = useState(false);

	const { data: blogPost } = useQuery({
		queryKey: ["blogPost", blogId],
		queryFn: async () => {
			try {
				const { data } = await axios.post("/api/wordpress/get-blogpost", {
					id: blogId,
					organizationId,
					domain: domain || auth.user?.domain,
					status: status,
				});
				return data;
			} finally {
				setTimeout(() => setIsPageLoading(false), 500);
			}
		},
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
	});

	const { data: categoriesList } = useQuery({
		queryKey: ["categories", organizationId],
		queryFn: async () => {
			const { data } = await axios.get(
				`/api/wordpress/categories/${organizationId}`,
			);

			return data.data;
		},
		enabled: !isPageLoading,
	});

	useEffect(() => {
		if (blogPost) {
			setTitle(blogPost.title?.rendered || "");
			const contentWithoutTags = blogPost.content?.rendered || "";
			setContent(contentWithoutTags);
			const excerptWithoutTags = blogPost.excerpt?.rendered || "";
			setExcerpt(excerptWithoutTags);
			setCategories(blogPost.categories?.map(String) || []);
			setTags(blogPost.tags?.join(", ") || "");
			setPublishDate(blogPost.date ? new Date(blogPost.date) : undefined);
			setFeaturedImage(blogPost.featured_media || null);
			setStatus(blogPost.status || "draft");
		}
	}, [blogPost]);

	const handlePublish = async () => {
		setIsPublishing(true);
		try {
			const blogData = {
				title: title,
				content: content,
				categories: categories,
				status: "publish",
			};

			const response = await axios.patch(
				`/api/wordpress/update-blogpost/${blogId}/${organizationId}/${domain}`,
				blogData,
			);

			if (response.status === 200) {
				toast({
					title: "Éxito",
					description: "El post ha sido publicado correctamente",
					variant: "default",
				});
			}
		} catch (error) {
			toast({
				title: "Error",
				description: "Hubo un error al publicar el post",
				variant: "destructive",
			});
		} finally {
			setIsPublishing(false);
		}
	};

	const handleSaveDraft = async () => {
		setIsSavingDraft(true);
		try {
			const blogData = {
				title: title,
				content: content,
				categories: categories,
				status: "draft",
			};

			const response = await axios.patch(
				`/api/wordpress/update-blogpost/${blogId}/${organizationId}/${domain}`,
				blogData,
			);

			if (response.status === 200) {
				toast({
					title: "Éxito",
					description: "El borrador ha sido guardado correctamente",
					variant: "default",
				});
			}
		} catch (error) {
			toast({
				title: "Error",
				description: "Hubo un error al guardar el borrador",
				variant: "destructive",
			});
		} finally {
			setIsSavingDraft(false);
		}
	};

	const handleContentChange = (newContent: string) => {
		setContent(newContent);
	};

	const handleReadabilityMetricsChange = (metrics: any) => {
		// Aquí puedes manejar las métricas de legibilidad si lo necesitas
		console.log("Readability metrics:", metrics);
	};

	if (isPageLoading) {
		return <BlogPostSkeleton />;
	}

	return (
		<div className="container mx-auto p-4">
			<div className="flex justify-between items-center mb-8">
				<h1 className="text-4xl font-bold">Editar Borrador</h1>
				<div className="flex space-x-4">
					<Button
						type="button"
						variant="outline"
						onClick={handleSaveDraft}
						disabled={isSavingDraft}
					>
						{isSavingDraft ? "Guardando..." : "Guardar como Borrador"}
					</Button>
					<Button type="button" onClick={handlePublish} disabled={isPublishing}>
						{isPublishing ? "Publicando..." : "Publicar"}
					</Button>
				</div>
			</div>
			<div className="flex flex-col lg:flex-row gap-8">
				<div className="lg:w-3/4 space-y-8">
					<div className="space-y-4">
						<Label htmlFor="title" className="text-xl">
							Título
						</Label>
						<Input
							id="title"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							placeholder="Ingresa el título del post"
							className="text-2xl py-3"
						/>
					</div>

					<div className="space-y-4">
						<Label htmlFor="content" className="text-xl">
							Contenido
						</Label>
						<TextEditorWithReadability
							initialContent={content}
							onContentChange={handleContentChange}
							onReadabilityMetricsChange={handleReadabilityMetricsChange}
						/>
					</div>
				</div>

				<div className="lg:w-1/4 space-y-8">
					<div className="space-y-4">
						<Label htmlFor="categories" className="text-lg">
							Categorías
						</Label>
						{!categoriesList ? (
							// Skeleton loader solo para la sección de categorías
							<>
								<Skeleton className="h-10 w-full" />
								<div className="flex flex-wrap gap-2 mt-2">
									<Skeleton className="h-8 w-24" />
									<Skeleton className="h-8 w-32" />
								</div>
							</>
						) : (
							<>
								<Select
									value=""
									onValueChange={(value) => {
										if (!categories.includes(value)) {
											setCategories([...categories, value]);
										}
									}}
								>
									<SelectTrigger>
										<SelectValue placeholder="Selecciona una categoría" />
									</SelectTrigger>
									<SelectContent>
										{categoriesList?.map((category: any) => (
											<SelectItem
												key={category.id}
												value={category.id.toString()}
											>
												{category.name}
											</SelectItem>
										))}
									</SelectContent>
								</Select>

								<div className="flex flex-wrap gap-2 mt-2">
									{categories.map((categoryId) => {
										const category = categoriesList?.find(
											(c: any) => c.id.toString() === categoryId,
										);
										return (
											<div
												key={categoryId}
												className="flex items-center gap-1 bg-primary text-primary-foreground px-3 py-1 rounded-full text-sm"
											>
												{category?.name || categoryId}
												<Button
													type="button"
													variant="ghost"
													className="h-auto p-0 text-primary-foreground hover:text-primary-foreground/80"
													onClick={() => {
														setCategories(
															categories.filter((id) => id !== categoryId),
														);
													}}
												>
													<X className="h-3 w-3" />
													<span className="sr-only">
														Eliminar {category?.name || categoryId}
													</span>
												</Button>
											</div>
										);
									})}
								</div>
							</>
						)}
					</div>

					<div className="space-y-4">
						<Label htmlFor="tags" className="text-lg">
							Etiquetas
						</Label>
						<Input
							id="tags"
							value={tags}
							onChange={(e) => setTags(e.target.value)}
							placeholder="Ingresa las etiquetas separadas por comas"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
