/***
 *
 *   ORGANIZATION
 *   Index page for organization functions
 *
 **********/

import {
	Animate,
	AuthContext,
	Card,
	Grid,
	Icon,
	Link,
	Loader,
	useAPI,
} from "components/lib";
import { useContext } from "react";

export function Organization(props) {
	const context = useContext(AuthContext);

	const user = useAPI("/api/user");
	const iconSize = 20;

	if (user.loading) return <Loader />;

	return (
		<Animate>
			<Grid cols="4">
				<Card>
					<Icon image="user" size={iconSize} />
					<h2>{props.t("organization.profile.title")}</h2>
					<div>{props.t("organization.profile.description")}</div>
					<Link url="/organization/profile">
						{props.t("organization.profile.button")}
					</Link>
				</Card>

				<Card>
					<Icon image="lock" size={iconSize} />
					<h2>{props.t("organization.password.title")}</h2>
					<div>
						{user?.data?.["has_password"]
							? props.t("organization.password.description.change")
							: props.t("organization.password.description.create")}
					</div>
					<Link url="/organization/password">
						{user?.data?.["has_password"]
							? props.t("organization.password.button.change")
							: props.t("organization.password.button.create")}
					</Link>
				</Card>

				<Card>
					<Icon image="shield" size={iconSize} />
					<h2>{props.t("organization.2fa.title")}</h2>
					<div>{props.t("organization.2fa.description")}</div>
					<Link url="/organization/2fa">
						{user?.data?.["2fa_enabled"]
							? props.t("organization.2fa.button.manage")
							: props.t("organization.2fa.button.enable")}
					</Link>
				</Card>

				{context.permission?.owner && (
					<Card>
						<Icon image="credit-card" size={iconSize} />
						<h2>{props.t("organization.billing.title")}</h2>
						<div>{props.t("organization.billing.description")}</div>
						<Link url="/organization/billing">
							{props.t("organization.billing.button")}
						</Link>
					</Card>
				)}

				<Card>
					<Icon image="bell" size={iconSize} />
					<h2>{props.t("organization.notifications.title")}</h2>
					<div>{props.t("organization.notifications.description")}</div>
					<Link url="/organization/notifications">
						{props.t("organization.notifications.button")}
					</Link>
				</Card>

				{context.permission?.developer && (
					<Card>
						<Icon image="settings" size={iconSize} />
						<h2>{props.t("organization.api_keys.title")}</h2>
						<div>{props.t("organization.api_keys.description")}</div>
						<Link url="/organization/apikeys">
							{props.t("organization.api_keys.button")}
						</Link>
					</Card>
				)}

				{context.permission?.admin && (
					<Card>
						<Icon image="users" size={iconSize} />
						<h2>{props.t("organization.users.title")}</h2>
						<div>{props.t("organization.users.description")}</div>
						<Link url="/organization/users">
							{props.t("organization.users.button")}
						</Link>
					</Card>
				)}

				<Card>
					<Icon image="sliders" size={iconSize} />
					<h2>{props.t("organization.user_settings.title")}</h2>
					<div>{props.t("organization.user_settings.description")}</div>
					<Link url="/organization/user-settings">
						{props.t("organization.user_settings.button")}
					</Link>
				</Card>
			</Grid>
		</Animate>
	);
}
