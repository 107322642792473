import { Card } from "components/ui/card";
import { format } from "date-fns";
import {
	Eye,
	MessageCircle,
	MousePointerClick,
	Percent,
	Share2,
	Target,
	ThumbsUp,
} from "lucide-react";
import type { LinkedInPost, Post, WordPressPost } from "../types";
import { getLinkedInPostLink, isLinkedInPost } from "../utils";

interface TopPostCardProps {
	post: Post;
}

const renderMetrics = (post: Post) => {
	if (isLinkedInPost(post)) {
		return (
			<div className="flex items-center gap-4 text-sm">
				<div className="flex items-center gap-2">
					<Eye className="h-4 w-4 text-gray-500" />
					<div>{post.impressions.toLocaleString()}</div>
				</div>
				<div className="flex items-center gap-2">
					<ThumbsUp className="h-4 w-4 text-blue-500" />
					<div>{post.likes.toLocaleString()}</div>
				</div>
				<div className="flex items-center gap-2">
					<MessageCircle className="h-4 w-4 text-sky-500" />
					<div>{post.comments.toLocaleString()}</div>
				</div>
				<div className="flex items-center gap-2">
					<Share2 className="h-4 w-4 text-orange-500" />
					<div>{post.shares.toLocaleString()}</div>
				</div>
			</div>
		);
	}

	return (
		<div className="flex items-center gap-4 text-sm">
			<div className="flex items-center gap-2">
				<Eye className="h-4 w-4 text-gray-500" />
				<div>{post.total_impressions.toLocaleString()}</div>
			</div>
			<div className="flex items-center gap-2">
				<MousePointerClick className="h-4 w-4 text-teal-500" />
				<div>{post.total_clicks.toLocaleString()}</div>
			</div>
			<div className="flex items-center gap-2">
				<Percent className="h-4 w-4 text-indigo-500" />
				<div>{post.ctr.toFixed(2)}%</div>
			</div>
			<div className="flex items-center gap-2">
				<Target className="h-4 w-4 text-red-500" />
				<div>{post.avg_position.toFixed(1)}</div>
			</div>
		</div>
	);
};

export const TopPostCard = ({ post }: TopPostCardProps) => {
	const postId = isLinkedInPost(post) ? post.linkedin_id : post.wordpress_id;
	const postDate = isLinkedInPost(post) ? post.creation_time : post.created_at;
	const postContent = isLinkedInPost(post) ? post.content : post.title;
	const postLink = isLinkedInPost(post)
		? getLinkedInPostLink(post.linkedin_id)
		: post.link;

	return (
		<a
			href={postLink}
			target="_blank"
			rel="noopener noreferrer"
			className="block"
		>
			<Card
				key={postId}
				className="p-4 bg-white hover:bg-gray-50 transition-colors"
			>
				<div className="space-y-2">
					<div className="flex justify-between text-sm text-muted-foreground">
						<div>{format(new Date(postDate), "MMM d, yyyy")}</div>
						<div>{post.user_name}</div>
					</div>
					<div className="line-clamp-2 text-sm font-medium">{postContent}</div>
					{renderMetrics(post)}
				</div>
			</Card>
		</a>
	);
};
