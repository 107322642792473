import type { LinkedinPostType, LinkedPostMapping } from "../../../../types";
import { PostFilter } from "../../../../types";
export const getPostStatusBadgeStyle = (status?: string) => {
  const styles = {
    draft: "bg-orange-100 text-orange-700",
    pending: "bg-yellow-100 text-yellow-700",
    publish: "bg-green-100 text-green-700",
    failed: "bg-red-100 text-red-700",
  };
  
  return styles[status as keyof typeof styles] || "";
};

export const getPostStatusLabel = (t: (key: string) => string, status?: string) => {
  const labels = {
    draft: t("content-creation.blog_from_posts_bulk.draft_status"),
    pending: t("content-creation.blog_from_posts_bulk.pending_status"),
    publish: t("content-creation.blog_from_posts_bulk.published_status"),
    failed: t("content-creation.blog_from_posts_bulk.failed_status")
  };

  return labels[status as keyof typeof labels] || status || "";
};

export const getFilteredPosts = (
  posts: LinkedinPostType[], 
  linkedPosts: LinkedPostMapping[], 
  currentFilter: PostFilter,
  selectedMonth: string,
  selectedYear: string,
  skipPagination = false,
  pageSize = 10,
  currentPage = 0
) => {
  let filtered = posts;

  if (selectedMonth !== "all" || selectedYear !== "all") {
    filtered = filtered.filter((post) => {
      const postDate = new Date(post.creation_time);
      const matchesYear = selectedYear === "all" || postDate.getFullYear().toString() === selectedYear;
      const matchesMonth = selectedMonth === "all" || postDate.getMonth().toString() === selectedMonth;
      return matchesYear && matchesMonth;
    });
  }

  switch (currentFilter) {
    case PostFilter.PUBLISHED:
      filtered = filtered.filter(post => 
        linkedPosts?.find(item => item.linkedin_post_id === post.linkedin_id && item.status === "publish")
      );
      break;
    case PostFilter.DRAFT:
      filtered = filtered.filter(post => 
        linkedPosts?.find(item => item.linkedin_post_id === post.linkedin_id && item.status === "draft")
      );
      break;
    case PostFilter.PENDING:
      filtered = filtered.filter(post => 
        linkedPosts?.find(item => item.linkedin_post_id === post.linkedin_id && item.status === "pending")
      );
      break;
    case PostFilter.FAILED:
      filtered = filtered.filter(post => 
        linkedPosts?.find(item => item.linkedin_post_id === post.linkedin_id && item.status === "failed")
      );
      break;
    case PostFilter.UNPUBLISHED:
      filtered = filtered.filter(post => 
        !linkedPosts?.find(item => item.linkedin_post_id === post.linkedin_id)
      );
      break;
    case PostFilter.ALL:
    default:
      break;
  }

  if (skipPagination) {
    return filtered;
  }

  const start = currentPage * pageSize;
  const end = start + pageSize;
  return filtered.slice(start, end);
}; 