import { useQuery } from "@tanstack/react-query";
import axios from "axios";
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Chart as ChartJS, registerables } from "chart.js";
import { Skeleton } from "components/ui/skeleton";
import { Line } from "react-chartjs-2";

ChartJS.register(...registerables);

const MetricsChart = ({
	organizationId = null,
	userId = null,
	dates,
	metric,
	timeInterval,
	t,
	backgroundColor = "rgba(75, 145, 192, 0.2)",
	borderColor = "rgb(75, 116, 192)",
	platform = "linkedin",
}) => {
	const url = organizationId
		? `/api/analytics/${organizationId}/${platform}/${metric}?timeInterval=${timeInterval}&startDate=${dates.from.toISOString()}&endDate=${dates.to.toISOString()}`
		: `/api/analytics/user/${userId}/${platform}/${metric}?timeInterval=${timeInterval}&startDate=${dates.from.toISOString()}&endDate=${dates.to.toISOString()}`;

	const { data: metricData, isLoading } = useQuery({
		queryKey: ["metrics", url],
		queryFn: async () => {
			const { data } = await axios.get(url);
			return data;
		},
	});

	if (isLoading) {
		return <Skeleton className="w-full h-[620px] rounded-md" />;
	}

	const labels = metricData.map((item) => {
		switch (timeInterval) {
			case "yearly": {
				return `${t("dashboard.collaborator_metrics.year")} ${item.year}`;
			}
			case "monthly": {
				return `${item.month}/${item.year}`;
			}
			case "weekly": {
				return `${t("dashboard.collaborator_metrics.week")} ${item.week}, ${item.year}`;
			}
			case "daily": {
				return `${item.day}/${item.month}/${item.year}`;
			}
			default: {
				return "";
			}
		}
	});

	const data = {
		labels: labels,
		datasets: [
			{
				label: `${t(`dashboard.collaborator_metrics.${metric}`)}`,
				data: metricData.map((item) => item[metric]),
				backgroundColor: backgroundColor,
				borderColor: borderColor,
				borderWidth: 2,
				tension: 0.4,
				fill: true,
				pointRadius: 2,
				pointHoverRadius: 6,
			},
		],
	};

	const options = {
		scales: {
			y: {
				beginAtZero: true,
				ticks: {
					callback: (value) => {
						if (value >= 1000000) {
							return `${(Math.ceil(value / 100000) / 10).toFixed(1)}m`;
						}
						if (value >= 1000) {
							return `${(Math.ceil(value / 100) / 10).toFixed(1)}k`;
						}
						return value;
					},
				},
			},
		},
		plugins: {
			tooltip: {
				callbacks: {
					label: (tooltipItem) => {
						const value = tooltipItem.raw;
						if (value >= 1000000) {
							return `${(Math.ceil(value / 100000) / 10).toFixed(1)}m`;
						}
						if (value >= 1000) {
							return `${(Math.ceil(value / 100) / 10).toFixed(1)}k`;
						}
						return value;
					},
				},
			},
		},
		interaction: {
			intersect: false,
			mode: "index",
		},
	};

	return <Line data={data} options={options} />;
};

export default MetricsChart;
