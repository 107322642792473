import { Button } from "components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import { ArrowUpDown } from "lucide-react";
import { useState } from "react";
import CardSkeleton from "./card-skeleton";

interface Post {
	id: string;
	date: string;
	content: string;
	url: string;
	metrics: {
		likes: number;
		comments: number;
		shares: number;
		impressions: number;
		engagement: number;
	};
}

interface TopPerformingPostsProps {
	posts: Post[];
	loading?: boolean;
}

export const TopPerformingPosts: React.FC<TopPerformingPostsProps> = ({
	posts,
	loading,
}) => {
	const [sortConfig, setSortConfig] = useState<{
		key: string;
		direction: "asc" | "desc";
	}>({ key: "date", direction: "desc" });

	const formatDate = (dateString: string) => {
		return new Date(dateString).toLocaleDateString("en-US", {
			year: "numeric",
			month: "short",
			day: "numeric",
		});
	};

	const sortedPosts = [...posts].sort((a, b) => {
		if (sortConfig.key === "date") {
			return sortConfig.direction === "asc"
				? new Date(a.date).getTime() - new Date(b.date).getTime()
				: new Date(b.date).getTime() - new Date(a.date).getTime();
		}

		if (sortConfig.key === "engagement") {
			return sortConfig.direction === "asc"
				? a.metrics.engagement - b.metrics.engagement
				: b.metrics.engagement - a.metrics.engagement;
		}

		const aValue = a.metrics[sortConfig.key as keyof typeof a.metrics] || 0;
		const bValue = b.metrics[sortConfig.key as keyof typeof b.metrics] || 0;
		return sortConfig.direction === "asc" ? aValue - bValue : bValue - aValue;
	});

	const toggleSort = (key: string) => {
		setSortConfig((current) => ({
			key,
			direction:
				current.key === key && current.direction === "asc" ? "desc" : "asc",
		}));
	};

	const truncateText = (text: string, maxLength = 100) => {
		if (text.length <= maxLength) return text;
		return `${text.slice(0, maxLength)}...`;
	};

	if (loading) {
		return <CardSkeleton />;
	}

	return (
		<Card className="bg-white">
			<CardHeader>
				<CardTitle className="text-xl font-semibold">
					Top Performing Posts
				</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="rounded-md border">
					<Table className="h-[500px]">
						<TableHeader>
							<TableRow>
								<TableHead className="w-[100px]">
									<Button
										variant="ghost"
										onClick={() => toggleSort("date")}
										className="h-8 px-2"
									>
										Date
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
								<TableHead className="w-[300px] max-w-[300px]">
									Content
								</TableHead>
								<TableHead className="text-right">
									<Button
										variant="ghost"
										onClick={() => toggleSort("impressions")}
										className="h-8 px-2"
									>
										Views
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
								<TableHead className="text-right">
									<Button
										variant="ghost"
										onClick={() => toggleSort("engagement")}
										className="h-8 px-2"
									>
										Engagement %
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
								<TableHead className="text-right">
									<Button
										variant="ghost"
										onClick={() => toggleSort("likes")}
										className="h-8 px-2"
									>
										Likes
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
								<TableHead className="text-right">
									<Button
										variant="ghost"
										onClick={() => toggleSort("comments")}
										className="h-8 px-2"
									>
										Comments
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
								<TableHead className="text-right">
									<Button
										variant="ghost"
										onClick={() => toggleSort("shares")}
										className="h-8 px-2"
									>
										Shares
										<ArrowUpDown className="ml-2 h-4 w-4" />
									</Button>
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{sortedPosts.map((post) => (
								<TableRow key={post.id}>
									<TableCell className="font-medium whitespace-nowrap">
										{formatDate(post.date)}
									</TableCell>
									<TableCell className="max-w-[300px]">
										<div className="relative group">
											<a
												href={post.url}
												target="_blank"
												rel="noopener noreferrer"
												className="text-blue-500 hover:underline block truncate"
												title={post.content}
											>
												{truncateText(post.content)}
											</a>
											<div className="hidden group-hover:block absolute z-10 p-2 bg-white border rounded-md shadow-lg max-w-md mt-1">
												{post.content}
											</div>
										</div>
									</TableCell>
									<TableCell className="text-right whitespace-nowrap">
										{post.metrics.impressions.toLocaleString()}
									</TableCell>
									<TableCell className="text-right whitespace-nowrap">
										{post.metrics.engagement.toFixed(1)}%
									</TableCell>
									<TableCell className="text-right whitespace-nowrap">
										{post.metrics.likes.toLocaleString()}
									</TableCell>
									<TableCell className="text-right whitespace-nowrap">
										{post.metrics.comments.toLocaleString()}
									</TableCell>
									<TableCell className="text-right whitespace-nowrap">
										{post.metrics.shares.toLocaleString()}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</CardContent>
		</Card>
	);
};
