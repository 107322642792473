/***
 *
 *   useAPI hook
 *   Interact with API calls – handle errors, return loading state and data
 *
 *   PROPS
 *   method: get, post, put (string, required, default: get)
 *   url: endpoint url (string, required)
 *
 **********/

import Axios from "axios";
import { ViewContext } from "components/lib";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

export function useAPI(url, method = "get", showError = true) {
	const context = useRef(useContext(ViewContext));
	const [state, setState] = useState({ data: undefined, loading: false });

	const fetch = useCallback(async () => {
		try {
			if (!url) {
				setState({ data: undefined, loading: false });
				return false;
			}

			setState({ data: undefined, loading: true });
			const res = await Axios({
				url: url,
				method: method || "get",
			});

			setState({ data: res?.data?.data, loading: false });
		} catch (error) {
			if (
				context?.current &&
				typeof context.current.handleError === "function"
			) {
				if (showError) {
					context.current.handleError(error);
				}
			} else {
				console.error("Error:", error);
			}
		}
	}, [url, method]);

	useEffect(() => {
		fetch();
	}, [fetch]);

	return state;
}
