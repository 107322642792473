import { Skeleton } from "components/ui/skeleton";

export const SkeletonSelect = () => {
	return (
		<div className="flex gap-4">
			<Skeleton className="h-9 w-60" />
			<Skeleton className="h-9 w-32" />
			<Skeleton className="h-9 w-36" />
			<Skeleton className="h-9 w-36" />
		</div>
	);
};
