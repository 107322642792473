const Style = {
	input: `float-left relative w-6 h-6 cursor-pointer mr-2 bg-none 
  border border-solid border-slate-200 bg-center appearance-none
  checked:bg-[url('/assets/icons/ico-check-white.svg')] 
  checked:bg-emerald-500 checked:bg-[length:10px] p-1 dark:border-slate-600`,

	label: "float-left cursor-pointer mt-[2px] normal-case",
};

export default Style;
