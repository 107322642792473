import { Skeleton } from "components/ui/skeleton";
import { Card, CardContent, CardHeader } from "components/ui/card";

export const LeadTrackingTableSkeleton = () => {
  return (
    <Card className="p-2 bg-white">
      <CardHeader>
        <div className="flex justify-between items-center">
          <Skeleton className="h-8 w-32" />
          <div className="flex items-center gap-4">
            <Skeleton className="h-8 w-40" />
            <Skeleton className="h-8 w-24" />
            <Skeleton className="h-8 w-24" />
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Skeleton className="h-10 w-64" />
          <div className="rounded-md border">
            <div className="space-y-4">
              <div className="border-b">
                <div className="grid grid-cols-7 gap-4 p-4">
                  {Array.from({ length: 7 }).map((_, i ) => (
                    <Skeleton key={Math.random().toString(36).substring(7)} className="h-6" />
                  ))}
                </div>
              </div>
              {Array.from({ length: 5 }).map((_, i) => (
                <div key={Math.random().toString(36).substring(7)} className="grid grid-cols-7 gap-4 p-4">
                  {Array.from({ length: 7 }).map((_, j) => (
                    <Skeleton key={Math.random().toString(36).substring(7)} className="h-6" />
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}; 