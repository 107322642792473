import { Animate, AuthContext, Card, Form, Row } from "components/lib";
import React, { useContext } from "react";

export function SignupUser(props) {
	const url = window.location.href;
	const context = useContext(AuthContext);
	const id = url.substring(url.indexOf("?id=") + 4, url.indexOf("&email"));
	const email = url.slice(Math.max(0, url.indexOf("&email") + 7));

	return (
		<Animate type="pop">
			<Row title={props.t("auth.signup.user.title")}>
				<Card
					center
					transparent
					className="flex flex-col gap-4 w-full max-w-xl"
				>
					<Form
						inputs={{
							name: {
								label: props.t("auth.signup.user.form.name.label"),
								value: "",
								type: "text",
								required: true,
								errorMessage: props.t("auth.signup.user.form.name.error"),
							},
							email: {
								label: props.t("auth.signup.user.form.email.label"),
								value: email,
								type: "email",
								required: true,
								disabled: true,
								tooltip: "This email is fixed and can't be changed",
							},
							password: {
								label: props.t("auth.signup.user.form.password.label"),
								type: "password",
								required: true,
								complexPassword: true,
							},
							confirm_password: {
								type: "hidden",
								value: null,
							},
							invite_id: {
								type: "hidden",
								value: id,
							},
						}}
						url="/api/user"
						method="POST"
						redirect="/onboarding"
						buttonText={props.t("auth.signup.user.form.button")}
						callback={context.signin}
					/>
				</Card>
			</Row>
		</Animate>
	);
}
