/***
 *
 *   SIGN IN
 *   Sign in form for all organization types (including master).
 *
 **********/

import {
	Animate,
	AuthContext,
	Button,
	Card,
	Form,
	Link,
	Message,
	Row,
	SocialSignin,
	ViewContext,
	useLocation,
	useNavigate,
} from "components/lib";
import { useContext, useEffect, useState } from "react";

export function Signin(props) {
	// context
	const authContext = useContext(AuthContext);
	const viewContext = useContext(ViewContext);

	const location = useLocation();
	const navigate = useNavigate();

	// state
	const [useMagic, setUseMagic] = useState(false);
	const [magicLinkSent, setMagicLinkSent] = useState(false);
	const [form, setForm] = useState({
		email: {
			label: props.t("auth.signin.index.form.email.label"),
			type: "email",
			required: true,
		},
		password: {
			label: props.t("auth.signin.index.form.password.label"),
			type: "password",
			required: true,
		},
		forgotpassword: {
			type: "link",
			url: "/forgotpassword",
			text: props.t("auth.signin.index.form.forgotpassword.text"),
		},
	});

	useEffect(() => {
		// was an error message passed from the server router?
		const qs = location.search;

		if (qs.includes("error")) {
			const msg = decodeURIComponent(
				qs.slice(Math.max(0, qs.indexOf("?error=") + 7)),
			);
			viewContext.notification.show(msg, "error");
		}
	}, [location.search, viewContext.notification]);

	function toggleMagicLink(useMagic) {
		const f = { ...form };
		f.password.type = useMagic ? "hidden" : "password";
		f.password.required = useMagic ? false : true;
		f.forgotpassword.type = useMagic ? "hidden" : "link";

		setForm(f);
		setUseMagic(useMagic);
	}

	/*
  useEffect(() => {
    // Redirect to dashboard if already logged in
    // if (authContext.user) {
    //   navigate("/dashboard");
    // }
    console.log("wat", authContext.user);
  }, [authContext, navigate]);
  */

	return (
		<Animate type="pop">
			<Row title={props.t("auth.signin.index.title")}>
				{magicLinkSent ? (
					<div style={{ width: "28em", margin: "0 auto" }}>
						<Message
							type="success"
							title={props.t("auth.signin.index.message.title")}
							text={props.t("auth.signin.index.message.text")}
						/>
					</div>
				) : (
					<Card restrictWidth center transparent>
						<SocialSignin useMagic={useMagic} />

						{useMagic ? (
							<Button
								text={props.t("auth.signin.index.button.use_password")}
								color="blue"
								icon="shield"
								iconColor="white"
								lowercase
								className="mx-auto mb-5"
								rounded
								iconButton
								action={() => {
									toggleMagicLink(false);
								}}
							/>
						) : (
							<Button
								text={props.t("auth.signin.index.button.use_magic")}
								color="blue"
								lowercase
								iconColor="white"
								className="mx-auto mb-5 text-indigo-800 bg-indigo-200 hover:bg-indigo-300 hover:text-indigo-900"
								rounded
								iconButton
								action={() => {
									toggleMagicLink(true);
								}}
							/>
						)}

						<Form
							inputs={form}
							method="POST"
							url={useMagic ? "/api/auth/magic" : "/api/auth"}
							buttonText={
								useMagic
									? props.t("auth.signin.index.form.button.use_magic")
									: props.t("auth.signin.index.form.button.use_password")
							}
							callback={(res) => {
								useMagic
									? setMagicLinkSent(true)
									: res.data["2fa_required"]
										? navigate(`/signin/otp?token=${res.data.token}`)
										: navigate(authContext.signin(res));
							}}
						/>

						{/* <footer className="mt-4">
              {props.t("auth.signin.index.footer.text")}
              <Link
                url="/signup"
                text={props.t("auth.signin.index.footer.link_text")}
              />
            </footer> */}
					</Card>
				)}
			</Row>
		</Animate>
	);
}
