import * as Sentry from "@sentry/react";
import { posthog } from "posthog-js";

posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
	api_host: import.meta.env.VITE_POSTHOG_API_HOST,
	ui_host: import.meta.env.VITE_POSTHOG_UI_HOST,
	enable_heatmaps: true,
	loaded: (ph) => {
		if (!import.meta.env.PROD) {
			ph.opt_out_capturing();
			ph.set_config({
				disable_session_recording: true,
				enable_heatmaps: false,
			});
		}
	},
});

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	tunnel: `${import.meta.env.VITE_API_MAIN_URL}/sentry-tunnel`,
	enabled: import.meta.env.VITE_APP_ENV === "production",
	release: import.meta.env.VITE_APP_VERSION,
	integrations: [
		posthog.sentryIntegration({
			organization: import.meta.env.VITE_SENTRY_ORGANIZATION,
			projectId: Number(import.meta.env.VITE_SENTRY_PROJECT_ID),
			severityAllowList: ["error", "info"], // optional: here is set to handle captureMessage (info) and captureException (error)
		}),
	],
});
