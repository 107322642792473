/***
 *
 *   USER
 *   Shows the current user name and avatar
 *   If user belongs to more than one organization, they can switch organizations here
 *
 **********/

import {
	AuthContext,
	Button,
	HoverNav,
	Image,
	LocalePicker,
	ViewContext,
} from "components/lib";
import { useContext } from "react";

import Style from "./user.tailwind.js";

export function User() {
	const authContext = useContext(AuthContext);
	const viewContext = useContext(ViewContext);
	const organizationName = authContext.user?.organizations?.find(
		(x) => x.id === authContext.user?.organization_id,
	)?.name;

	return (
		<div className={Style.user}>
			<div className={Style.label}>
				<div className={Style.name}>
					{" "}
					{viewContext.t("global.welcome")}, {authContext.user?.name}
				</div>

				{authContext.user?.organizations?.length > 1 && (
					<HoverNav
						dark
						icon="chevron-down"
						align="right"
						label={organizationName}
						className={Style.hoverNav}
					>
						{authContext.user.organizations.map((organization) => {
							return (
								<Button
									key={organization.id}
									text={organization.name}
									action={() => {
										viewContext.setLoading(true);
										authContext.switchOrganization(organization.id);
									}}
								/>
							);
						})}
					</HoverNav>
				)}
			</div>

			{authContext.user?.avatar && (
				<Image source={authContext.user?.avatar} className={Style.avatar} />
			)}
		</div>
	);
}
