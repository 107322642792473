import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "components/ui/accordion";
import { Button } from "components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import { Label } from "components/ui/label";
import { Textarea } from "components/ui/textarea";
import { useState } from "react";
import type { CustomVoice, VoiceConfig } from "types/customVoice";
import { ObjectEditor } from "./ObjectEditor";

interface VoiceModalProps {
	voice: CustomVoice;
	isOpen: boolean;
	onClose: () => void;
	mode: "view" | "edit";
	onSave?: (data: CustomVoice) => Promise<void>;
}

export function VoiceModal({
	voice,
	isOpen,
	onClose,
	mode,
	onSave,
}: VoiceModalProps) {
	const [metadata, setMetadata] = useState(voice.metadata || "");
	const parseJson = (value: string | object | undefined) => {
		if (!value) return null;
		if (typeof value === "object") return value;
		try {
			return JSON.parse(value);
		} catch (error) {
			return value;
		}
	};

	const voiceConfig = parseJson(voice.voice_config);

	const handleSave = async (newConfig: VoiceConfig) => {
		if (onSave) {
			await onSave({
				...voice,
				voice_config: newConfig,
				metadata,
			});
			onClose();
		}
	};

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
				<DialogHeader>
					<DialogTitle>
						{mode === "edit" ? "Edit Voice" : "View Voice"}: {voice.name}
					</DialogTitle>
				</DialogHeader>

				<div className="flex-1 overflow-hidden">
					{mode === "view" ? (
						<Accordion type="single" collapsible className="w-full space-y-2">
							<AccordionItem value="voice-config" className="border rounded-lg">
								<AccordionTrigger className="px-4 hover:no-underline hover:bg-slate-50/50 [&[data-state=open]>svg]:rotate-180 transition-all">
									Voice Configuration
								</AccordionTrigger>
								<AccordionContent className="px-4 pb-4 data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
									<pre className="bg-slate-50/50 p-4 rounded-lg overflow-auto max-h-96 whitespace-pre-wrap break-words max-w-full">
										{JSON.stringify(voiceConfig, null, 2)}
									</pre>
								</AccordionContent>
							</AccordionItem>
							<AccordionItem value="metadata" className="border rounded-lg">
								<AccordionTrigger className="px-4 hover:no-underline hover:bg-slate-50/50 [&[data-state=open]>svg]:rotate-180 transition-all">
									Metadata
								</AccordionTrigger>
								<AccordionContent className="px-4 pb-4 data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
									<pre className="bg-slate-50/50 p-4 rounded-lg overflow-auto max-h-96 whitespace-pre-wrap break-words max-w-full">
										{metadata}
									</pre>
								</AccordionContent>
							</AccordionItem>
						</Accordion>
					) : (
						<div className="space-y-6">
							<div className="max-h-[60vh] overflow-y-auto pr-4">
								<ObjectEditor data={voiceConfig} onSave={handleSave} />
								<div className="space-y-2 mt-6">
									<Label>Metadata</Label>
									<Textarea
										value={metadata}
										onChange={(e) => setMetadata(e.target.value)}
										className="min-h-[100px]"
										placeholder="Enter metadata..."
									/>
								</div>
							</div>
						</div>
					)}
				</div>
				{mode === "edit" && (
					<div className="flex justify-end pt-4">
						<Button onClick={() => handleSave(voiceConfig)}>
							Save Changes
						</Button>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
}
