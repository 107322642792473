import { AuthContext } from "components/lib";
import { motion } from "framer-motion";
import { CircleCheck, CircleX, Loader } from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { appConfig } from "views/content-creation/config";

const LinkedinAuthCallback = () => {
	const auth: any = useContext(AuthContext);
	const [searchParams] = useSearchParams();
	const code = searchParams.get("code");
	const state = searchParams.get("state"); // contains the email
	const email = state ? decodeURIComponent(state) : null;
	const hasCalled = useRef(false);
	const [status, setStatus] = useState("idle");

	const storeLinkedinCallback = async () => {
		try {
			setStatus("pending");
			const response = await fetch(
				`${appConfig.API_MAIN_URL}/api/oauth/linkedin/callback`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						code,
						email,
					}),
				},
			);

			if (response.status !== 201) {
				throw new Error("Failed to store LinkedIn callback");
			}

			const result = await response.json();

			localStorage.setItem("linkedinOauthExpiresIn", result.expiresIn);
			toast.success("LinkedIn account connected successfully");
			setStatus("success");
		} catch (error) {
			console.error("Error storing LinkedIn callback:", error);
			setStatus("error");
		}
	};

	useEffect(() => {
		if (code && email && !hasCalled.current) {
			hasCalled.current = true;
			storeLinkedinCallback();
		}
	}, [auth?.user?.id, code, email]);

	return (
		<div className="flex flex-col justify-center items-center py-20 my-auto h-full max-h-screen bg-gray-100">
			<div className="p-8 bg-white rounded-lg shadow-md">
				<h1 className="mb-4 text-2xl font-bold">Connecting LinkedIn Account</h1>
				{status === "success" && (
					<motion.div
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						className="flex flex-col items-center"
					>
						<CircleCheck className="mb-4 w-16 h-16 text-green-500" />
						<p className="mb-2 text-lg font-semibold">
							LinkedIn account connected successfully!
						</p>
						<p className="text-sm text-gray-600">You can close this tab.</p>
					</motion.div>
				)}
				{status === "error" && (
					<motion.div
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						className="flex flex-col items-center"
					>
						<CircleX className="mb-4 w-16 h-16 text-red-500" />
						<p className="mb-2 text-lg font-semibold">
							Failed to connect LinkedIn account
						</p>
						<p className="text-sm text-gray-600">Please try again later</p>
					</motion.div>
				)}
				{status === "pending" && (
					<div className="flex justify-center">
						<Loader className="w-12 h-12 text-blue-500 animate-spin" />
					</div>
				)}
			</div>
		</div>
	);
};

export default LinkedinAuthCallback;
