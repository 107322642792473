import { AuthContext, Animate } from "components/lib";
import { useContext } from "react";
import type { AuthContextType } from "types/authContext";
import { LeadTrackingTable } from "./_components/lead-tracking-table";

export const LeadLens = () => {
  const auth: AuthContextType = useContext(AuthContext);

  if (!auth?.user) return null;

  return (
    <Animate type="pop">
      <div className="mx-auto max-w-7xl py-6 space-y-4">
        <LeadTrackingTable />
      </div>
    </Animate>
  );
}; 