import { defineStepper } from "@stepperize/react";
import { AuthContext, Card, Header, Row, User } from "components/lib";
import { Button } from "components/ui/button";
import { Steps } from "components/ui/steps";
import { linkedinOAuthUrl } from "lib/utils";
import { Loader } from "lucide-react";
import React, { useState, useEffect, useContext, useRef } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { appConfig } from "views/content-creation/config";
import { useExtensionInstalled } from "views/dashboard/_hooks/use-extension-installed";
import { useLinkedinProfileId } from "views/dashboard/_hooks/use-linkedin-profile-id";
import { initialLinkedinUserPostStoringProcess } from "../../../views/dashboard/utils";

const steps = [
	{ id: "extension", title: "Install the Extension" },
	{ id: "linkedin", title: "Connect to LinkedIn" },
	{ id: "sync", title: "Sync Account" },
];

const { useStepper } = defineStepper(...steps);

export function Onboarding() {
	const stepper = useStepper();
	const { extensionInstalled, loading: loadingExtension } =
		useExtensionInstalled();
	const { profileId, loading: loadingProfile } = useLinkedinProfileId();
	const [isConnectingLinkedIn, setIsConnectingLinkedIn] = useState(false);
	const { user } = useContext(AuthContext);
	const [searchParams] = useSearchParams();
	const code = searchParams.get("code");
	const hasCalled = useRef(false);
	const [isCompletingOnboarding, setIsCompletingOnboarding] = useState(false);
	const [linkedInConnected, setLinkedInConnected] = useState(false);

	useEffect(() => {
		if (user) {
			if (user.onboarded === true) {
				window.location.href = "/dashboard";
				return;
			}
			if (code && !hasCalled.current) {
				hasCalled.current = true;
				storeLinkedinCallback();
			} else if (
				extensionInstalled &&
				profileId &&
				localStorage.getItem("linkedinOauthExpiresIn")
			) {
				stepper.goTo("sync");
			} else if (extensionInstalled) {
				stepper.goTo("linkedin");
				checkLinkedInConnection();
			}
		}
	}, [user, extensionInstalled, profileId, stepper, code]);

	const checkLinkedInConnection = () => {
		const interval = setInterval(() => {
			const expiresIn = localStorage.getItem("linkedinOauthExpiresIn");
			if (expiresIn) {
				clearInterval(interval);
				setLinkedInConnected(true);
				if (extensionInstalled && profileId) {
					stepper.goTo("sync");
				}
			}
		}, 1000);

		setTimeout(() => clearInterval(interval), 300000);
	};

	const storeLinkedinCallback = async () => {
		try {
			setIsConnectingLinkedIn(true);
			const response = await fetch(
				`${appConfig.API_MAIN_URL}/api/oauth/linkedin/callback`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						code,
						email: user.email,
					}),
				},
			);

			if (response.status !== 201) {
				throw new Error("Failed to store LinkedIn callback");
			}

			const result = await response.json();

			localStorage.setItem("linkedinOauthExpiresIn", result.expiresIn);
			toast.success("LinkedIn account connected successfully");
			setIsConnectingLinkedIn(false);

			if (extensionInstalled && profileId) {
				handleOnboardingCompletion().catch((error) => {
					console.error("Error completing onboarding:", error);
					setIsConnectingLinkedIn(false);
					toast.error("Failed to connect LinkedIn account");
				});
			}
		} catch (error) {
			console.error("Error storing LinkedIn callback:", error);
			setIsConnectingLinkedIn(false);
			toast.error("Failed to connect LinkedIn account");
		}
	};

	const handleOnboardingCompletion = async () => {
		try {
			setIsCompletingOnboarding(true);
			const posts = await initialLinkedinUserPostStoringProcess();

			const response = await fetch(
				`${appConfig.API_MAIN_URL}/api/user/onboarded`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						posts,
						profile_id: profileId,
						organization_id: user.organization_id,
						user_id: user.id,
					}),
				},
			);

			if (response.ok) {
				const storedUser = JSON.parse(localStorage.getItem("user"));
				if (storedUser) {
					storedUser.onboarded = true;
					localStorage.setItem("user", JSON.stringify(storedUser));
				}
				window.location.href = "/dashboard";
			} else {
				throw new Error("Failed to set user as onboarded");
			}
		} catch (error) {
			console.error("Error completing onboarding:", error);
			toast.error("Failed to complete onboarding");
		} finally {
			setIsCompletingOnboarding(false);
		}
	};

	const handleExtensionInstall = () => {
		window.open(
			"https://chromewebstore.google.com/detail/inbound-tools/lmdidkfogbencnidkgoohehjlmagaplk",
			"_blank",
		);
	};

	const handleLinkedInConnect = () => {
		setIsConnectingLinkedIn(true);
		window.location.href = linkedinOAuthUrl;
	};

	const handleSyncAccount = async () => {
		try {
			setIsCompletingOnboarding(true);
			await handleOnboardingCompletion();
		} catch (error) {
			console.error("Error syncing account:", error);
			toast.error("Failed to sync account");
		} finally {
			setIsCompletingOnboarding(false);
		}
	};

	if (!user) {
		return <Navigate to="/signin" />;
	}

	return (
		<Row className="w-screen">
			<Header title="Complete Your Onboarding">
				<User />
			</Header>
			<Card
				center
				transparent
				className="flex flex-col gap-4 w-full max-w-xl h-[80vh] pt-32"
			>
				<Steps steps={steps} currentStep={steps.indexOf(stepper.current)} />
				{stepper.when("extension", () => (
					<div className="flex flex-col gap-4">
						<Button
							size="lg"
							className="w-full"
							onClick={handleExtensionInstall}
						>
							Install Chrome Extension
						</Button>
					</div>
				))}

				{stepper.when("linkedin", () => (
					<div className="flex flex-col gap-4">
						<Button
							size="lg"
							onClick={handleLinkedInConnect}
							className="w-full"
							disabled={
								isConnectingLinkedIn ||
								isCompletingOnboarding ||
								linkedInConnected
							}
						>
							{isConnectingLinkedIn
								? "Connecting..."
								: isCompletingOnboarding
									? "Completing Onboarding..."
									: linkedInConnected
										? "LinkedIn Connected"
										: "Connect LinkedIn"}
						</Button>
					</div>
				))}

				{stepper.when("sync", () => (
					<div className="flex flex-col gap-4">
						<Button
							size="lg"
							onClick={handleSyncAccount}
							className="w-full"
							disabled={isCompletingOnboarding}
						>
							{isCompletingOnboarding ? (
								<span className="flex items-center gap-2">
									Loading... <Loader className="animate-spin" />
								</span>
							) : (
								"Sync LinkedIn Content"
							)}
						</Button>
					</div>
				))}
			</Card>
		</Row>
	);
}
