import { api } from "../api";

export const NOT_LOGGED_IN_LINKEDIN_ERROR = "NOT_LOGGED_IN_LINKEDIN";

function extractLinkedInUserId(input: string): string | undefined {
	// Regular expression pattern to match LinkedIn user ID
	const linkedInIdPattern =
		/^(?:https?:\/\/(?:www\.)?linkedin\.com\/in\/)?([^/]+)(?:\/?)?$/i;

	// Match the pattern with the input string
	const match = input.match(linkedInIdPattern);

	// If there's a match, return the captured group (the user ID)
	if (match && match[1]) {
		return match[1];
	}

	return undefined;
}

export const checkIfUserIsLoggedIn = async (JSESSIONID: string) => {
	await api.get(
		"https://www.linkedin.com/voyager/api/me",
		{
			headers: {
				accept: "application/vnd.linkedin.normalized+json+2.1",
				"csrf-token": JSESSIONID,
			},
		},
		undefined,
		(response) => {
			if (response.status === 401) {
				throw new Error(NOT_LOGGED_IN_LINKEDIN_ERROR);
			}
		},
	);
};

export const fetchPostsFromLinkedin = async (
	currentCount = 0,
	desiredCount = 0,
	linkedinProfile: string,
	JSESSIONID: string,
) => {
	const response: unknown = await api.get(
		`https://www.linkedin.com/voyager/api/feed/updates?profileId=${extractLinkedInUserId(linkedinProfile)}&q=memberShareFeed&moduleKey=member-share&count=${desiredCount + currentCount}&start=0`,
		{
			headers: {
				accept: "application/vnd.linkedin.normalized+json+2.1",
				"csrf-token": JSESSIONID,
			},
		},
		undefined,
		(response) => {
			if (response.status === 401) {
				throw new Error(
					"Asegurate de haber iniciado sesión en Linkedin en este navegador",
				);
			}
		},
	);

	return response;
};

// a function that measures the time it takes to execute a function. It receives an async function. It returns an array where the first element is the result and the second element is the time it took to execute it.

type AsyncFunction<T> = () => Promise<T>;

// Function to measure the execution time of an async function
export const measureTime = async <T>(
	fn: AsyncFunction<T>,
): Promise<[T, number]> => {
	const start = performance.now();
	const result = await fn();
	const end = performance.now();
	return [result, end - start];
};
