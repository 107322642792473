import { LeadLens } from "views/lead-lens/lead-lens";


const routes = [
  {
    path: "/lead-lens",
    view: LeadLens,
    layout: "app",
    permission: "user",
    title: "LeadLens",
  },
];

export default routes; 