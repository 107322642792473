/***
 *
 *   USERS
 *   Enables an admin to manage the users in their application
 *
 **********/

import axios from "axios";
import {
	Animate,
	Button,
	Card,
	Event,
	OrganizationNav,
	Table,
	TitleRow,
	ViewContext,
	useAPI,
	usePermissions,
} from "components/lib";
import React, { Fragment, useContext, useEffect, useState } from "react";

export function Users(props) {
	const context = useContext(ViewContext);
	const permissions = usePermissions();

	const data = useAPI("/api/organization/users");
	const [users, setUsers] = useState([]);

	function invite() {
		context.modal.show(
			{
				title: props.t("organization.users.invite.title"),
				text: props.t("organization.users.invite.text"),
				form: {
					email: {
						label: props.t("organization.users.invite.form.email.label"),
						type: "text",
						required: true,
					},
					permission: {
						label: props.t("organization.users.invite.form.permission.label"),
						type: "select",
						default: "user",
						options: permissions?.data?.list?.filter(
							(x) => x.value !== "owner",
						),
					},
				},
				buttonText: props.t("organization.users.invite.form.button"),
				url: "/api/invite",
				method: "POST",
			},
			(form, res) => {
				// add the invited user to the
				if (res.length > 0) {
					const state = [...users];

					for (const invite of res) {
						if (!state.some((x) => x.id === invite.id)) {
							state.push({
								id: invite.id,
								avatar: invite.avatar,
								name: "",
								email: invite.email,
								date_created: invite.date_sent,
								permission: invite.permission || "user",
								status: props.t("organization.users.status.invited"),
								actions: {
									invite: resendInvite,
									delete: deleteInvite,
								},
							});
						}
					}

					Event("invited_user");
					setUsers(state);
				}
			},
		);
	}

	function editUser(data, callback) {
		context.modal.show(
			{
				title: props.t("organization.users.edit.title"),
				form: {
					id: {
						type: "hidden",
						value: data.id,
					},
					name: {
						label: props.t("organization.users.edit.form.name.label"),
						type: "text",
						required: true,
						value: data.name,
						errorMessage: "Please enter a name",
					},
					email: {
						label: props.t("organization.users.edit.form.email.label"),
						type: "email",
						value: data.email,
						required: true,
					},
					permission: {
						label: props.t("organization.users.edit.form.permission.label"),
						type: data.permission === "owner" ? null : "select",
						options: permissions?.data?.list?.filter(
							(x) => x.value !== "owner",
						),
						default: data.permission,
					},
				},
				buttonText: props.t("organization.users.edit.form.button"),
				url: "/api/user",
				method: "PATCH",
			},
			(res) => {
				callback(res);
			},
		);
	}

	function deleteUser(data, callback) {
		context.modal.show(
			{
				title: props.t("organization.users.delete.title"),
				form: {},
				buttonText: props.t("organization.users.delete.form.button"),
				text: `${props.t("organization.users.delete.text")} ${data.name}?`,
				url: `/api/user/${data.id}`,
				method: "DELETE",
				destructive: true,
			},
			() => {
				callback();
			},
		);
	}

	function deleteInvite(data, callback) {
		context.modal.show(
			{
				title: props.t("organization.users.delete_invite.title"),
				form: {},
				buttonText: props.t("organization.users.delete_invite.form.button"),
				text: `${props.t("organization.users.delete_invite.text")} ${data.email}?`,
				url: `/api/invite/${data.id}`,
				method: "DELETE",
				destructive: true,
			},
			() => {
				// remove from state
				const s = [...users];
				s.splice(
					s.findIndex((u) => u.id === data.id),
					1,
				);
				setUsers(s);

				// remove from table
				callback();
			},
		);
	}

	async function resendInvite(data) {
		try {
			context.notification.show(
				`${props.t("organization.users.invite.resent_notification")} ${data.email}`,
				"success",
				true,
			);

			await axios({
				url: "/api/invite",
				method: "post",
				data: { email: data.email },
			});
		} catch (error) {
			context.handleError(error);
		}
	}

	useEffect(() => {
		// format the user list
		let list = [];

		if (data?.data?.users?.length) {
			list = data.data.users.map((x) => {
				return {
					id: x.id,
					avatar: x.avatar,
					name: x.name,
					email: x.email,
					date_created: x.date_created,
					permission: x.permission,
					status: x.verified
						? props.t("organization.users.status.verified")
						: props.t("organization.users.status.registered"),
				};
			});
		}

		if (data?.data?.invites?.length) {
			for (const x of data.data.invites) {
				list.push({
					id: x.id,
					avatar: x.avatar,
					name: "",
					email: x.email,
					date_created: x.date_sent,
					permission: x.permission || "user",
					status: props.t("organization.users.status.invited"),
				});
			}
		}

		setUsers(list);
	}, [data]);

	// attach the per row actions for invites
	if (users.length > 0) {
		for (const u of users) {
			if (u.status === props.t("organization.users.status.invited")) {
				u.actions = {
					invite: resendInvite,
					delete: deleteInvite,
				};
			}
		}
	}

	return (
		<Fragment>
			<OrganizationNav />
			<Animate>
				<TitleRow title={props.t("organization.users.subtitle")}>
					<Button
						small
						text={props.t("organization.users.new.button")}
						action={invite}
					/>
				</TitleRow>

				<Card>
					<Table
						bulkActions={{ delete: 1 }}
						search
						className="restrict-width"
						data={users}
						loading={data.loading}
						translation="organization.users"
						show={[
							"avatar",
							"email",
							"name",
							"date_created",
							"last_login",
							"permission",
							"status",
						]}
						badge={{
							col: "status",
							color: "blue",
							condition: [
								{
									value: props.t("organization.users.status.verified"),
									color: "green",
								},
								{
									value: props.t("organization.users.status.registered"),
									color: "blue",
								},
								{
									value: props.t("organization.users.status.invited"),
									color: "orange",
								},
							],
						}}
						actions={{
							edit: editUser,
							delete: deleteUser,
							email: true,
						}}
					/>
				</Card>
			</Animate>
		</Fragment>
	);
}
