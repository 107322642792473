/***
 *
 *   TABLE
 *   Dynmaic table with sorting, search and row actions
 *   Header rows are created dynamically from column names
 *
 *   PROPS
 *   actions: object with edit/delete/custom callback functions (object, optional)
 *   badge - column name and color to add badges to column (object, optional)
 *   bulkActions: global actions array when using selectable (array, optional)
 *   data: array of table rows (array, required)
 *   header - array of header column names (array, optional)
 *   hide - columns (object key names) to hide (array, optional, default: none)
 *   loading: toggle loading spinner (boolean, optional)
 *   search: show the search field (boolean, optional)
 *   selectable: user can select table rows (boolean, optional)
 *   show: columns (object key names) to show (array, optional, default: all)
 *   translation: reference to a locale object to use for the header translations (string, optional)
 *   naked: remove the styling (boolean, optional)
 *   throttle: throttle the search callback in ms (integer, optional)
 *
 **********/

import {
	Checkbox,
	ClassHelper,
	Loader,
	Search,
	useTranslation,
} from "components/lib";
import { Fragment, useEffect, useState } from "react";

import { BulkActions } from "./actions.jsx";
import { Body } from "./body.jsx";
import { Header } from "./header.jsx";
import Style from "./table.tailwind.js";

export function Table(props) {
	const { t } = useTranslation();

	// state
	const [header, setHeader] = useState(null);
	const [body, setBody] = useState(null);
	const [filter, setFilter] = useState(false);
	const [selected, setSelected] = useState([]);

	useEffect(() => {
		if (props.data) {
			// create the headers
			const header = [];

			// determine the source of keys (props.data.header or from data key)
			const source =
				props.header ||
				(props.data.length > 0 ? Object.keys(props.data[0]) : []);

			if (source.length > 0) {
				for (const key of source) {
					let translation = props.translation
						? t(`${props.translation}.header.${key}`)
						: false;
					translation =
						key === "actions" ? t("global.table.header.actions") : translation;

					header.push({
						name: props.header ? key.name : key,
						title:
							translation ||
							(props.header
								? key.title.replace("_", "")
								: key?.replace("_", " ")),
						sort: key !== "actions",
					});
				}
			}

			setBody(props.data.body || props.data);
			setHeader(header);
		}
	}, [props.data]);

	// loading
	if (props.loading) {
		return (
			<div className={Style.loading}>
				<Loader />
			</div>
		);
	}

	// no data
	if (!header && !body) return false;

	function sort(column, direction) {
		const rows = filter.length > 0 ? [...filter] : [...body];

		rows.sort((a, b) => {
			if (a[column] != undefined && b[column] != undefined) {
				a[column].badge ? (a = a[column].label) : (a = a[column]);

				b[column].badge ? (b = b[column].label) : (b = b[column]);

				// compare dates
				if (/^\d{4}-\d{2}-\d{2}$|^\d{1,2} [A-Z][a-z]{2} \d{4}$/.test(a)) {
					return direction === "desc"
						? new Date(b).getTime() - new Date(a).getTime()
						: new Date(a).getTime() - new Date(b).getTime();
				} else {
					// compare strings and numbers
					if (direction === "desc") {
						if (a > b) return -1;
						return a < b ? 1 : 0;
					} else {
						if (a < b) return -1;
						return a > b ? 1 : 0;
					}
				}
			} else {
				return false;
			}
		});

		filter ? setFilter(rows) : setBody(rows);
	}

	function search(term) {
		if (!term) return setFilter(false);

		// search each cell in each row &
		// update state to show only filtered rows
		const rowsToShow = [];

		for (const row of body) {
			for (const cell in row) {
				if (
					row[cell]?.toString().toLowerCase().includes(term.toLowerCase()) &&
					!rowsToShow.includes(row)
				)
					rowsToShow.push(row);
			}
		}

		setFilter(rowsToShow);
	}

	function select(index, id) {
		// toggle the select state
		// save the index of selected
		const s = [...selected];
		const i = s.findIndex((x) => x.index === index);
		i > -1 ? s.splice(i, 1) : s.push({ index, id });
		return setSelected(s);
	}

	function selectAll() {
		// toggle all visible rows
		setSelected(
			selected.length > 0
				? []
				: props.data.map((x, i) => {
						return { index: i, id: x.id };
					}),
		);
	}

	function editRowCallback(res, row) {
		// update state
		const state = [...body];
		const stateRow = state[state.findIndex((x) => x.id === row.id)];
		Object.keys(res).map((key) => (stateRow[key] = res[key].value));
		setBody(state);

		// update filter
		if (filter) {
			const f = [...filter];
			const filterRow = f[f.findIndex((x) => x.id === row.id)];
			Object.keys(res).map((key) => (filterRow[key] = res[key].value));
			setFilter(f);
		}
	}

	function deleteRowCallback(row) {
		const b = [...body];
		const remove = Array.isArray(row) ? row : [row]; // ensure array

		// remove from body
		for (const r of remove) {
			b.splice(
				b.findIndex((x) => x.id === r.id),
				1,
			);
		}

		setBody(b);
		setSelected([]); // reset selected items to 0

		// update filter
		if (filter) {
			const f = [...filter];

			for (const r of remove) {
				f.splice(
					f.findIndex((x) => x.id === r.id),
					1,
				);
			}

			setFilter(f);
		}
	}

	const tableStyle = ClassHelper(Style, {
		table: true,
	});

	const showSelectable =
		props.selectable &&
		props.bulkActions &&
		Object.keys(props.bulkActions).length &&
		selected.length;

	return (
		<Fragment>
			{props.search || showSelectable ? (
				<div className={Style.inputs}>
					{props.search && (
						<Search
							className={Style.search}
							callback={search}
							throttle={props.throttle}
						/>
					)}

					{showSelectable ? (
						<BulkActions
							actions={props.bulkActions}
							selected={selected}
							delete={deleteRowCallback}
						/>
					) : undefined}
				</div>
			) : undefined}

			{/* select all for mobile */}
			{showSelectable ? (
				<div className={Style.select_all}>
					<Checkbox
						option={t("global.table.action.select_all")}
						callback={selectAll}
						checked={selected.length === props.data?.length}
					/>
				</div>
			) : undefined}

			<table className={!props.naked && tableStyle}>
				{header && (
					<Header
						data={header}
						callback={sort}
						show={props.show}
						hide={props.hide}
						select={props.selectable ? selectAll : false}
						hasData={props.data?.length}
						selectAll={selected.length === props.data?.length}
						actions={props.actions}
					/>
				)}
				{body && (
					<Body
						data={filter ? filter : body}
						show={props.show}
						hide={props.hide}
						badge={props.badge}
						select={props.selectable ? select : false}
						selected={selected}
						actions={{
							edit: props.actions?.edit,
							view: props.actions?.view,
							delete: props.actions?.delete,
							email: props.actions?.email,
							custom: props.actions?.custom,
							download: props.actions?.download,
						}}
						callback={{
							edit: editRowCallback,
							delete: deleteRowCallback,
						}}
					/>
				)}
			</table>
		</Fragment>
	);
}
