import { useState } from 'react';
import type { Post, PostInteraction } from '../types';

interface UseLeadTrackingState {
  searchTerm: string;
  showLeadsPerPost: boolean;
  selectedLead: PostInteraction | null;
  selectedPost: Post | null;
  page: number;
  limit: number;
  syncingPosts: Set<string>;
  syncingProfiles: Set<string>;
  isSubmitting: boolean;
  setSearchTerm: (term: string) => void;
  setShowLeadsPerPost: (show: boolean) => void;
  setSelectedLead: (lead: PostInteraction | null) => void;
  setSelectedPost: (post: Post | null) => void;
  setPage: (page: number) => void;
  setLimit: (limit: number) => void;
  addSyncingPost: (postId: string) => void;
  removeSyncingPost: (postId: string) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setSyncingProfiles: React.Dispatch<React.SetStateAction<Set<string>>>
}

export const useLeadTrackingState = (): UseLeadTrackingState => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showLeadsPerPost, setShowLeadsPerPost] = useState(true);
  const [selectedLead, setSelectedLead] = useState<PostInteraction | null>(null);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [syncingPosts, setSyncingPosts] = useState<Set<string>>(new Set());
  const [syncingProfiles, setSyncingProfiles] = useState<Set<string>>(new Set());
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addSyncingPost = (postId: string) => {
    setSyncingPosts(prev => new Set(prev).add(postId));
  };

  const removeSyncingPost = (postId: string) => {
    setSyncingPosts(prev => {
      const newSet = new Set(prev);
      newSet.delete(postId);
      return newSet;
    });
  };

  return {
    searchTerm,
    showLeadsPerPost,
    selectedLead,
    selectedPost,
    page,
    limit,
    syncingPosts,
    syncingProfiles,
    isSubmitting,
    setSearchTerm,
    setShowLeadsPerPost,
    setSelectedLead,
    setSelectedPost,
    setPage,
    setLimit,
    addSyncingPost,
    removeSyncingPost,
    setSyncingProfiles,
    setIsSubmitting
  };
}; 