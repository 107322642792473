/* eslint-disable @typescript-eslint/no-explicit-any */
import type { LinkedInPost, Post } from "./types";

export const initialLinkedinUserPostStoringProcess = (): Promise<any> => {
	return new Promise((resolve, reject) => {
		try {
			if (window.chrome?.runtime) {
				window.chrome.runtime.sendMessage(
					import.meta.env.VITE_EXTENSION_ID,
					{ type: "initialLinkedinUserPostStoringProcess" },
					(response: { posts?: any; error?: string }) => {
						if (response?.posts) {
							resolve(response.posts);
						} else {
							reject(`Error scraping LinkedIn posts: ${response?.error}`);
						}
					},
				);
			} else {
				reject("Chrome runtime not available");
			}
		} catch (error) {
			console.error("Failed to scrape LinkedIn posts:", error);
			reject(error);
		}
	});
};

import {
	differenceInDays,
	startOfQuarter,
	startOfWeek,
	startOfYear,
} from "date-fns";

const now = new Date();

const tableTimeFrameOptions = [
	{
		label: "dashboard.collaborator_metrics.this_week",
		value: differenceInDays(now, startOfWeek(now)),
		type: "week",
	},
	{
		label: "dashboard.collaborator_metrics.last_7_days",
		value: 7,
	},
	{
		label: "dashboard.collaborator_metrics.last_14_days",
		value: 14,
	},
	{
		label: "dashboard.collaborator_metrics.last_28_days",
		value: 28,
	},
	{
		label: "dashboard.collaborator_metrics.last_30_days",
		value: 30,
	},
	{
		label: "dashboard.collaborator_metrics.last_60_days",
		value: 60,
	},
	{
		label: "dashboard.collaborator_metrics.last_90_days",
		value: 90,
	},
	{
		label: "dashboard.collaborator_metrics.quarter_to_date",
		value: differenceInDays(now, startOfQuarter(now)),
	},
	{
		label: "dashboard.collaborator_metrics.last_12_months",
		value: 365,
	},
	{
		label: "dashboard.collaborator_metrics.start_of_year",
		value: differenceInDays(now, startOfYear(now)),
	},
	{
		label: "dashboard.collaborator_metrics.custom_range",
		value: "custom",
		type: "custom",
	},
];

const sortedMiddleOptions = tableTimeFrameOptions
	.slice(1, -1)
	.sort((a, b) => (a.value as number) - (b.value as number));

export const sortedTableTimeFrameOptions = [
	tableTimeFrameOptions[0],
	...sortedMiddleOptions,
	tableTimeFrameOptions[tableTimeFrameOptions.length - 1],
];

export const getCompletionColor = (completion: number) => {
	if (completion >= 100) return "text-green-500";
	return "text-red-500";
};

export const getCompletionFillColor = (completion: number) => {
	if (completion >= 100) return "fill-green-500";
	if (completion >= 76) return "fill-lime-500";
	if (completion >= 51) return "fill-yellow-500";
	if (completion >= 26) return "fill-orange-500";
	if (completion >= 1) return "fill-red-500";
	return "fill-gray-200";
};

export const generateBlueHues = (count: number) => {
	const minHue = 190; // Minimum blue hue
	const maxHue = 250; // Maximum blue hue
	const hueStep = (maxHue - minHue) / count;
	const minDifference = 15; // Minimum difference between adjacent hues

	// Generate evenly spaced hue values
	const hues = Array.from({ length: count }, (_, i) => minHue + i * hueStep);

	// Adjust hues to ensure minimum difference
	for (let i = 1; i < hues.length; i++) {
		while (Math.abs(hues[i] - hues[i - 1]) < minDifference) {
			hues[i] = (hues[i] + minDifference) % 360;
		}
	}

	return hues;
};

export const calculateCompletion = (
	currentTotal: number,
	objectiveTotal: number,
) => {
	if (objectiveTotal === 0) {
		return 0;
	}
	return (currentTotal / objectiveTotal) * 100;
};

export const formatNumber = (num: number): string => {
	if (num >= 1_000_000) {
		return `${(num / 1_000_000).toFixed(1).replace(/\.0$/, "")}M`;
	}
	if (num >= 1_000) {
		return `${(num / 1_000).toFixed(1).replace(/\.0$/, "")}K`;
	}
	return num.toFixed(2).replace(/\.?0+$/, "");
};

export const calculateTrend = (currentTotal: number, previousTotal: number) => {
	if (previousTotal === 0) {
		return currentTotal > 0 ? 100 : 0;
	}
	return ((currentTotal - previousTotal) / previousTotal) * 100;
};

export const calculateExpectedValue = (
	initialValue: number,
	weeklyGrowthRate: number,
	weeksPassed: number,
) => {
	return initialValue * weeklyGrowthRate ** weeksPassed;
};

export const isLinkedInPost = (post: Post): post is LinkedInPost => {
	return "linkedin_id" in post;
};

export const getLinkedInPostLink = (linkedinId: string) => {
	return `https://www.linkedin.com/feed/update/urn:li:activity:${linkedinId}/`;
};
