/***
 *
 *   PROFILE
 *   Update the user profile or close the organization
 *
 **********/

import {
	Animate,
	AuthContext,
	Button,
	Card,
	Event,
	Form,
	OrganizationNav,
	ViewContext,
	useAPI,
	useNavigate,
} from "components/lib";
import { Fragment, useContext } from "react";

export function Profile(props) {
	const navigate = useNavigate();

	// context
	const authContext = useContext(AuthContext);
	const viewContext = useContext(ViewContext);

	// fetch
	const user = useAPI("/api/user");

	function closeOrganization() {
		viewContext.modal.show(
			{
				title: props.t("organization.profile.form.close_organization.title"),
				form: {},
				buttonText: props.t(
					"organization.profile.form.close_organization.button",
				),
				url: authContext.permission.owner ? "/api/organization" : "/api/user",
				method: "DELETE",
				destructive: true,
				text: props.t("organization.profile.form.close_organization.text"),
			},
			() => {
				// destory user
				Event("closed_organization");
				localStorage.clear();
				navigate("/signup");
			},
		);
	}

	return (
		<Fragment>
			<OrganizationNav />
			<Animate>
				<Card
					title={props.t("organization.profile.subtitle")}
					loading={user.loading}
					restrictWidth
				>
					{user?.data && (
						<Form
							buttonText={props.t("organization.profile.form.button")}
							url="/api/user"
							method="PATCH"
							inputs={{
								name: {
									label: props.t("organization.profile.form.name.label"),
									type: "text",
									required: true,
									value: user.data.name,
									errorMessage: props.t("organization.profile.form.name.error"),
								},
								email: {
									label: props.t("organization.profile.form.email.label"),
									type: "email",
									required: true,
									value: user.data.email,
									errorMessage: props.t(
										"organization.profile.form.email.error",
									),
								},
								avatar: {
									label: props.t("organization.profile.form.avatar.label"),
									type: "file",
									required: false,
									max: 1,
								},
								dark_mode: {
									label: props.t("organization.profile.form.dark_mode.label"),
									type: "switch",
									default: user.data.dark_mode,
								},
								...(user.data.permission === "owner" && {
									organization_name: {
										type: "text",
										label: props.t(
											"organization.profile.form.organization_name.label",
										),
										value: user.data.organization_name,
									},
								}),
								...(user.data.organizations?.length > 1 && {
									default_organization: {
										label: props.t(
											"organization.profile.form.default_organization.label",
										),
										type: "select",
										default: user.data.default_organization,
										options: user.data.organizations.map((x) => {
											return {
												value: x.id,
												label: x.name,
											};
										}),
									},
								}),
							}}
							callback={(res) => {
								const data = res.data.data;

								// update the organization name
								if (
									data.organization_name &&
									authContext.user?.organizations?.length > 0
								) {
									const organizations = [...authContext.user.organizations];
									organizations[
										organizations.findIndex(
											(x) => x.id === authContext.user.organization_id,
										)
									].name = data.organization_name;
									authContext.update({ organizations: organizations });
								}

								// update the user name
								if (data.name) authContext.update({ name: data.name });

								// update the avatr
								if (data.avatar) authContext.update({ avatar: data.avatar });

								// user changed email and needs to verify
								if (
									Object.prototype.hasOwnProperty.call(data, "verified") &&
									!data.verified
								) {
									authContext.update({ verified: false });
									navigate("/signup/verify");
								}

								// toggle dark mode
								if (Object.prototype.hasOwnProperty.call(data, "dark_mode")) {
									authContext.update({ dark_mode: data.dark_mode });

									data.dark_mode
										? document.querySelector("#app").classList.add("dark")
										: document.querySelector("#app").classList.remove("dark");
								}
							}}
						/>
					)}

					<Fragment>
						<br />
						<Button
							textOnly
							action={closeOrganization}
							lowercase
							text={props.t(
								"organization.profile.form.close_organization.title",
							)}
						/>
					</Fragment>
				</Card>
			</Animate>
		</Fragment>
	);
}
