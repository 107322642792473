import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import { useAPI } from "components/lib";
import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { Button } from "components/ui/button";
import {
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { ScrollArea } from "components/ui/scroll-area";
import { Separator } from "components/ui/separator";
import { Loader2 } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import type { GoalType } from "views/dashboard/types";

import { Plus, X } from "lucide-react";
import type { GoalUser } from "./goal-creation";

interface User {
	id: string;
	name: string;
}

interface Metric {
	name: string;
	label: string;
	editable?: boolean;
}

interface MetricValues {
	impressions: number;
	likes: number;
	comments: number;
	shares: number;
	posts: number;
}

interface WeeklyMetrics {
	[userId: string]: {
		[metricName: string]: number;
	};
}

export function ManageUsersDialog({ goal }: { goal: GoalType }) {
	const [users, setUsers] = useState<User[]>([]);
	const [orgUsers, setOrgUsers] = useState<User[]>([]);
	const [showAddUsers, setShowAddUsers] = useState(false);
	const [loading, setLoading] = useState(true);
	const [weeklyMetrics, setWeeklyMetrics] = useState<WeeklyMetrics>({});
	const [actionLoading, setActionLoading] = useState<string | null>(null);
	const [openDialogs, setOpenDialogs] = useState<Record<string, boolean>>({});
	const { toast } = useToast();
	const [postsMetric, setPostsMetric] = useState<Record<string, number>>({});

	const metrics: Metric[] = [
		{ name: "impressions", label: "Impressions" },
		{ name: "likes", label: "Likes" },
		{ name: "comments", label: "Comments" },
		{ name: "shares", label: "Shares" },
		{ name: "posts", label: "Posts", editable: true },
	];

	const defaultMetrics: MetricValues = {
		impressions: 1500,
		likes: 20,
		comments: 15,
		shares: 0,
		posts: 2,
	};

	const fetchGoalUsers = useCallback(async () => {
		try {
			const response = await axios.get(
				`/api/goal/get-users?goal_id=${goal.id}`,
			);
			setUsers(response.data);
			setLoading(false);
		} catch (error) {
			console.error("Error fetching goal users:", error);
			setLoading(false);
		}
	}, [goal.id]);

	const fetchOrgUsers = useCallback(async () => {
		try {
			const response = await axios.get("/api/organization/users");
			const users = response.data.data.users.filter(
				(user: GoalUser) =>
					// remove SoporteIBT
					user.id !== "a8437da7-b295-4d2f-8477-2b4c745da212",
			);
			setOrgUsers(users);
		} catch (error) {
			console.error("Error fetching org users:", error);
		}
	}, []);

	const { data: orgWeeklyMetrics } = useAPI(
		`/api/analytics/${goal.organization_id}/weekly-metrics`,
	);

	useEffect(() => {
		fetchGoalUsers();
		fetchOrgUsers();
		if (orgWeeklyMetrics) {
			setWeeklyMetrics(orgWeeklyMetrics);
		}
	}, [fetchGoalUsers, fetchOrgUsers, orgWeeklyMetrics]);

	const handleRemoveUser = async (userId: string, userName: string) => {
		try {
			setActionLoading(userId);
			await axios.post("/api/goal/remove-user", {
				goal_id: goal.id,
				user_id: userId,
			});
			await fetchGoalUsers();
			setShowAddUsers(false);
			setOpenDialogs((prev) => ({ ...prev, [userId]: false }));
			toast({
				title: `${userName} removed from the goal: ${goal.title}`,
				description: "The user has been successfully removed from the goal.",
			});
		} catch (error) {
			console.error("Error removing user:", error);
			toast({
				title: "Error",
				description: `Failed to remove the user ${userName} from the goal: ${goal.title}. Please try again.`,
				variant: "destructive",
			});
		} finally {
			setActionLoading(null);
		}
	};

	const handleAddUser = async (userId: string, userName: string) => {
		try {
			setActionLoading(userId);
			const userMetrics = metrics.reduce<Partial<MetricValues>>(
				(acc, metric) => {
					if (metric.name in defaultMetrics) {
						acc[metric.name as keyof MetricValues] = metric.editable
							? postsMetric[userId] ||
								defaultMetrics[metric.name as keyof MetricValues]
							: weeklyMetrics[userId]?.[metric.name] ||
								defaultMetrics[metric.name as keyof MetricValues];
					}
					return acc;
				},
				{},
			);
			await axios.post("/api/goal/add-user", {
				goal_id: goal.id,
				user_id: userId,
				metrics: userMetrics,
			});
			await fetchGoalUsers();
			setShowAddUsers(false);
			setOpenDialogs((prev) => ({ ...prev, [userId]: false }));
			toast({
				title: `${userName} added to the goal: ${goal.title}`,
				description: "The user has been successfully added to the goal.",
			});
		} catch (error) {
			console.error("Error adding user:", error);
			toast({
				title: "Error",
				description: `Failed to add the user ${userName} to the goal: ${goal.title}. Please try again.`,
				variant: "destructive",
			});
		} finally {
			setActionLoading(null);
		}
	};

	const nonGoalUsers = orgUsers.filter(
		(user) => !users.some((goalUser) => goalUser.id === user.id),
	);

	return (
		<DialogContent className="max-h-[60vh] flex flex-col">
			<DialogHeader>
				<DialogTitle>
					{showAddUsers
						? `Add Users to goal: ${goal.title}`
						: `Remove Users from goal: ${goal.title}`}
				</DialogTitle>
				<DialogDescription>
					{showAddUsers
						? "Add users to the goal to allow them to view and contribute to the goal. Shown metrics are the user's weekly initial values."
						: "Remove users from the goal to prevent them from viewing and contributing to the goal."}
				</DialogDescription>
			</DialogHeader>
			<ScrollArea className="h-[40vh]">
				{showAddUsers ? (
					<div className="pr-4">
						{nonGoalUsers.map((user) => (
							<React.Fragment key={user.id}>
								<div
									key={user.id}
									className="flex justify-between items-center my-2"
								>
									<div className="flex flex-col w-full">
										<h4>{user.name}</h4>
										<div className="grid grid-cols-5 text-sm text-gray-500 mt-1">
											{metrics.map((metric) => (
												<div key={metric.name} className="flex flex-col">
													<span>{metric.label}:</span>
													{metric.editable ? (
														<Input
															type="number"
															value={
																postsMetric[user.id] ||
																defaultMetrics[
																	metric.name as keyof MetricValues
																]
															}
															onChange={(e) =>
																setPostsMetric((prev) => ({
																	...prev,
																	[user.id]:
																		Number(e.target.value) ||
																		defaultMetrics[
																			metric.name as keyof MetricValues
																		],
																}))
															}
															className="w-16 h-6 px-1 py-0"
														/>
													) : (
														<span>
															{Math.round(
																weeklyMetrics[user.id]?.[metric.name] ||
																	defaultMetrics[
																		metric.name as keyof MetricValues
																	],
															)}
														</span>
													)}
												</div>
											))}
										</div>
									</div>
									<AlertDialog
										open={openDialogs[user.id]}
										onOpenChange={(isOpen) =>
											setOpenDialogs((prev) => ({ ...prev, [user.id]: isOpen }))
										}
									>
										<AlertDialogTrigger asChild>
											<Button variant="outline" size="icon">
												<Plus className="h-4 w-4" />
											</Button>
										</AlertDialogTrigger>
										<AlertDialogContent>
											<AlertDialogHeader>
												<AlertDialogTitle>Are you sure?</AlertDialogTitle>
												<AlertDialogDescription>
													This will add the <strong>{user.name}</strong> to the
													goal: <strong>{goal.title}</strong>
												</AlertDialogDescription>
											</AlertDialogHeader>
											<AlertDialogFooter>
												<AlertDialogCancel>Cancel</AlertDialogCancel>
												<AlertDialogAction
													onClick={(e) => {
														e.preventDefault();
														handleAddUser(user.id, user.name);
													}}
													disabled={actionLoading === user.id}
												>
													{actionLoading === user.id ? (
														<Loader2 className="h-4 w-4 animate-spin mr-2" />
													) : null}
													Add
												</AlertDialogAction>
											</AlertDialogFooter>
										</AlertDialogContent>
									</AlertDialog>
								</div>
								<Separator className="my-2" />
							</React.Fragment>
						))}
					</div>
				) : (
					<div className="pr-4">
						{loading ? (
							<p>Loading users...</p>
						) : (
							users.map((user) => (
								<React.Fragment key={user.id}>
									<div className="flex justify-between items-center my-2">
										<h4>{user.name}</h4>
										<AlertDialog
											open={openDialogs[user.id]}
											onOpenChange={(isOpen) =>
												setOpenDialogs((prev) => ({
													...prev,
													[user.id]: isOpen,
												}))
											}
										>
											<AlertDialogTrigger asChild>
												<Button variant="outline" size="icon">
													<X className="h-4 w-4" />
												</Button>
											</AlertDialogTrigger>
											<AlertDialogContent>
												<AlertDialogHeader>
													<AlertDialogTitle>Are you sure?</AlertDialogTitle>
													<AlertDialogDescription>
														This will remove the <strong>{user.name}</strong>{" "}
														from the goal: <strong>{goal.title}</strong>
													</AlertDialogDescription>
												</AlertDialogHeader>
												<AlertDialogFooter>
													<AlertDialogCancel>Cancel</AlertDialogCancel>
													<AlertDialogAction
														onClick={(e) => {
															e.preventDefault();
															handleRemoveUser(user.id, user.name);
														}}
														disabled={actionLoading === user.id}
													>
														{actionLoading === user.id ? (
															<Loader2 className="h-4 w-4 animate-spin mr-2" />
														) : null}
														Remove
													</AlertDialogAction>
												</AlertDialogFooter>
											</AlertDialogContent>
										</AlertDialog>
									</div>
									<Separator className="my-2" />
								</React.Fragment>
							))
						)}
					</div>
				)}
			</ScrollArea>
			<div className="mt-4">
				{showAddUsers ? (
					<Button onClick={() => setShowAddUsers(false)}>
						Back to Goal Users
					</Button>
				) : (
					<Button onClick={() => setShowAddUsers(true)}>Add New User</Button>
				)}
			</div>
		</DialogContent>
	);
}
