import { Skeleton } from "components/ui/skeleton";

export const SkeletonTable = () => {
	return (
		<div className="space-y-4 py-4">
			<Skeleton className="h-10 w-1/2" />
			<Skeleton className="h-10 w-full" />
			<Skeleton className="h-8 w-full" />
			<Skeleton className="h-8 w-full" />
			<Skeleton className="h-8 w-full" />
			<Skeleton className="h-8 w-full" />
			<Skeleton className="h-8 w-full" />
			<Skeleton className="h-8 w-full" />
			<Skeleton className="h-8 w-full" />
			<Skeleton className="h-8 w-full" />
			<Skeleton className="h-8 w-full" />
			<Skeleton className="h-8 w-full" />
		</div>
	);
};
