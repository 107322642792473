import {
  FormLabel
} from "components/ui/form";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { HelpCircle } from "lucide-react";

interface FormLabelWithTooltipProps {
  label: string;
  tooltip: string;
  className?: string;
}

export const FormLabelWithTooltip = ({ 
  label, 
  tooltip,
  className 
}: FormLabelWithTooltipProps) => (
  <TooltipProvider delayDuration={0}>
    <Tooltip>
      <TooltipTrigger asChild>
        <div className="flex items-center gap-2 cursor-help">
          <FormLabel className={`cursor-help ${className || ''}`}>{label}</FormLabel>
          <HelpCircle className="h-4 w-4 text-muted-foreground" />
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <p className="max-w-xs">{tooltip}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
); 