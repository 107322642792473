import type {
	LinkedinMetrics,
	Platform,
	WordPressMetrics,
} from "types/metricTypes";
import { MetricPieChart } from "./metric-pie-chart";
import { SkeletonPieChart } from "./skeleton-pie-chart";

interface MetricPieChartsProps {
	metrics: Array<{
		user_id: string;
		user_name: string;
		linkedin: LinkedinMetrics;
		wordpress: WordPressMetrics;
	}>;
	loading: boolean;
	platform: Platform;
}

export const MetricsPieCharts = ({
	metrics,
	loading,
	platform,
}: MetricPieChartsProps) => {
	if (loading || (!loading && !metrics)) {
		return (
			<div className="flex items-center justify-between w-full">
				<SkeletonPieChart />
				<SkeletonPieChart />
				<SkeletonPieChart />
				<SkeletonPieChart />
			</div>
		);
	}

	if (platform === "linkedin") {
		return (
			<div className="flex items-center justify-between w-full">
				<MetricPieChart
					metrics={metrics}
					platform="linkedin"
					metricKey="posts"
					title="Posts"
					description="Distribution of posts among contributors"
					key="pie-posts"
				/>
				<MetricPieChart
					metrics={metrics}
					platform="linkedin"
					metricKey="impressions"
					title="Impressions"
					description="Distribution of impressions among contributors"
					key="pie-impressions"
				/>
				<MetricPieChart
					metrics={metrics}
					platform="linkedin"
					metricKey="interactions"
					title="Interactions"
					description="Distribution of interactions among contributors"
					key="pie-interactions"
				/>
				<MetricPieChart
					metrics={metrics}
					platform="linkedin"
					metricKey="comments"
					title="Comments"
					description="Distribution of comments among contributors"
					key="pie-comments"
				/>
			</div>
		);
	}

	return (
		<div className="flex items-center justify-between w-full">
			<MetricPieChart
				metrics={metrics}
				platform="wordpress"
				metricKey="blogs"
				title="Blogs"
				description="Distribution of blogs among contributors"
				key="pie-blogs"
			/>
			<MetricPieChart
				metrics={metrics}
				platform="wordpress"
				metricKey="impressions"
				title="Impressions"
				description="Distribution of impressions among contributors"
				key="pie-impressions-blog"
			/>
			<MetricPieChart
				metrics={metrics}
				platform="wordpress"
				metricKey="clicks"
				title="Clicks"
				description="Distribution of clicks among contributors"
				key="pie-clicks"
			/>
			<MetricPieChart
				metrics={metrics}
				platform="wordpress"
				metricKey="ctr"
				title="CTR"
				description="Distribution of CTR among contributors"
				key="pie-ctr"
			/>
		</div>
	);
};
