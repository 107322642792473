const Style = {
	file: "mb-4",
	dropzone:
		"relative text-center overflow-y-scroll h-32 px-2 py-4 border border-dashed border-slate-300 dark:border-slate-600",
	dragging: "rounded border border-dashed border-emerald-100",
	success: "border border-dashed border-emerald-500",
	label: "absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2",
	labelIcon: "inline-block mr-2",
	labelText: "inline-block text-xs",
	legacyInput: "hidden",
	error: "mb-0 border border-dashed border-red-500 bg-red-50",
	fileListItem: "inline-block relative mt-2 mr-2 pr-5 text-sm",
	fileListButton: "!absolute top-1/2 -translate-y-1/2 right-0 w-1 h-1 mt-[1px]",
};

export default Style;
