/***
 *
 *   HOVER NAV
 *   Reveals a nav when the user hovers over a hotspot
 *   Items are rendered as children, revealed with CSS
 *
 *   PROPS
 *   align: left or right (string, required)
 *   children: will be passed from router > view > here (component(s), required)
 *   className: custom styling (SCSS or tailwind style, optional)
 *   label: the hotspot text (string, required)
 *   icon: icon image (string, optional)
 *
 **********/

import { Animate, ClassHelper, Icon } from "components/lib";
import { cloneElement, useState } from "react";

import Style from "./hover.tailwind.js";

export function HoverNav(props) {
	// state
	const [open, setOpen] = useState(false);

	// style
	const css = ClassHelper(Style, {
		wrapper: true,
		[props.align]: true,
		className: props.className,
	});

	return (
		<div
			className={css}
			onMouseEnter={() => setOpen(true)}
			onMouseLeave={() => setOpen(false)}
		>
			{props.label && <span className={Style.label}>{props.label}</span>}

			{props.icon && (
				<Icon image={props.icon} size={14} className={Style.icon} />
			)}

			{open && props.children?.length && (
				<Animate type="slidedown" timeout={50}>
					<nav className={Style.nav}>
						{props.children.map((button) => {
							return cloneElement(button, { className: Style.button });
						})}
					</nav>
				</Animate>
			)}
		</div>
	);
}
