import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Clock, CheckCircle2, AlertCircle, Loader2 } from "lucide-react";
import type { LinkedinPostType, LinkedPostMapping } from "../../../../types";
import { cn } from "lib/utils";

interface StatsCardsProps {
  posts: LinkedinPostType[];
  linkedPosts: LinkedPostMapping[];
  linkedinPostCount: number;
  t: (key: string) => string;
}

export const StatsCards = ({ posts, linkedPosts, linkedinPostCount, t }: StatsCardsProps) => {
  const stats = {
    inProgress: linkedPosts?.filter(post => post.status === "pending").length || 0,
    done: linkedPosts?.filter(post => post.status === "publish").length || 0,
    error: linkedPosts?.filter(post => post.status === "failed").length || 0,
    pending: linkedinPostCount - (linkedPosts?.filter(post => post.status === "publish").length || 0),
  };

  return (
    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4 mb-6">
      <Card className="relative overflow-hidden">
        <div className="absolute inset-0 bg-yellow-100/50" />
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 relative">
          <CardTitle className="text-sm font-medium text-yellow-700">
            {t("content-creation.blog_from_posts_bulk.pending_status")}
          </CardTitle>
          <Clock className="h-4 w-4 text-yellow-700" />
        </CardHeader>
        <CardContent className="relative">
          <div className="text-2xl font-bold text-yellow-900">{stats.inProgress}</div>
          <div className="absolute bottom-0 right-0 translate-x-1/4 translate-y-1/4">
            <Clock className="h-24 w-24 text-yellow-200" />
          </div>
        </CardContent>
      </Card>

      <Card className="relative overflow-hidden">
        <div className="absolute inset-0 bg-green-100/50" />
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 relative">
          <CardTitle className="text-sm font-medium text-green-700">
            {t("content-creation.blog_from_posts_bulk.published_status")}
          </CardTitle>
          <CheckCircle2 className="h-4 w-4 text-green-700" />
        </CardHeader>
        <CardContent className="relative">
          <div className="text-2xl font-bold text-green-900">{stats.done}</div>
          <div className="absolute bottom-0 right-0 translate-x-1/4 translate-y-1/4">
            <CheckCircle2 className="h-24 w-24 text-green-200" />
          </div>
        </CardContent>
      </Card>

      <Card className="relative overflow-hidden">
        <div className="absolute inset-0 bg-red-100/50" />
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 relative">
          <CardTitle className="text-sm font-medium text-red-700">
            {t("content-creation.blog_from_posts_bulk.failed_status")}
          </CardTitle>
          <AlertCircle className="h-4 w-4 text-red-700" />
        </CardHeader>
        <CardContent className="relative">
          <div className="text-2xl font-bold text-red-900">{stats.error}</div>
          <div className="absolute bottom-0 right-0 translate-x-1/4 translate-y-1/4">
            <AlertCircle className="h-24 w-24 text-red-200" />
          </div>
        </CardContent>
      </Card>

      <Card className="relative overflow-hidden">
        <div className="absolute inset-0 bg-orange-100/50" />
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 relative">
          <CardTitle className="text-sm font-medium text-orange-700">
            {t("content-creation.blog_from_posts_bulk.unpublished_status")}
          </CardTitle>
          <Loader2 className="h-4 w-4 text-orange-700" />
        </CardHeader>
        <CardContent className="relative">
          <div className="text-2xl font-bold text-orange-900">{stats.pending}</div>
          <div className="absolute bottom-0 right-0 translate-x-1/4 translate-y-1/4">
            <Loader2 className="h-24 w-24 text-orange-200" />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}; 