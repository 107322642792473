import { Teams } from "views/teams/teams";

const routes = [
	{
		path: "/teams",
		view: Teams,
		layout: "app",
		permission: "admin",
		title: "Team",
	},
];

export default routes;
