import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "components/ui/pagination";

interface TablePaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const TablePagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: TablePaginationProps) => {
  return (
    <Pagination>
      <PaginationContent className="list-none">
        <PaginationItem>
          <PaginationPrevious
            onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
            aria-disabled={currentPage === 1}
            className={currentPage === 1 ? "pointer-events-none opacity-50" : ""}
          />
        </PaginationItem>
        {Array.from({ length: totalPages }, (_, i) => i + 1)
          .filter(page => {
            if (totalPages <= 5) return true;
            
            // First page: show next 4
            if (currentPage === 1) return page <= 5;
            
            // Second page: show previous 1 and next 3
            if (currentPage === 2) return page >= 1 && page <= 5;
            
            // Second-to-last page: show previous 3 and next 1
            if (currentPage === totalPages - 1) return page >= totalPages - 4 && page <= totalPages;
            
            // Last page: show previous 4
            if (currentPage === totalPages) return page > totalPages - 5;
            
            // Otherwise show current page and 2 pages before/after
            return Math.abs(currentPage - page) <= 2;
          })
          .sort((a, b) => a - b)
          .map((page, index, array) => (
            <PaginationItem key={page}>
              <PaginationLink
                onClick={() => onPageChange(page)}
                isActive={currentPage === page}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}
        <PaginationItem>
          <PaginationNext
            onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
            aria-disabled={currentPage === totalPages}
            className={currentPage === totalPages ? "pointer-events-none opacity-50" : ""}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
}; 