import "./css/output.css";

import "./instrument";
import App from "app/app";
import { Toaster } from "components/ui/toaster";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
// Sentry.init({
// 	dsn: "https://c6652b5ab611130f9e56f08ae8b6739f@o4507658183180288.ingest.us.sentry.io/4507658187571200",
// 	tunnel: `${appConfig.API_MAIN_URL}/sentry-tunnel`,
// 	integrations: [
// 		Sentry.browserTracingIntegration(),
// 		Sentry.replayIntegration(),
// 	],
// 	// Performance Monitoring
// 	tracesSampleRate: 1.0, //  Capture 100% of the transactions
// 	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
// 	tracePropagationTargets: [
// 		"localhost",
// 		/^https:\/\/backoffice-server-production\.up\.railway\.app\/api/,
// 	],
// 	// Session Replay
// 	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
// 	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const OLD_DOMAIN = "inbound-tools.up.railway.app";
const NEW_DOMAIN = "app.inbound-tools.com";

if (window.location.hostname === OLD_DOMAIN) {
	window.location.replace(
		`https://${NEW_DOMAIN}${window.location.pathname}${window.location.search}`,
	);
} else {
	// biome-ignore lint/style/noNonNullAssertion: <Gravity>
	ReactDOM.createRoot(document.querySelector("#root")!).render(
		<Sentry.ErrorBoundary>
		<PostHogProvider client={posthog}>
			<React.StrictMode>
				<App />
				<Toaster />
				</React.StrictMode>
			</PostHogProvider>
		</Sentry.ErrorBoundary>,
	);
}
