import { Alert, AlertDescription } from "components/ui/alert";
import { Button } from "components/ui/button";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "components/ui/select";
import { Textarea } from "components/ui/textarea";
import { ImageIcon, VideoIcon, XIcon } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useState } from "react";
import settings from "settings.json";

const mode = import.meta.env.MODE as "development" | "production";

interface Organization {
	id: string;
	name: string;
}

interface User {
	id: string;
	name: string;
	email: string;
}

interface ApiResponse<T> {
	data: T;
}

const formatText = (text: string) => {
	return text.split("\n").map((line, index) => (
		<React.Fragment key={index}>
			{line}
			<br />
		</React.Fragment>
	));
};

const Page = () => {
	const posthog = usePostHog();
	const [postContent, setPostContent] = useState("");
	const [mediaFiles, setMediaFiles] = useState<File[]>([]);
	const [organizations, setOrganizations] = useState<Organization[]>([]);
	const [selectedOrg, setSelectedOrg] = useState<string>("");
	const [users, setUsers] = useState<User[]>([]);
	const [selectedUser, setSelectedUser] = useState<string>("");
	const [isConnectedToLinkedIn, setIsConnectedToLinkedIn] = useState<
		boolean | null
	>(null);
	const [isPublishing, setIsPublishing] = useState(false);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (posthog) {
			posthog.capture("publish_to_linkedin_flow_started");
		}
	}, [posthog]);

	useEffect(() => {
		fetchOrganizations();
	}, []);

	useEffect(() => {
		if (selectedOrg) {
			fetchUsers(selectedOrg);
		}
	}, [selectedOrg]);

	useEffect(() => {
		if (selectedUser) {
			checkLinkedInConnection(selectedUser);
		}
	}, [selectedUser]);

	const fetchOrganizations = async () => {
		try {
			const response = await fetch(
				`${settings[mode].server_url}/api/organization/all`,
			);
			const organizations: ApiResponse<Organization[]> = await response.json();

			if (organizations?.data) {
				setOrganizations(organizations.data);
			} else {
				setError("Failed to fetch organizations");
			}
		} catch (error) {
			console.error("Error fetching organizations:", error);
			setError("Failed to fetch organizations");
		}
	};

	const fetchUsers = async (orgId: string) => {
		try {
			const response = await fetch(
				`${settings[mode].server_url}/api/organization/${orgId}/users`,
			);
			const users: ApiResponse<User[]> = await response.json();
			if (users.data) {
				setUsers(users.data);
			} else {
				setError("Failed to fetch users");
			}
		} catch (error) {
			console.error("Error fetching users:", error);
			setError("Failed to fetch users");
		}
	};

	const checkLinkedInConnection = async (userId: string) => {
		try {
			const response = await fetch(
				`${settings[mode].server_url}/api/user/is-connected-to-linkedin/${userId}`,
			);
			const result: boolean = await response.json();
			setIsConnectedToLinkedIn(result);
		} catch (error) {
			console.error("Error checking LinkedIn connection:", error);
			setError("Failed to check LinkedIn connection");
		}
	};

	const handleMediaUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target.files || []);
		const isVideo = files.some((file) => file.type.startsWith("video/"));

		if (isVideo && files.length > 1) {
			setError("You can only upload 1 video");
			return;
		}

		if (!isVideo && files.length + mediaFiles.length > 20) {
			setError("You can only upload up to 20 images");
			return;
		}

		setMediaFiles((prevFiles) =>
			[...prevFiles, ...files].slice(0, isVideo ? 1 : 20),
		);
		setError(null);
	};

	const removeMedia = (index: number) => {
		setMediaFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
	};

	const handlePublish = async () => {
		setIsPublishing(true);
		setError(null);

		try {
			const formData = new FormData();
			formData.append("content", postContent);
			mediaFiles.forEach((file) => {
				formData.append(`media`, file);
			});
			formData.append("userId", selectedUser);
			formData.append("organizationId", selectedOrg);

			const response = await fetch(
				`${settings[mode].server_url}/api/linkedin/publish`,
				{
					method: "POST",
					body: formData,
				},
			);

			if (response.ok) {
				alert("Post published successfully!");
				setPostContent("");
				setMediaFiles([]);
			} else {
				const errorData = await response.json();
				throw new Error(errorData.error || "Failed to publish post");
			}

			if (posthog) {
				posthog.capture("published_to_linkedin_flow_finished");
			}
		} catch (error) {
			console.error("Error publishing post:", error);
			setError(
				error instanceof Error
					? error.message
					: "Failed to publish post. Please try again.",
			);
		} finally {
			setIsPublishing(false);
		}
	};

	return (
		<main className="grid gap-4 mx-auto max-w-screen-xl lg:grid-cols-2">
			<Card className="flex flex-col gap-4">
				<CardHeader>
					<CardTitle>Create Post</CardTitle>
					<CardDescription>Compose your LinkedIn post here.</CardDescription>
				</CardHeader>
				<CardContent>
					<div className="grid gap-4">
						<Textarea
							placeholder="Write your post..."
							className="resize-none"
							rows={4}
							value={postContent}
							onChange={(e) => setPostContent(e.target.value)}
						/>
						<div className="flex gap-2 items-center">
							<Button
								variant="ghost"
								size="icon"
								onClick={() => document.getElementById("imageUpload")?.click()}
							>
								<ImageIcon className="w-4 h-4" />
								<span className="sr-only">Upload Image</span>
							</Button>
							<Button
								variant="ghost"
								size="icon"
								onClick={() => document.getElementById("videoUpload")?.click()}
							>
								<VideoIcon className="w-4 h-4" />
								<span className="sr-only">Upload Video</span>
							</Button>
							<input
								id="imageUpload"
								type="file"
								accept="image/*"
								multiple
								style={{ display: "none" }}
								onChange={handleMediaUpload}
							/>
							<input
								id="videoUpload"
								type="file"
								accept="video/*"
								style={{ display: "none" }}
								onChange={handleMediaUpload}
							/>
						</div>
						<div className="flex flex-wrap gap-2">
							{mediaFiles.map((file, index) => (
								<div key={index} className="relative">
									{file.type.startsWith("image/") ? (
										<img
											src={URL.createObjectURL(file)}
											alt={`Upload ${index + 1}`}
											className="object-cover w-20 h-20 rounded"
										/>
									) : (
										<video
											src={URL.createObjectURL(file)}
											className="object-cover w-20 h-20 rounded"
										/>
									)}
									<Button
										variant="destructive"
										size="icon"
										className="absolute top-0 right-0 w-5 h-5"
										onClick={() => removeMedia(index)}
									>
										<XIcon className="w-3 h-3" />
									</Button>
								</div>
							))}
						</div>
					</div>
				</CardContent>
			</Card>
			<Card className="flex flex-col gap-4">
				<CardHeader>
					<CardTitle>Preview</CardTitle>
					<CardDescription>
						See how your post will look before publishing.
					</CardDescription>
				</CardHeader>
				<CardContent>
					<div className="grid gap-4">
						<div className="flex gap-4 items-center">
							<div>
								<p className="text-sm font-medium leading-none">
									{users.find((user) => user.id === selectedUser)?.name || ""}
								</p>
							</div>
						</div>
						<div className="grid gap-2">
							<div className="whitespace-pre-wrap">
								{formatText(postContent)}
							</div>
							{mediaFiles.map((file, index) => (
								<div key={index}>
									{file.type.startsWith("image/") ? (
										<img
											src={URL.createObjectURL(file)}
											alt={`Preview ${index + 1}`}
											className="max-w-full h-auto rounded-md"
										/>
									) : (
										<video
											src={URL.createObjectURL(file)}
											controls
											className="max-w-full h-auto rounded-md"
										/>
									)}
								</div>
							))}
						</div>
					</div>
				</CardContent>
				<CardFooter className="flex flex-col gap-3">
					<div className="flex gap-4 justify-between w-full">
						<Select value={selectedOrg} onValueChange={setSelectedOrg}>
							<SelectTrigger>
								<SelectValue placeholder="Select Organization" />
							</SelectTrigger>
							<SelectContent>
								{organizations.map((org) => (
									<SelectItem key={org.id} value={org.id}>
										{org.name}
									</SelectItem>
								))}
							</SelectContent>
						</Select>
						<Select value={selectedUser} onValueChange={setSelectedUser}>
							<SelectTrigger>
								<SelectValue placeholder="Select User" />
							</SelectTrigger>
							<SelectContent>
								{users.map((user) => (
									<SelectItem key={user.id} value={user.id}>
										{user.name} ({user.email})
									</SelectItem>
								))}
							</SelectContent>
						</Select>
						<Button
							onClick={handlePublish}
							disabled={!isConnectedToLinkedIn || isPublishing}
						>
							{isPublishing ? "Publishing..." : "Publish"}
						</Button>
					</div>
					{selectedUser && !isConnectedToLinkedIn && (
						<Alert variant="destructive">
							<AlertDescription>
								The selected user is not connected to LinkedIn. Please ask them
								to connect their LinkedIn account.
							</AlertDescription>
						</Alert>
					)}
					{error && (
						<Alert variant="destructive">
							<AlertDescription>{error}</AlertDescription>
						</Alert>
					)}
				</CardFooter>
			</Card>
		</main>
	);
};

export default Page;
