import { Skeleton } from "components/ui/skeleton";

export const SkeletonTabs = () => {
	return (
		<div className="w-full">
			<div className="grid w-full grid-cols-2 rounded-lg bg-muted p-1 h-10">
				<Skeleton className="h-8 rounded-md" />
				<Skeleton className="h-8 rounded-md" />
			</div>
		</div>
	);
};
