/***
 *
 *   IMPERSONATE
 *   Allows a Master organization to log into a user organization via Mission Control
 *
 **********/

import Axios from "axios";
import {
	Animate,
	AuthContext,
	Message,
	Row,
	useLocation,
	useNavigate,
} from "components/lib";
import React, { useContext, useEffect, useRef } from "react";

export function ImpersonateSignin(props) {
	const navigate = useNavigate();
	const location = useLocation();
	const qs = location.search;
	const context = useRef(useContext(AuthContext));

	useEffect(() => {
		const verifyToken = async (token) => {
			try {
				const res = await Axios.post("/api/auth/impersonate", { token: token });
				res.status === 200
					? navigate(context.current.signin(res))
					: navigate("/signin");
			} catch {
				navigate("/signin");
			}
		};

		if (qs.includes("?token=")) {
			// check token exists
			verifyToken(qs.slice(Math.max(0, qs.indexOf("?token=") + 7)));
		} else {
			navigate("/404");
		}
	}, [qs, context, navigate]);

	return (
		<Animate>
			<Row>
				<div style={{ width: "28em", margin: "0 auto" }}>
					<Message
						type="info"
						title={props.t("auth.signin.impersonate.message.title")}
						text={props.t("auth.signin.impersonate.message.text")}
					/>
				</div>
			</Row>
		</Animate>
	);
}
