/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { useExtensionInstalled } from "views/dashboard/_hooks/use-extension-installed";
import { useLinkedinProfileId } from "views/dashboard/_hooks/use-linkedin-profile-id";

import { interactWithExtension } from "lib/utils";
import { useEffect, useState } from "react";
import {
	Alert,
	AlertDescription,
	AlertTitle,
} from "../../../components/ui/alert";

const ExtensionMessage = ({ text }: any) => {
	const { extensionInstalled, loading: loadingExtension } =
		useExtensionInstalled(2000);

	const { profileId, loading: loadingProfile } = useLinkedinProfileId(2000);

	if (!extensionInstalled && !loadingExtension) {
		return (
			<a
				href="https://chromewebstore.google.com/detail/inbound-tools/lmdidkfogbencnidkgoohehjlmagaplk"
				target="_blank"
				rel="noreferrer"
				className="block my-5"
			>
				<Alert variant={"destructive"}>
					<ExclamationTriangleIcon color="white" />
					<AlertTitle>Extension not installed</AlertTitle>
					<AlertDescription className="flex justify-between items-center">
						<span>
							{text
								? text
								: "Please install the Inbound Tools extension to gain access to your metrics. Click here to install."}
						</span>
					</AlertDescription>
				</Alert>
			</a>
		);
	}
	if (!profileId && !loadingProfile) {
		return (
			<a
				href="https://www.linkedin.com/login"
				target="_blank"
				rel="noreferrer"
				className="block my-5"
			>
				<Alert variant={"destructive"}>
					<ExclamationTriangleIcon color="white" />
					<AlertTitle>You are not logged in to linkedin</AlertTitle>
					<AlertDescription className="flex justify-between items-center">
						<span>
							Please login to LinkedIn to gain access to your metrics. Click
							here to Login.
						</span>
					</AlertDescription>
				</Alert>
			</a>
		);
	}

	return;
};

export default ExtensionMessage;
