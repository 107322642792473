import { useMutation } from "@tanstack/react-query";
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "components/ui/accordion";
import {
	Card,
	CardContent,
	CardDescription,
	CardFooter,
	CardHeader,
	CardTitle,
} from "components/ui/card";
import { AlertCircle, CheckCircle } from "lucide-react";
import { usePostHog } from "posthog-js/react";
import { appConfig } from "../config";

type Props = {
	text: string;
};

type BenchmarkResult = {
	originalityai: {
		ai_score: number;
		items: {
			text: string;
			prediction: string;
			ai_score: number;
			ai_score_detail: number;
		}[];
		status: string;
		cost: number;
	};
};

const AIContentDetection = ({ text }: Props) => {
	const posthog = usePostHog();
	const {
		data: benchmarkResults,
		isPending: isBenchmarkResultsPending,
		mutate: mutateBenchmarkResults,
	} = useMutation({
		mutationFn: async (text: string) => {
			try {
				const result = await fetch(
					`${appConfig.API_MAIN_URL}/api/ai-content-detection/benchmark`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({ text }),
					},
				);
				const benchmark: BenchmarkResult = await result.json();
				if (posthog) {
					posthog.capture("AI Detection Benchmark", {
						text: text.substring(0, 100),
						ai_score: benchmark?.originalityai?.ai_score,
					});
				}

				return benchmark;
			} catch (error) {
				console.error("Error:", error);
				return null;
			}
		},
	});

	const handleMutate = () => {
		mutateBenchmarkResults(text);
	};

	const aiScore = benchmarkResults?.originalityai?.ai_score ?? 0;
	const isAIGenerated = aiScore > 0.5;
	const scoreColor = isAIGenerated ? "text-red-500" : "text-green-500";
	const scorePercentage = (aiScore * 100).toFixed(2);

	const highScoredItems =
		benchmarkResults?.originalityai?.items.filter(
			(item) => item.ai_score > 0.5,
		) ?? [];

	return (
		<Card className="my-3 mb-6 w-full">
			<CardHeader>
				<CardTitle>AI Content Detection</CardTitle>
				<CardDescription>
					Analyze your text for potential AI-generated content
				</CardDescription>
			</CardHeader>
			<CardContent>
				<button
					type="button"
					onClick={handleMutate}
					className="px-4 py-2 mb-4 text-white bg-blue-500 rounded transition-colors hover:bg-blue-600"
				>
					Analyze Text
				</button>

				{isBenchmarkResultsPending ? (
					<div>Analyzing...</div>
				) : benchmarkResults ? (
					<div>
						<div className="flex items-center mb-4 space-x-2">
							<div className={`text-2xl font-bold ${scoreColor}`}>
								{scorePercentage}%
							</div>
							{isAIGenerated ? (
								<AlertCircle className="w-6 h-6 text-red-500" />
							) : (
								<CheckCircle className="w-6 h-6 text-green-500" />
							)}
							<div className="text-sm text-gray-500">
								{isAIGenerated ? "Likely AI-generated" : "Likely human-written"}
							</div>
						</div>

						{highScoredItems.length > 0 && (
							<Accordion type="single" collapsible className="w-full">
								<AccordionItem value="high-scored-items">
									<AccordionTrigger>
										View {highScoredItems.length} potentially AI-generated
										sections
									</AccordionTrigger>
									<AccordionContent>
										<ul className="space-y-2">
											{highScoredItems.map((item) => (
												<li key={item.text} className="pb-2 border-b">
													<div className="font-semibold">
														{(item.ai_score * 100).toFixed(2)}% AI Score
													</div>
													<div className="text-sm text-gray-600">
														{item.text}
													</div>
												</li>
											))}
										</ul>
									</AccordionContent>
								</AccordionItem>
							</Accordion>
						)}
					</div>
				) : null}
			</CardContent>
			<CardFooter className="text-sm text-gray-500">
				Powered by OriginalityAI
			</CardFooter>
		</Card>
	);
};

export default AIContentDetection;
