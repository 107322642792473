import { AuthContext } from "components/lib";
import { useContext } from "react";

export const GrowthFunnel = () => {
	// const auth: any = useContext(AuthContext);
	// const organization = auth.user.organizations.find(
	//   (org: any) => org.id === auth.user.organization_id,
	// );

	return (
		<main className="w-full py-1 md:py-2 lg:py-4">
			<div className="container px-4 md:px-6">
				<div className="space-y-2 text-center">
					<iframe
						title="Growth Funnel"
						width="1200"
						height="1200"
						src="https://lookerstudio.google.com/embed/reporting/a094db1d-4f88-45cf-8282-2e7d0efd8dda/page/p_bjj3aq3gid"
						style={{ border: 0 }}
						allowFullScreen
						sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
					/>
				</div>
			</div>
		</main>
	);
};
