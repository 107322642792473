/***
 *
 *   SOCIAL SIGN IN
 *   After social authentication has been completed on the server
 *   the user is redirected back to this view - which obtains
 *   the token and completes the signin flow as normal
 *
 **********/

import axios from "axios";
import {
	Animate,
	AuthContext,
	Card,
	Message,
	Row,
	ViewContext,
	useLocation,
	useNavigate,
} from "components/lib";
import React, { useContext, useEffect, useRef } from "react";

export function SocialSignin(props) {
	// wrap in useRef to prevent triggering useEffect multiple times
	const authContext = useRef(useContext(AuthContext));
	const viewContext = useRef(useContext(ViewContext));
	const navigate = useNavigate();
	const location = useLocation();

	const qs = location.search;
	const provider = qs.substring(qs.indexOf("?provider") + 10, qs.indexOf("&"));
	const token = qs.slice(Math.max(0, qs.indexOf("&token") + 7));

	useEffect(() => {
		async function fetchToken() {
			try {
				const res = await axios({
					method: "post",
					url: "/api/auth",
					data: { token: token },
				});
				res.data["2fa_required"]
					? navigate(`/signin/otp?token=${res.data.token}`)
					: navigate(authContext.current.signin(res));
			} catch (error) {
				viewContext.current.handleError(error);
			}
		}

		if (token) fetchToken();
	}, [token, viewContext, authContext, navigate]);

	return (
		<Animate type="pop">
			<Row
				title={`${props.t("auth.signin.social.title")} ${provider.charAt(0).toUpperCase() + provider.slice(1)}`}
			>
				<Card loading={token} transparent>
					{!token && (
						<div style={{ width: "32em", margin: "0 auto" }}>
							<Message
								type="error"
								title={props.t("auth.signin.social.message.title")}
								text={props.t("auth.signin.social.message.text")}
								buttonText={props.t("auth.signin.social.message.button")}
								buttonLink="/signin"
							/>
						</div>
					)}
				</Card>
			</Row>
		</Animate>
	);
}
