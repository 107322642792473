/***
 *
 *   usePlans hook
 *   fetch, format and return the price plans
 *
 **********/

import { useAPI } from "components/lib";
import { useEffect, useState } from "react";

export function usePlans() {
	const [state, setState] = useState({ data: null, loading: false });
	const plans = useAPI("/api/organization/plans");

	useEffect(() => {
		setState({ loading: true });

		// format plans
		if (plans?.data?.plans) {
			const formatted = [];
			plans.data.plans.map((plan) => {
				let label = `${plan.name}`;
				label += plan.interval
					? ` (${plan.currency.symbol}${plan.price}/${plan.interval})`
					: ` (${plan.currency.symbol}${plan.price})`;

				return formatted.push({ value: plan.id, label: label });
			});

			setState({
				data: { list: formatted, active: plans.data.active, raw: plans.data },
				loading: false,
			});
		}
	}, [plans]);

	return state;
}
