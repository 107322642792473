import { Button } from "components/ui/button";
import { cn } from "lib/utils";
import { Linkedin, Newspaper } from "lucide-react";

interface PlatformSelectorProps {
	selectedPlatform: "linkedin" | "wordpress";
	onPlatformChange: (platform: "linkedin" | "wordpress") => void;
	disabled?: boolean;
}

export const PlatformSelector = ({
	selectedPlatform,
	onPlatformChange,
	disabled = false,
}: PlatformSelectorProps) => {
	return (
		<div className="flex gap-2">
			<Button
				variant={selectedPlatform === "linkedin" ? "default" : "outline"}
				size="icon"
				className={cn(
					"bg-white hover:bg-primary/60 group",
					selectedPlatform === "linkedin" && "bg-primary",
					disabled &&
						selectedPlatform !== "linkedin" &&
						"opacity-50 cursor-not-allowed",
				)}
				onClick={() => onPlatformChange("linkedin")}
				disabled={disabled && selectedPlatform !== "linkedin"}
			>
				<Linkedin
					className={cn(
						"h-4 w-4 text-slate-600",
						"group-hover:text-white",
						selectedPlatform === "linkedin" && "text-white",
					)}
				/>
			</Button>
			<Button
				variant={selectedPlatform === "wordpress" ? "default" : "outline"}
				size="icon"
				className={cn(
					"bg-white hover:bg-primary/60 group",
					selectedPlatform === "wordpress" && "bg-primary",
					disabled && "opacity-50 cursor-not-allowed",
				)}
				onClick={() => onPlatformChange("wordpress")}
				disabled={disabled}
			>
				<Newspaper
					className={cn(
						"h-4 w-4 text-slate-600",
						"group-hover:text-white",
						selectedPlatform === "wordpress" && "text-white",
					)}
				/>
			</Button>
		</div>
	);
};
