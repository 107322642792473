import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
	type Row,
	type SortingState,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import axios from "axios";
import { useToast } from "components/hooks/use-toast";
import { AuthContext } from "components/lib";
import { Button } from "components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Input } from "components/ui/input";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "components/ui/table";
import debounce from "lodash/debounce";
import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react";
import { useContext, useMemo, useState } from "react";
import type { CustomVoice } from "types/customVoice";
import { VoiceModal } from "./VoiceModal";

const createColumns = (
	onView: (voice: CustomVoice) => void,
	onEdit: (voice: CustomVoice) => void,
	showUserId = false,
) => [
	{
		accessorKey: "id",
		header: "ID",
	},
	{
		accessorKey: "name",
		header: "Name",
	},
	...(showUserId
		? [
				{
					accessorKey: "organization_name",
					header: "Organization",
					cell: ({ row }: { row: Row<CustomVoice> }) => {
						return row.getValue("organization_name") as string;
					},
				},
			]
		: []),
	{
		accessorKey: "type",
		header: "Type",
	},
	{
		accessorKey: "voice_config",
		header: "Voice Config",
		cell: ({ row }: { row: Row<CustomVoice> }) => {
			const voice = row.original;
			const voiceConfig = row.getValue("voice_config");
			if (!voiceConfig) return "No config";

			return (
				<div
					onClick={() => onView(voice)}
					onKeyDown={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							onView(voice);
						}
					}}
					role="button"
					tabIndex={0}
					className="cursor-pointer hover:bg-slate-50 rounded p-1"
				>
					<details>
						<summary>View Config</summary>
						<pre className="text-xs max-h-40 overflow-auto">
							{typeof voiceConfig === "string"
								? voiceConfig
								: JSON.stringify(voiceConfig, null, 2)}
						</pre>
					</details>
				</div>
			);
		},
	},
	{
		accessorKey: "metadata",
		header: "Metadata",
		cell: ({ row }: { row: Row<CustomVoice> }) => {
			const voice = row.original;
			const metadata = row.getValue("metadata") as string;
			if (!metadata) return "No metadata";
			return (
				<div
					onClick={() => onView(voice)}
					onKeyDown={(e) => {
						if (e.key === "Enter" || e.key === " ") {
							onView(voice);
						}
					}}
					role="button"
					tabIndex={0}
					className="cursor-pointer hover:bg-slate-50 rounded p-1"
				>
					<details>
						<summary>View Metadata</summary>
						<pre className="text-xs max-h-40 overflow-auto">{metadata}</pre>
					</details>
				</div>
			);
		},
	},
	{
		accessorKey: "created_at",
		header: "Created At",
		cell: ({ row }: { row: Row<CustomVoice> }) => {
			const date = row.getValue("created_at") as string;
			return date ? new Date(date).toLocaleString() : "N/A";
		},
	},
	{
		id: "actions",
		cell: ({ row }: { row: Row<CustomVoice> }) => {
			const voice = row.original;
			return (
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="ghost" className="h-8 w-8 p-0">
							<MoreHorizontal className="h-4 w-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end">
						<DropdownMenuItem onClick={() => onView(voice)}>
							View
						</DropdownMenuItem>
						<DropdownMenuItem onClick={() => onEdit(voice)}>
							Edit
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			);
		},
	},
];

interface VoiceTableProps {
	voices: CustomVoice[];
	title: string;
	showUserId?: boolean;
	isLoading?: boolean;
	showSearch?: boolean;
	onSearch?: (value: string) => void;
	onView: (voice: CustomVoice) => void;
	onEdit: (voice: CustomVoice) => void;
}

function VoiceTable({
	voices,
	title,
	showUserId = false,
	isLoading = false,
	showSearch = false,
	onSearch,
	onView,
	onEdit,
}: VoiceTableProps) {
	const [sorting, setSorting] = useState<SortingState>([]);

	const table = useReactTable({
		data: voices,
		columns: createColumns(onView, onEdit, showUserId),
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		onSortingChange: setSorting,
		state: {
			sorting,
		},
	});

	return (
		<div className="rounded-md border mb-8">
			<div className="flex justify-between items-center p-4">
				<h2 className="text-xl font-semibold">{title}</h2>
				{showSearch && onSearch && (
					<Input
						placeholder="Search by name or organization..."
						onChange={(e) => onSearch(e.target.value)}
						className="max-w-sm"
					/>
				)}
			</div>
			<Table>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<TableHead key={header.id}>
									{flexRender(
										header.column.columnDef.header,
										header.getContext(),
									)}
								</TableHead>
							))}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{isLoading ? (
						<TableRow>
							<TableCell
								colSpan={createColumns(onView, onEdit, showUserId).length}
								className="h-24 text-center"
							>
								Loading...
							</TableCell>
						</TableRow>
					) : table.getRowModel().rows.length ? (
						table.getRowModel().rows.map((row) => (
							<TableRow key={row.id}>
								{row.getVisibleCells().map((cell) => (
									<TableCell key={cell.id}>
										{flexRender(cell.column.columnDef.cell, cell.getContext())}
									</TableCell>
								))}
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={createColumns(onView, onEdit, showUserId).length}
								className="h-24 text-center"
							>
								No results.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>
		</div>
	);
}

const VOICES_PER_PAGE = 10;

export default function CustomVoiceManagement() {
	const queryClient = useQueryClient();
	const { toast } = useToast();
	// biome-ignore lint/suspicious/noExplicitAny: <auth hook>
	const auth: any = useContext(AuthContext);
	const [page, setPage] = useState(1);
	const [search, setSearch] = useState("");
	const [selectedVoice, setSelectedVoice] = useState<CustomVoice | null>(null);
	const [modalMode, setModalMode] = useState<"view" | "edit">("view");

	const { data: userVoices = [], isLoading: isLoadingUserVoices } = useQuery({
		queryKey: ["user-voices", auth?.user?.id],
		queryFn: async () => {
			if (!auth?.user?.id) return [];
			try {
				const { data } = await axios.get<CustomVoice[]>(
					`/api/custom-voice/user/${auth.user.id}`,
				);
				console.log("User voices:", data);
				return data;
			} catch (error) {
				console.error("Error fetching user voices:", error);
				return [];
			}
		},
		enabled: !!auth?.user?.id,
	});

	// Query for all voices with pagination and search
	const { data: voicesData, isLoading: isLoadingAllVoices } = useQuery({
		queryKey: ["custom-voices", page, search],
		queryFn: async () => {
			const { data } = await axios.get<{
				voices: CustomVoice[];
				total: number;
				totalPages: number;
			}>("/api/custom-voice/get-all", {
				params: {
					page,
					limit: VOICES_PER_PAGE,
					search: search || undefined,
				},
			});
			console.log("Fetched all voices:", data);
			return data;
		},
	});

	const debouncedSearch = useMemo(
		() =>
			debounce((value: string) => {
				setSearch(value);
				setPage(1);
			}, 300),
		[],
	);

	if (!auth?.user) return null;

	const otherVoices =
		voicesData?.voices.filter((voice) => voice.user_id !== auth.user.id) ?? [];

	const handleView = (voice: CustomVoice) => {
		setSelectedVoice(voice);
		setModalMode("view");
	};

	const handleEdit = (voice: CustomVoice) => {
		setSelectedVoice(voice);
		setModalMode("edit");
	};

	const handleSave = async (updatedVoice: CustomVoice) => {
		try {
			await axios.put(`/api/custom-voice/modify/${updatedVoice.id}`, {
				userId: auth.user.id,
				name: updatedVoice.name,
				voiceConfig: updatedVoice.voice_config,
				metadata: updatedVoice.metadata,
			});

			toast({
				title: "Success",
				description: "Voice configuration updated successfully",
			});

			await queryClient.invalidateQueries({ queryKey: ["custom-voices"] });
			await queryClient.invalidateQueries({ queryKey: ["user-voices"] });
		} catch (error) {
			console.error("Error updating voice:", error);
			toast({
				title: "Error",
				description: "Failed to update voice configuration",
				variant: "destructive",
			});
		}
	};

	return (
		<div className="p-4">
			<h1 className="text-2xl font-bold mb-4">Custom Voice Management</h1>

			<VoiceTable
				voices={userVoices}
				title="Your Custom Voices"
				isLoading={isLoadingUserVoices}
				onView={handleView}
				onEdit={handleEdit}
			/>

			<VoiceTable
				voices={otherVoices}
				title="All Custom Voices"
				showUserId={true}
				isLoading={isLoadingAllVoices}
				showSearch={true}
				onSearch={debouncedSearch}
				onView={handleView}
				onEdit={handleEdit}
			/>

			{voicesData && voicesData.totalPages > 1 && (
				<div className="flex items-center justify-center gap-4 mt-8">
					<Button
						variant="ghost"
						size="sm"
						onClick={() => setPage((p) => Math.max(1, p - 1))}
						disabled={page === 1}
						className="h-8 w-8 p-0"
					>
						<ChevronLeft className="h-4 w-4" />
						<span className="sr-only">Previous page</span>
					</Button>
					<span className="text-sm text-muted-foreground">
						Page {page} of {voicesData.totalPages}
					</span>
					<Button
						variant="ghost"
						size="sm"
						onClick={() =>
							setPage((p) => Math.min(voicesData.totalPages, p + 1))
						}
						disabled={page === voicesData.totalPages}
						className="h-8 w-8 p-0"
					>
						<ChevronRight className="h-4 w-4" />
						<span className="sr-only">Next page</span>
					</Button>
				</div>
			)}
			{selectedVoice && (
				<VoiceModal
					voice={selectedVoice}
					isOpen={!!selectedVoice}
					onClose={() => setSelectedVoice(null)}
					mode={modalMode}
					onSave={handleSave}
				/>
			)}
		</div>
	);
}
